import { StyledEngineProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack'
import { HubProvider } from './components/HubContext/hubProvider.js';
import { HelmetProvider } from 'react-helmet-async';
import { IPProvider } from './components/IPContext/IPProvider.js';
import { RouterProvider, } from 'react-router-dom';
import './styles/cardStyles.css';
import './styles/gridRowStyles.css';
import './styles/muiStyles.css';
import { ColorThemeProvider } from './styles/ColorMode/ColorThemeProvider.js';
import { router } from './routeConfig.js';
import './styles/powerstationStyles.css';
import { StyledSnackbar } from './components/StyledSnackbar.js';
import { DurableFunctionProvider } from './components/DurableFunctionContext/durableFunctionProvider.js';

const SnackbarComponents = {
  success: StyledSnackbar,
  error: StyledSnackbar,
  info: StyledSnackbar,
};

function App() {
  return (
    <div>
      <HelmetProvider>
        <StyledEngineProvider injectFirst>
          <ColorThemeProvider>
            <SnackbarProvider maxSnack={5} Components={SnackbarComponents}>
              <HubProvider>
                <DurableFunctionProvider>
                  <IPProvider>
                    <RouterProvider router={router} />
                  </IPProvider>
                </DurableFunctionProvider>
              </HubProvider>
            </SnackbarProvider>
          </ColorThemeProvider>
        </StyledEngineProvider>
      </HelmetProvider>
    </div>
  );
}

export default App;
