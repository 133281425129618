import { useEffect, useMemo, useRef, useState, } from 'react';
import AddIcon from '@mui/icons-material/Add';
import "react-tabs/style/react-tabs.css"
import { Typography, Tab, Tabs, Box, Collapse, } from '@mui/material';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import ConfirmationDialog from '../TSRActivity/ConfirmationDialog';
import EditIcon from '@mui/icons-material/Edit';
import { useUserLayouts } from "../useUserLayouts";
import { useActionAudit } from "../useActionAudit";
import { styled } from '@mui/material';
import { CircularProgress } from '@mui/material';

export const useDashboard = ({ dashboardKey, useShared = false, ViewComponent, AddNewOrUpdateDialog, }) => {
    const [tabIndex, setTabIndex] = useState(0);
    const [openAddNew, setOpenAddNew] = useState(false);
    const { layouts: views, setLayouts: setViews, deleteLayout: handleDelete, insertUpdateLayout: handleSave, loading, } = useUserLayouts(dashboardKey, useShared);
    const [viewToUpdate, setViewToUpdate] = useState({});
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
    const { logAction } = useActionAudit();
    const viewsRef = useRef([]);

    useEffect(() => {
        viewsRef.current = viewsRef.current.slice(0, views.length);
    }, [views]);

    function handleTabSelect(event, newIndex) {
        if (newIndex <= views.length) { //last tab handles deletes, don't switch to it
            setTabIndex(newIndex);
        }
    }

    function handleAddNew(criteria) {
        if (viewToUpdate.id) {
            const oldView = views.find(view => view.id === criteria.id);
            logAction(`User updated the ${dashboardKey} view ${oldView.label}`, dashboardKey, `Old view data: ${JSON.stringify(oldView)} New view data: ${JSON.stringify(criteria)}`);
            handleUpdate(criteria);
        } else {
            setTabIndex(views.length); //switch to new tab
            logAction(`User created a new ${dashboardKey} view ${criteria.label}`, dashboardKey, JSON.stringify(criteria));
            handleSave(criteria);
        }
        setOpenAddNew(false);
    }

    function handleUpdate(criteria) {
        setViews(views.map(view => view.id === criteria.id ? criteria : view));
        handleSave(criteria, false);
    }

    function handleAddNewClick() {
        setViewToUpdate({});
        setOpenAddNew(true);
    }

    function handleViewUpdate() {
        const gridLayout = viewsRef.current[tabIndex]?.captureLayout();
        setViewToUpdate({
            ...views[tabIndex],
            layout: gridLayout,
        });
        setOpenAddNew(true);
    }

    const LoadingOverlay = ({ visible, }) => {
        return (
            <OverlayContainer visible={visible}>
                <CircularProgress />&nbsp;&nbsp;
                <Typography align='center' color='primary' variant='h6'>Loading...</Typography>
            </OverlayContainer>
        );
    }

    const NoDataOverlay = ({ visible, }) => {
        return (
            <OverlayContainer visible={visible}>
                <Typography> No views found.  Click Add New in the upper left to create a new view.  </Typography>
            </OverlayContainer>
        );
    }

    function handleConfirmDelete() {
        setOpenConfirmDelete(false);
        const current = views[tabIndex];
        logAction(`User deleted the ${dashboardKey} view ${current.label}`, dashboardKey, JSON.stringify(current));
        localStorage.removeItem(`deal-rizz-${dashboardKey}-grid-layout-${current.id}`);
        handleDelete(current.id).then(response => {
            const remaining = views?.filter(v => v.id !== current.id);
            if (remaining?.length === 0) { //if they deleted the last view, show Add New dialog
                setOpenAddNew(true);
            }
            setTabIndex(0);
        });
    }

    return (
        <Box
            sx={{
                p: '7px',
                flexGrow: 1,
            }}
        >
            <AddNewOrUpdateDialog
                open={openAddNew}
                viewToUpdate={viewToUpdate}
                handleCancel={() => setOpenAddNew(false)}
                handleAddNew={handleAddNew}
                allViewLabels={views.map(v => v.label)}
            />
            <ConfirmationDialog
                open={openConfirmDelete}
                message={`You are about to delete the current tab${views[tabIndex]?.label ? ' ' + views[tabIndex].label : ''}. Continue?`}
                onCancel={() => setOpenConfirmDelete(false)}
                onConfirmation={handleConfirmDelete}
            />
            <Box>
                <Tabs
                    value={tabIndex}
                    onChange={handleTabSelect}
                    indicatorColor="primary"
                    textColor="primary"
                    color="primary"
                    sx={{ maxHeight: '40px', minHeight: '40px' }}
                >
                    {views.map((view) => (
                        <Tab
                            sx={{ maxHeight: '40px', minHeight: '40px' }}
                            label={view.label}
                            key={`${dashboardKey}-dashboard-tab-${view.id}`} />
                    ))}
                    <Tab key='addNewTab' label='Add New' icon={<AddIcon />} iconPosition='start' onClick={handleAddNewClick} sx={{ maxHeight: '40px', minHeight: '40px' }} />
                    <Tab key='editCurrentTab' label='Edit Current' icon={<EditIcon />} iconPosition='start' onClick={handleViewUpdate} sx={{ maxHeight: '40px', minHeight: '40px' }} disabled={!views[tabIndex]} />
                    <Tab key='deleteCurrentTab' label='Delete Current' icon={<RemoveCircleOutlineIcon />} iconPosition='start' onClick={() => setOpenConfirmDelete(true)} sx={{ maxHeight: '40px', minHeight: '40px' }} disabled={!views[tabIndex]} />
                </Tabs>
                {views.map((view, i) => {
                    return (<Collapse in={tabIndex === i} orientation={'vertical'} key={`${view.id}-${dashboardKey}-view`}>
                        <ViewComponent
                            view={view}
                            ref={el => viewsRef.current[i] = el}
                        />
                    </Collapse>)
                })}
            </Box>
            <NoDataOverlay visible={!views.length && !loading} />
            <LoadingOverlay visible={loading} />
        </Box>
    );
}

const OverlayContainer = styled('div', {
    shouldForwardProp: (prop) => prop !== 'visible',
})(({ theme, visible }) => ({
    position: 'absolute',
    top: '45%',
    left: 0,
    width: '100%',
    display: visible ? 'flex' : 'none',
    justifyContent: 'center',
    alignItems: 'center',
}));