import CloseIcon from '@mui/icons-material/Close';
import moment from "moment";
import { Typography, IconButton, FormControl, FormControlLabel, Checkbox, Button, Tooltip, Dialog, DialogContent, DialogActions, Box, DialogTitle, Grid, Autocomplete, TextField, Card, CardActionArea, CardHeader, Collapse, CardContent, } from "@mui/material";
import useFilteredCriteria from '../CriteriaContext/useFilteredCriteria';
import { useSnackbar } from "notistack";
import { useState, useMemo } from 'react';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ProfileEditor from '../ProfileEditor';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { useTheme } from '@mui/styles';
import dayjs from '../dayjs-tz'

export default (props) => {
  const { open, close, handleSave } = props;
  const { pors, pods, providers, filteredTSCombos, } = useFilteredCriteria({});
  const { enqueueSnackbar } = useSnackbar();
  const [showProfile, setShowProfile] = useState(false);
  const theme = useTheme();

  const [portfolio, setPortfolio] = useState({
    startDate: dayjs().add(1, 'hour'),
    stopDate: dayjs().add(1, 'hour'),
  });

  function handleChange(key, value) {
    const newPortfolio = {
      ...portfolio,
      [key]: value,
    }

    setPortfolio(newPortfolio);
  }

  const renderTimezones = {
    'Pacific': 'Pacific Standard Time',
    'Pacific Standard Time': 'Pacific',
    'Eastern': 'Eastern Standard Time',
    'Eastern Standard Time': 'Eastern',
    'Central': 'Central Standard Time',
    'Central Standard Time': 'Central',
    'Mountain': 'Mountain Standard Time',
    'Mountain Standard Time': 'Mountain',
  }

  function dateFormatter(val) {
    return moment(val, 'MM/DD/YYYY HH:mm:ss.SSS').startOf('hour').format('MM/DD/YYYY HH:mm');
  }

  const expandIcon = showProfile ? <ExpandLessIcon /> : <ExpandMoreIcon />

  function toggleShowProfile() {
    setShowProfile(show => !show);
  }

  const singleLeg = true;
  const defaultInfo = [];
  const pathData = [portfolio];

  const defaultPriceData = pathData.reduce((prices, segment) => ({
    ...prices,
    [segment.leg]: segment.OfferPrice
  }), {})

  const defaultData = useMemo(() => defaultInfo?.map(row => ({
    ...row,
    maxCapacity: row.capacityRequested,
    price: singleLeg ? row.price : defaultPriceData,
  })), [])

  const [gridData, setGridData] = useState(defaultData ?? []);

  function handleProfileSave() {
    const newInfo = singleLeg ? gridData : pathData.reduce((info, next, i) => {
      info[i] = gridData.map(row => ({
        ...row,
        price: row.price[i + 1]
      }))
      return info;
    }, {})
    handleChange('profileInfo', newInfo);
    return newInfo;
  }

  function onSaveClick() {
    const profileInfo = handleProfileSave();
    const newPortfolio = {
      ...portfolio,
      'profileInfo': profileInfo,
    }
    handleSave(newPortfolio);
  }

  return (
    <Dialog maxWidth={'xl'} open={open}>
      <DialogTitle>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography>New Portfolio Info</Typography>
          <Box sx={{ flexGrow: 1, }} />
          <IconButton onClick={close} size='large'>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', p: 1 }}>
          <Grid container justifyContent="center" style={{ marginTop: '20px' }} spacing={2}>
            <Grid item xs={4}>
              <Autocomplete
                fullWidth
                autoComplete
                autoSelect
                options={['Pacific', 'Eastern', 'Central', 'Mountain']}
                value={renderTimezones[portfolio.timezone] ?? ''}
                onChange={(_, newValue) => {
                  handleChange('timezone', renderTimezones[newValue]);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Timezone"
                    size='small'
                    color="success"
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={portfolio.startDate}
                  onChange={(newValue) => handleChange('startDate', newValue)}
                  ampm={false}
                  slotProps={{
                    textField: {
                      size: 'small',
                      label: 'Start Date',
                      fullWidth: true,
                    },
                    layout: {
                      sx: {
                        ul: {
                          '::-webkit-scrollbar': {
                            width: '10px',
                          },
                          '::-webkit-scrollbar-thumb': {
                            backgroundColor: theme.palette.primary.dark,
                            borderRadius: '20px',
                            border: '3px solid transparent',
                            backgroundClip: 'padding-box',
                          },
                          '::-webkit-scrollbar-track': {
                            background: 'transparent',
                          },
                          '::-webkit-scrollbar-thumb:hover': {
                            border: 0,
                          },
                        },
                      },
                    }
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={4}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  value={portfolio.stopDate}
                  onChange={(newValue) => handleChange('stopDate', newValue)}
                  ampm={false}
                  slotProps={{
                    textField: {
                      size: 'small',
                      label: 'Stop Date',
                      fullWidth: true,
                    },
                    layout: {
                      sx: {
                        ul: {
                          '::-webkit-scrollbar': {
                            width: '10px',
                          },
                          '::-webkit-scrollbar-thumb': {
                            backgroundColor: theme.palette.primary.dark,
                            borderRadius: '20px',
                            border: '3px solid transparent',
                            backgroundClip: 'padding-box',
                          },
                          '::-webkit-scrollbar-track': {
                            background: 'transparent',
                          },
                          '::-webkit-scrollbar-thumb:hover': {
                            border: 0,
                          },
                        },
                      },
                    }
                  }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6}>
              <Tooltip title="Choose a Point of Receipt." arrow placement="top">
                <Autocomplete
                  fullWidth
                  autoComplete
                  autoSelect
                  options={pors ?? []}
                  value={portfolio.por ?? []}
                  onChange={(_, newValue) => {
                    handleChange('por', newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      variant="outlined"
                      label="POR"
                      color="success"
                    />
                  )}
                />
              </Tooltip>
            </Grid>
            <Grid item xs={6}>
              <Tooltip title="Choose a Point of Delivery." arrow placement="top">
                <Autocomplete
                  fullWidth
                  autoComplete
                  autoSelect
                  options={pods ?? []}
                  value={portfolio.pod ?? []}
                  onChange={(_, newValue) => {
                    handleChange('pod', newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      variant="outlined"
                      label="POD"
                      color="success"
                    />
                  )}
                />
              </Tooltip>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CardActionArea onClick={toggleShowProfile}>
                  <CardHeader
                    action={
                      <IconButton onClick={toggleShowProfile}>
                        {expandIcon}
                      </IconButton>
                    }
                    title="Target Profile"
                  />
                </CardActionArea>
                <Collapse in={showProfile}>
                  <CardContent>
                    <ProfileEditor
                      pathData={pathData}
                      maxInfo={[]}
                      gridData={gridData}
                      setGridData={setGridData}
                      defaultPriceData={defaultPriceData}
                      singleLeg={true}
                    />
                  </CardContent>
                </Collapse>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={close}>Cancel</Button>
        <Box sx={{ flexGrow: 1, }} />
        <Button variant="contained" color="primary" onClick={onSaveClick}>Save & Close</Button>
      </DialogActions>
    </Dialog>
  )
}