import { useState, useEffect, useRef, useContext, useMemo } from 'react';
import useHeader from "../useHeader";
import { useSnackbar } from "notistack";
import { useLocalGuid } from "../../data/UserGuidContext/useLocalGuid";
import moment from "moment";
import { apiUrlPrefix } from "../../authConfig";
import axios from "axios";
import { Box, Collapse, Stack, Button, Grid, } from "@mui/material";
import { useActionAudit } from '../useActionAudit';
import { useMsal } from '@azure/msal-react';
import { UserContext } from "../../data/fetchUserGroups";
import PathMap from '../PathMap';
import SearchCriteriaDialog from './SearchCriteriaDialog';
import { TransmissionDialog } from '../FastPathPro/TransmissionDialog';
import PathGrid from './PathGrid';
import PublishIcon from '@mui/icons-material/Publish';
import MapIcon from '@mui/icons-material/Map';
import AddAlertIcon from '@mui/icons-material/AddAlert';
import PortfolioView from './PortfolioView';
import SearchIcon from '@mui/icons-material/Search';
import { AgGridContainer } from '../AgGrid/AgGridContainer';

export default () => {
  const [openSearchDialog, setOpenSearchDialog] = useState(false);
  const [searchCriteria, setSearchCriteria] = useState({});
  const [pathData, setPathData] = useState([]);
  const headers = useHeader();
  const { enqueueSnackbar } = useSnackbar();
  const { accounts } = useMsal();
  const pathGridRef = useRef();
  const guid = useLocalGuid();
  const { logAction } = useActionAudit();
  const [selectedPath, setSelectedPath] = useState();
  const [selectedNodes, setSelectedNodes] = useState([]);
  const [openTransmissionDialog, setOpenTransmissionDialog] = useState(false);
  const [dialogAction, setDialogAction] = useState('');
  const [showMap, setShowMap] = useState(false);
  const [showAvailability, setShowAvailability] = useState(false);

  //Takes an array, returns a comma separated string
  function collapseArray(arr) {
    return arr?.length ? arr.reduce((current, next, i) => `${current}${(i > 0) ? ',' : ''}${next}`) : '';
  }

  function handlePathFetch(criteria) {
    setOpenSearchDialog(false);
    pathGridRef.current?.api && pathGridRef.current.api.showLoadingOverlay();
    //const start = moment(criteria.startDate).startOf('day').hours(criteria.endHour ?? 0).format('MM/DD/YYYY HH:mm:ss.SSS')

    const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_fastPathProFetch2`
      + `&parm=${guid ?? ''}`
      + `&parm=${criteria.legs ?? ''}`
      + `&parm=${criteria.por ?? ''}`
      + `&parm=${criteria.pod ?? ''}`
      + `&parm=${collapseArray(criteria.excludeTp)}`
      + `&parm=${criteria.minCapacity ?? ''}`
      + `&parm=${criteria.timezone ?? ''}`
      + `&parm=${criteria.startDate}`
      + `&parm=${criteria.stopDate}`
      + `&parm=${collapseArray(criteria.tsClass)}`
      + `&parm=${collapseArray(criteria.tsIncrement)}`
      + `&parm=${collapseArray(criteria.tsType)}`
      + `&parm=${collapseArray(criteria.tsPeriod)}`
      + `&parm=${collapseArray(criteria.tsWindow)}`
      + `&parm=${collapseArray(criteria.tsSubclass)}`
      + `&parm=${collapseArray(criteria.excludePoints)}`
      + `&parm=${criteria.hardLimit ? 1 : 0}`

    const options = {
      headers: headers,
      url: url,
    }

    const logMessage = `${accounts[0]?.username} used Fast Path Pro to find ${criteria.minCapacity}MW of `
      + `${criteria.tsClass ?? ''} ${criteria.tsIncrement ?? ''} ${criteria.tsType ?? ''} ${criteria.tsPeriod ?? ''} ${criteria.tsWindow ?? ''} ${criteria.tsSubclass ?? ''}`
      + ` trans ${criteria.por}-${criteria.pod} ${criteria.excludePoints ? ` excluding points ${criteria.excludePoints}` : ''}`
      + `${criteria.excludeTp ? ` excluding providers ${criteria.excludeTp}` : ''}`

    logAction(logMessage, 'Fast Path Pro', criteria)

    axios(options).then(response => {
      setSearchCriteria(criteria);
      setPathData(response?.data ?? []);
      pathGridRef.current?.api && pathGridRef.current.api.hideOverlay();
      logAction(`Success fetching paths. ${response.data.length} rows returned.`, 'Fast Path Pro', criteria)
    }).catch(error => {
      enqueueSnackbar(`Error fetching paths. ${error.message}`)
      logAction(`Fast path fetch failed: ${error.message}`, 'Fast Path Pro', criteria)
      pathGridRef.current?.api && pathGridRef.current.api.hideOverlay();
    })
  }

  let availabilityWidth = 0;
  const availabilitySx = {};
  if (showAvailability) {
    availabilityWidth = showMap ? 8 : 6;
  } else {
    availabilitySx.display = 'none';
  }

  return (
    <Box
      sx={{ p: 2, width: '100%', height: '100%', }}
    //overflow='auto'
    >
      <SearchCriteriaDialog
        open={openSearchDialog}
        handleClose={() => setOpenSearchDialog(false)}
        handleFetch={handlePathFetch}
      />
      {openTransmissionDialog && <TransmissionDialog
        open={openTransmissionDialog}
        pathData={selectedPath}
        action={dialogAction}
        setAction={setDialogAction}
        handleClose={() => setOpenTransmissionDialog(false)}
        view='east'
      />}
      <Grid spacing={2} container sx={{ height: '89vh', }}>
        <Grid item container xs={availabilityWidth} spacing={1} sx={availabilitySx}>
          <Grid item xs={12}>
            <Stack spacing={2} direction='row' sx={{ p: 1 }}>
              <Button
                endIcon={<SearchIcon />}
                variant='contained'
                size='small'
                onClick={() => {
                  setOpenSearchDialog(true);
                }}
              >New Search</Button>
              <Button
                endIcon={<PublishIcon />}
                variant='contained'
                size='small'
                onClick={() => {
                  setOpenTransmissionDialog(true);
                  setDialogAction('reservenow');
                }}
                disabled={!selectedPath}
              >Reserve</Button>
              <Button
                endIcon={<AddAlertIcon />}
                variant='contained'
                size='small'
                onClick={() => {
                  setOpenTransmissionDialog(true);
                  setDialogAction('textalert');
                }}
                disabled={!selectedPath}
              >Alert</Button>
              <Box sx={{ flexGrow: 1, }} />
              <Button
                endIcon={<MapIcon />}
                variant='contained'
                size='small'
                onClick={() => {
                  setShowMap(show => !show);
                }}
              >{showMap ? 'Hide Map' : 'View Map'}</Button>
            </Stack>
          </Grid>
          <Grid item xs={showMap ? 5 : 12} sx={{ height: '100%', }}>
            <AgGridContainer
              style={{ height: '100%' }}
            >
              <PathGrid
                data={pathData}
                ref={pathGridRef}
                setSelectedRow={setSelectedPath}
                selectedMapNodes={selectedNodes}
              />
            </AgGridContainer>
          </Grid>
          <Grid item xs={showMap ? 7 : 0}>
            <PathMap
              data={pathData}
              pors={searchCriteria.por}
              pods={searchCriteria.pod}
              selectedPath={selectedPath}
              setSelectedNodes={setSelectedNodes}
              selectedNodes={selectedNodes}
            />
          </Grid>
        </Grid>
        <Grid item xs={12 - availabilityWidth} sx={{ height: '100%', }}>
          <PortfolioView
            showAvailability={showAvailability}
            setShowAvailability={setShowAvailability}
          />
        </Grid>
      </Grid>
    </Box>
  )
}