import { useImperativeHandle, useRef, useMemo, useEffect, useState, useCallback, forwardRef } from "react"
import { Box } from "@mui/material"
import { FilterCriteriaFormGroup } from "./FilterCriteriaFormGroup"
import { AgGridContainer } from "../../AgGrid/AgGridContainer"
import { AgGridReact } from "ag-grid-react"
import { defaultColumnDef, defaultStatusBar } from "../../AgGrid/defaultGridProps"
import { useColumnTypes } from "../../AgGrid/useColumnTypes"
import { columnPanel, filterPanel, } from "../../ToolPanels/DefaultToolPanels"
import { LayoutToolPanel } from "../../ToolPanels/LayoutToolPanel"
import { apiUrlPrefix } from "../../../authConfig"
import { useSnackbar } from "notistack"
import useGridLayout from "../../useGridLayout"
import axios from "axios"
import useHeader from "../../useHeader"
import dayjs from "dayjs"
import { useActionAudit } from "../../useActionAudit"
import { Ribbon, GridActionsGroup, DealActionsGroup } from "../Ribbon"

export const IndexPricingView = forwardRef(({ view, }, ref) => {
    const layoutStorageKey = `deal-rizz-index-pricing-grid-layout-${view.id}`;
    const gridRef = useRef();
    const { columnTypes } = useColumnTypes();
    const [rowData, setRowData] = useState();
    const headers = useHeader();
    const { enqueueSnackbar } = useSnackbar();
    const { logAction } = useActionAudit();

    useEffect(() => {
        fetchData(view);
    }, [view]);

    const fetchData = useCallback(async (data) => {
        logAction('User fetched Index Pricing Data', 'Index Pricing', data);
        const { timezone, date, } = data;
        gridRef.current?.api?.showLoadingOverlay();

        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=dealrizz.UI_fetchIndexPrices`
            + `&parm=${headers.userGuid}`
            + `&parm=${dayjs(date).format('MM/DD/YYYY')}`
            + `&parm=${timezone}`

        return axios.get(url, { headers: headers }).then(response => {
            setRowData(response?.data ?? []);
        }).catch((error) => {
            enqueueSnackbar(error.message, { variant: 'error' });
            setRowData([]);
        }).finally(() => {
            gridRef.current?.api?.hideOverlay();
        });
    }, [headers]);

    const baseColDefs = useMemo(() => [
        {
            checkboxSelection: true,
            headerCheckboxSelection: true,
            headerName: 'Index Name',
            field: 'indexName',
        },
        {
            headerName: 'Market',
            field: 'market',
        },
        {
            headerName: 'Market Type',
            field: 'marketType',
        },
        {
            headerName: 'Component',
            field: 'component',
        },
        {
            headerName: 'Zone',
            field: 'zone',
        },
        {
            headerName: 'Original Currency',
            field: 'originalCurrency',
        },
        {
            headerName: 'Date',
            field: 'date',
        },
        {
            headerName: 'Description',
            field: 'description',
        },
        {
            headerName: 'Name',
            field: 'name',
        },
        {
            headerName: 'Source',
            field: 'source',
        },
        {
            headerName: 'Type',
            field: 'type',
        },
        ...Array.from({ length: 26 }, (_, i) => ({
            headerName: `${i + 1}`,
            field: `${i + 1}`,
        })),
    ], []);

    const defaultColDef = useMemo(() => ({
        ...defaultColumnDef,
        editable: false,
        enableRowGroup: true,
        minWidth: 100,
        flex: 1,
    }), []);

    const { colDefs, layoutPanel, applyLayout, loadLayout, captureLayout, applyFilters, applySavedFilters } = useGridLayout(layoutStorageKey, gridRef, baseColDefs, defaultColDef);

    useImperativeHandle(ref, () => {
        return {
            captureLayout,
        };
    });

    const sideBar = useMemo(() => ({
        toolPanels: [
            columnPanel,
            filterPanel,
            layoutPanel,
        ]
    }), []);

    function onGridReady(params) {
        if (view?.layout && !localStorage.getItem(layoutStorageKey)) {
            applyLayout(view.layout);
        } else {
            loadLayout();
        }
    }

    const handleFirstDataRendered = useCallback(() => {
        if (view?.layout?.filters && !localStorage.getItem(layoutStorageKey)) {
            applyFilters(view.layout.filters);
        } else if (localStorage.getItem(layoutStorageKey)) {
            applySavedFilters();
        }
    }, []);

    const toolbarFormId = 'index-pricing-toolbar-form';

    return (
        <Box sx={{ height: '86vh', display: 'flex', flexDirection: 'column' }}>
            <Ribbon>
                {[
                    { title: 'Grid', content: <GridActionsGroup formId={toolbarFormId} /> },
                    { title: 'Filters', content: <FilterCriteriaFormGroup view={view} onSubmit={fetchData} formId={toolbarFormId} /> },
                ]}
            </Ribbon>
            <AgGridContainer style={{ display: 'flex', flex: 1, width: '100%' }}>
                <AgGridReact
                    containerStyle={{ display: 'flex', flexDirection: 'column', flex: 1, width: '100%', }}
                    rowData={rowData}
                    ref={gridRef}
                    enableCharts
                    columnDefs={colDefs}
                    enableRangeSelection
                    onFirstDataRendered={handleFirstDataRendered}
                    enableFillHandle
                    stopEditingWhenCellsLoseFocus
                    singleClickEdit
                    onGridReady={onGridReady}
                    columnTypes={columnTypes}
                    statusBar={defaultStatusBar}
                    sideBar={sideBar}
                    components={{
                        layoutToolPanel: LayoutToolPanel,
                    }}
                />
            </AgGridContainer>
        </Box>
    )
});