import SupportTicketDialog from "./SupportTicketDialog";
import { useTheme } from "@mui/styles";
import { useState } from "react";
import { useActionAudit } from "./useActionAudit";
import { useLocation } from "react-router-dom";

export const FatalError = ({ error }) => {
    const theme = useTheme();
    const [supportTicketOpen, setSupportTicketOpen] = useState(false);
    const { logAction } = useActionAudit();

    const location = useLocation();
    logAction('Fatal error occurred', location.pathname, error.message);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', top: 50, position: 'relative' }}>
            <SupportTicketDialog open={supportTicketOpen} handleClose={() => setSupportTicketOpen(false)} />
            <h1>Oops! Something's gone wrong.</h1>
            <h2 style={{ alignItems: 'center', display: 'flex' }}>
                Our team has been notified, but click
                <button
                    style={{ margin: theme.spacing(1), cursor: 'pointer' }}
                    onClick={() => setSupportTicketOpen(true)}
                >here</button>
                to fill out a report.
            </h2>
            <h2>Please refresh the page to continue using Powerstation.</h2>
            <pre>{error.message}</pre>
        </div>
    )
}