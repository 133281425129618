import { Button, Stack, Switch, Tooltip, Box, FormGroup, FormControlLabel, ToggleButton, ToggleButtonGroup, Typography, toggleButtonGroupClasses } from "@mui/material"
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import convertTimezones from "../../convertTimezones";
import { useTheme } from "@emotion/react";
import { styled } from '@mui/material/styles';
import { useEffect, useState } from "react";
import { AddAlertDialog } from "./AddAlertDialog";
import axios from "axios";
import { apiUrlPrefix } from "../../../authConfig";
import { useSnackbar } from "notistack";
import useHeader from "../../useHeader";
import { RibbonButton } from "../Ribbon";
import AddAlertIcon from '@mui/icons-material/AddAlert';

export const LMPGridActions = (props) => {
  const { expanded, onRefresh, disableAllButtons, autoRefresh, toggleAutoRefresh, timezone = 'Pacific Standard Time', marketType, handleMarketTypeChange, pivot, handlePivotChange, } = props;
  const theme = useTheme();
  const pivotOptions = { location: 'location', endTime: 'endTime', };

  function handleUpdatePivot() {
    handlePivotChange(pivot === pivotOptions.endTime ? pivotOptions.location : pivotOptions.endTime);
  }

  return (
    <Stack direction='row' spacing={2} useFlexGap flexWrap="wrap" display='flex' flexGrow={1} sx={{ p: 1, }}>
      <FormGroup sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <FormControlLabel control={<Switch checked={pivot === pivotOptions.endTime} onChange={handleUpdatePivot} />} label="End Time Pivot" />
      </FormGroup>
      <FormGroup sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} >
        <FormControlLabel
          label="Market Type: "
          labelPlacement="start"
          componentsProps={{
            typography: {
              sx: { marginRight: theme.spacing(1), }
            }
          }}
          control={
            <StyledToggleButtonGroup
              color="primary"
              value={marketType}
              exclusive
              onChange={handleMarketTypeChange}
              size="small"
            >
              <ToggleButton value="DA">DA</ToggleButton>
              <ToggleButton value="RT">RT</ToggleButton>
            </StyledToggleButtonGroup>
          }
        />
      </FormGroup>
      <Typography sx={{ display: 'flex', alignItems: 'center', paddingInline: theme.spacing(1), }}>Timezone: {convertTimezones[timezone]}</Typography>
      <FormGroup sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <FormControlLabel control={<Switch checked={autoRefresh} onChange={toggleAutoRefresh} />} label="Auto-refresh" />
      </FormGroup>
      <Tooltip title='Reload data with the current filter criteria.' arrow placement="top">
        <RibbonButton
          onClick={onRefresh}
          icon={<RefreshOutlinedIcon fontSize='small' />}
          label={'Refresh'}
          expanded={expanded}
          disabled={disableAllButtons}
        />
      </Tooltip>
    </Stack>
  )
}

export const LMPActions = ({ expanded, }) => {
  const [openAddAlertDialog, setOpenAddAlertDialog] = useState(false);
  const [locationOptions, setLocationOptions] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const headers = useHeader();

  useEffect(() => {
    fetchLocations();
  }, []);

  function fetchLocations() {
    const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_fetchCurrentLMPLocations`;

    const options = {
      headers: headers,
      url: url,
    }

    axios(options).then(response => {
      setLocationOptions(response.data.map(obj => obj.Location));
    }).catch(error => {
      enqueueSnackbar(`Error fetching LMP Locations. Editing LMP views may be impossible. ${error}`, { variant: 'error' });
    });
  }

  return (
    <Stack direction='row' spacing={2} useFlexGap flexWrap="wrap" display='flex' flexGrow={1} sx={{ p: 1, }}>
      <AddAlertDialog
        open={openAddAlertDialog}
        handleClose={() => setOpenAddAlertDialog(false)}
        locations={locationOptions}
      />
      <RibbonButton
        onClick={() => setOpenAddAlertDialog(true)}
        icon={<AddAlertIcon fontSize='small' />}
        label={'Add Alert'}
        expanded={expanded}
      />
    </Stack>
  );
}

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  [`& .${toggleButtonGroupClasses.grouped}`]: {
    margin: theme.spacing(0.5),
    border: 0,
    borderRadius: theme.shape.borderRadius,
    [`&.${toggleButtonGroupClasses.disabled}`]: {
      border: 0,
    },
  },
  [`& .${toggleButtonGroupClasses.middleButton},& .${toggleButtonGroupClasses.lastButton}`]:
  {
    marginLeft: -1,
    borderLeft: '1px solid transparent',
  },
}));