export const defaultColumnDef = {
    sortable: true,
    filter: "agMultiColumnFilter",
    resizable: true,
    floatingFilter: true,
    enableRowGroup: true,
}

export const defaultStatusBar = {
    statusPanels: [
        { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
        { statusPanel: 'agTotalRowCountComponent', align: 'left' },
        { statusPanel: 'agSelectedRowCountComponent', align: 'left' },
        { statusPanel: 'agAggregationComponent', align: 'right' },
    ],
};