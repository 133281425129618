import { useCallback, useEffect, useState } from "react";
import { useSnackbar } from 'notistack';
import { useActionAudit } from './useActionAudit';

export default (storageLocation, gridRef, baseColDefs, defaultDef = {}) => {
  const [colDefs, setColDefs] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const { logAction } = useActionAudit();

  const fields = ['field', 'width', 'hide', 'sort', 'order', 'pinned', 'headerName', 'sortIndex', 'rowGroup', 'rowGroupIndex', 'pivot', 'pivotIndex', 'aggFunc'];

  function saveLayoutLocal() {
    const layout = captureLayout();
    localStorage.setItem(storageLocation, JSON.stringify(layout));
    enqueueSnackbar('Layout saved.', { variant: 'info' });
    logAction(`Layout saved`, storageLocation, layout?.state);
  };

  function captureLayout() {
    const defs = gridRef.current.api.getColumnDefs();
    const state = defs.map((def, i) => {
      const defLayout = {};
      fields.forEach(field => {
        defLayout[field] = def[field];
      });
      defLayout.order = i;
      defLayout.hide = def.hide ? def.hide : false; //set undefined value to false so it displays properly after saving/loading
      return defLayout;
    });
    const filters = gridRef.current.api.getFilterModel();

    return {
      filters: filters,
      state: state,
    }
  }

  function loadLayout() {
    try {
      updateFromStorage();
    } catch (err) {
      enqueueSnackbar(`Error loading layout from ${storageLocation ?? 'local storage'}. Message: ${err}`, { variant: 'error' });
      logAction(`Error loading layout from ${storageLocation ?? 'local storage'}. Message: ${err}`, storageLocation);
    }
  };

  const deleteLayoutLocal = () => {
    clearLayout();
    localStorage.removeItem(storageLocation);
    enqueueSnackbar('Local layout deleted.', { variant: 'info' });
  };

  const clearLayout = () => {
    gridRef.current.columnApi.resetColumnState();
    let defaultDefs = [...baseColDefs].map(def => ({ ...defaultDef, ...def }));

    //reset hidden columns to initial state.
    defaultDefs.forEach(def => def.hide = def.initialHide ?? false);

    //reset column widths to initial state.
    defaultDefs.forEach(def => def.width = def.initialWidth ?? def.width);

    //reset sorting on all columns
    gridRef.current.columnApi.applyColumnState({
      defaultState: { sort: null }
    });

    //reset filters
    gridRef.current.api.setFilterModel(null);

    setColDefs(defaultDefs);
    enqueueSnackbar('Layout reset.', { variant: 'info' });
  };

  function updateFromStorage() {
    try {
      const savedLayout = JSON.parse(localStorage.getItem(storageLocation) ?? '{}');
      applyLayout(savedLayout);
    } catch (err) {
      enqueueSnackbar(`Error loading saved layout. ${err}`, { variant: 'error' });
      gridRef?.current && clearLayout();
    }
  }

  function applyLayout(layout) {
    const defs = baseColDefs.map(def => {
      const newDef = { ...defaultDef, ...def };
      const defLayout = layout?.state?.find(lyt => {
        return def.field ? lyt.field === def.field : lyt.headerName === def.headerName; //match on headerName if field is not defined
      });
      if (defLayout) {
        fields.forEach(field => {
          if (!['field', 'headerName'].includes(field)) {
            newDef[field] = defLayout[field];
          }
        });
      }
      return newDef;
    });

    const widthAdjustedDefs = defs.map(def => ({ //remove flex property if width is set to persist the users width setting
      ...def,
      flex: def.width ? undefined : def.flex,
    }));
    const sortedDefs = widthAdjustedDefs.sort((def1, def2) => def1.order - def2.order);

    setColDefs(sortedDefs);
  }

  useEffect(() => { //need to wait for colDefs to be set before applying filters
    !!colDefs?.length && applySavedFilters();
  }, [colDefs]);

  function applySavedFilters(layout) {
    if (gridRef.current?.api) {
      const lyt = layout ?? JSON.parse(localStorage.getItem(storageLocation) ?? '{}');
      if (lyt?.filters) {
        applyFilters(lyt.filters);
      }
    }
  }

  const applyFilters = useCallback((filters) => {
    gridRef.current.api.setFilterModel(filters);
    gridRef.current.api.onFilterChanged();
  }, [gridRef.current]);

  //save layout when window unloads
  useEffect(() => {
    window.addEventListener('beforeunload', saveLayoutLocal)
    return () => {
      window.removeEventListener('beforeunload', saveLayoutLocal)
    }
  }, []);

  const layoutPanel = {
    id: 'layout',
    labelDefault: 'Layout',
    labelKey: 'layout',
    toolPanel: 'layoutToolPanel',
    toolPanelParams: {
      onLayoutClear: clearLayout,
      onLayoutLoad: loadLayout,
      onLayoutSave: saveLayoutLocal,
      onLayoutDelete: deleteLayoutLocal
    },
    minWidth: 180,
    maxWidth: 400,
    width: 200
  }

  return {
    layoutPanel,
    colDefs,
    loadLayout,
    applyLayout,
    clearLayout,
    applySavedFilters,
    applyFilters,
    captureLayout,
  };
}