import * as React from 'react';
import PropTypes from 'prop-types';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
//import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { makeStyles } from '@mui/styles';
import { createTheme } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useState, useEffect } from 'react';
import clsx from 'clsx';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
//import CheckIcon from '@mui/icons-material/Check';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import { apiUrlPrefix, subscriptionKey } from '../../authConfig';
import useHeader from '../useHeader';
//import { LicenseInfo } from '@mui/x-data-grid';

LicenseInfo.setLicenseKey(
  '6ea91d57b278a973ebe74f3782caaf67T1JERVI6MzA4OTcsRVhQSVJZPTE2NjU3OTEyNTAwMDAsS0VZVkVSU0lPTj0x',
);

const dashboardRows = [
  {
    id: 1,
    Template: 'AEP',
    Apr27: 1,
    Apr28: 1,
    Apr29: 1,
    Apr30: 1,
    May1: 0,
    May2: 0,
    May3: 0,
    May4: 0,
    May5: 0,
  },
  {
    id: 2,
    Template: 'MW',
    Apr27: 1,
    Apr28: 1,
    Apr29: 1,
    Apr30: 1,
    May1: 0,
    May2: 0,
    May3: 0,
    May4: 0,
    May5: 0,
  },
  {
    id: 3,
    Template: 'Retail',
    Apr27: 15,
    Apr28: 15,
    Apr29: 15,
    Apr30: 15,
    May1: 0,
    May2: 0,
    May3: 0,
    May4: 0,
    May5: 0,
  },
  {
    id: 4,
    Template: 'Duke',
    Apr27: 1,
    Apr28: 1,
    Apr29: 1,
    Apr30: 1,
    May1: 0,
    May2: 0,
    May3: 0,
    May4: 0,
    May5: 0,
  },
  {
    id: 5,
    Template: 'SSS',
    Apr27: 1,
    Apr28: 1,
    Apr29: 1,
    Apr30: 1,
    May1: 0,
    May2: 0,
    May3: 0,
    May4: 0,
    May5: 0,
  },
];

const dashboardColumns = [
  { field: 'Template', width: 150 },
  { field: 'Apr27', width: 150, cellClassName: (params) =>
  clsx('super-app', {
    good: params.value > 0,
  }), },
  { field: 'Apr28', width: 150, cellClassName: (params) =>
  clsx('super-app', {
    good: params.value > 0,
  }), },
  { field: 'Apr29', width: 150, cellClassName: (params) =>
  clsx('super-app', {
    good: params.value > 0,
  }), },
  { field: 'Apr30', width: 150, cellClassName: (params) =>
  clsx('super-app', {
    good: params.value > 0,
  }), },
  { field: 'May1', width: 150, cellClassName: (params) =>
  clsx('super-app', {
    good: params.value > 0,
  }), },
  { field: 'May2', width: 150, cellClassName: (params) =>
  clsx('super-app', {
    good: params.value > 0,
  }), },
  { field: 'May3', width: 150, cellClassName: (params) =>
  clsx('super-app', {
    good: params.value > 0,
  }), },
  { field: 'May4', width: 150, cellClassName: (params) =>
  clsx('super-app', {
    good: params.value > 0,
  }), },
  { field: 'May5', width: 150, cellClassName: (params) =>
  clsx('super-app', {
    good: params.value > 0,
  }), },
];

const columns = [   
  { field: 'id', headerName: 'Id', width: 5, hide: true},
  { field: 'location', headerName: 'Location', width: 90, editable: true, sortable: false, disableColumnMenu: true },
  { field: 'sourceName', headerName: 'Version', width: 90, sortable: false, disableColumnMenu: true },
  { field: 'totalBids', headerName: 'Total', type: 'number', width: 70, sortable: false, disableColumnMenu: true, cellClassName: (params) =>
  clsx('super-app', {
    negative: params.value < 1000,
    neutral: params.value > 1000,
    positive: params.value == 1000,
  }), },
  { field: 'hE1',  headerName: 'H1', type: 'number', width: 40, sortable: false, disableColumnMenu: true},
  { field: 'hE2', headerName: 'H2', type: 'number', width: 40, sortable: false, disableColumnMenu: true},
  { field: 'hE3', headerName: 'H3', type: 'number', width: 40, sortable: false, disableColumnMenu: true},
  { field: 'hE4', headerName: 'H4', type: 'number', width: 40, sortable: false, disableColumnMenu: true},
  { field: 'hE5', headerName: 'H5', type: 'number', width: 40, sortable: false, disableColumnMenu: true},
  { field: 'hE6', headerName: 'H6', type: 'number', width: 40, sortable: false, disableColumnMenu: true},
  { field: 'hE7', headerName: 'H7', type: 'number', width: 40, sortable: false, disableColumnMenu: true},
  { field: 'hE8', headerName: 'H8', type: 'number', width: 40, sortable: false, disableColumnMenu: true},
  { field: 'hE9', headerName: 'H9', type: 'number', width: 40, sortable: false, disableColumnMenu: true},
  { field: 'hE10', headerName: 'H10', type: 'number', width: 60, sortable: false, disableColumnMenu: true},
  { field: 'hE11', headerName: 'H11', type: 'number', width: 55, sortable: false, disableColumnMenu: true, cellClassName: (params) =>
  clsx('super-app', {
    positive: params.value == 17.87,
  }), },
  { field: 'hE12', headerName: 'H12', type: 'number', width: 60, sortable: false, disableColumnMenu: true},
  { field: 'hE13', headerName: 'H13', type: 'number', width: 60, sortable: false, disableColumnMenu: true},
  { field: 'hE14', headerName: 'H14', type: 'number', width: 60, sortable: false, disableColumnMenu: true},
  { field: 'hE15', headerName: 'H15', type: 'number', width: 60, sortable: false, disableColumnMenu: true},
  { field: 'hE16', headerName: 'H16', type: 'number', width: 60, sortable: false, disableColumnMenu: true},
  { field: 'hE17', headerName: 'H17', type: 'number', width: 60, sortable: false, disableColumnMenu: true},
  { field: 'hE18', headerName: 'H18', type: 'number', width: 60, sortable: false, disableColumnMenu: true},
  { field: 'hE19', headerName: 'H19', type: 'number', width: 60, sortable: false, disableColumnMenu: true},
  { field: 'hE20', headerName: 'H20', type: 'number', width: 60, sortable: false, disableColumnMenu: true},
  { field: 'hE21', headerName: 'H21', type: 'number', width: 60, sortable: false, disableColumnMenu: true},
  { field: 'hE22', headerName: 'H22', type: 'number', width: 60, sortable: false, disableColumnMenu: true},
  { field: 'hE23', headerName: 'H23', type: 'number', width: 60, sortable: false, disableColumnMenu: true},
  { field: 'hE24', headerName: 'H24', type: 'number', width: 60, sortable: false, disableColumnMenu: true}
];


const defaultTheme = createTheme();
const useStylesAntDesign = makeStyles(
  (theme) => ({
    root: {
            display: 'flex',
            flexDirection: 'column',
            height: 840,
            width: '100%',
            '& .MuiFormGroup-options': {
              alignItems: 'center',
              paddingBottom: theme.spacing(1),
              '& > div': {
                minWidth:  100,
                margin: theme.spacing(2, 2, 2, 0),
              },
            },
          },
    bidmanRoot: {
      display: 'flex',
      flexDirection: 'column',
      height: 840,
      width: '100%',
       '& .MuiFormGroup-options': {
          alignItems: 'center',
          paddingBottom: theme.spacing(1),
          '& > div': {
            minWidth:  100,
            margin: theme.spacing(2, 2, 2, 0),
          },
        },
      border: `1px solid ${theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'}`,
      color:
        theme.palette.mode === 'light'
          ? 'rgba(0,0,0,.85)'
          : 'rgba(255,255,255,0.85)',
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      WebkitFontSmoothing: 'auto',
      letterSpacing: 'normal',
      '& .MuiDataGrid-renderingZone': {
        '& .MuiDataGrid-row': {
          '&:nth-child(2n)': { 
            backgroundColor: "rgba(235, 235, 235, .7)" 
          }
        }
      },
      '& .super-app-theme--cell': {
        backgroundColor: 'rgba(224, 183, 60, 0.55)',
        color: '#1a3e72',
        fontWeight: '600',
      },
      '& .super-app.negative': {
        backgroundColor: 'rgba(157, 255, 118, 0.49)',
        color: '#1a3e72',
        fontWeight: '600',
      },
      '& .super-app.good': {
        backgroundColor: 'rgba(157, 255, 118, 0.49)',
        color: '#1a3e72',
        fontWeight: '600',
      },
      '& .super-app.neutral': {
        backgroundColor: 'rgba(240, 240, 214, 1)',
        color: '#1a3e72',
        fontWeight: '600',
      },
      '& .super-app.positive': {
        backgroundColor: '#d47483',
        color: '#1a3e72',
        fontWeight: '600',
      },
      '& .MuiDataGrid-columnsContainer': {
        backgroundColor: theme.palette.mode === 'light' ? '#fafafa' : '#1d1d1d',
      },
      '& .MuiDataGrid-iconSeparator': {
        display: 'none',
      },
      '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
        fontSize: 11,
        borderRight: `1px solid ${
          theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'
        }`,
      },
      '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
        borderBottom: `1px solid ${
          theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'
        }`,
      },
      '& .MuiDataGrid-cell': {
        color:
          theme.palette.mode === 'light'
            ? 'rgba(0,0,0,.85)'
            : 'rgba(255,255,255,0.85)',
        fontFamily: [
          '-apple-system',
          'BlinkMacSystemFont',
          '"Segoe UI"',
          'Roboto',
          '"Helvetica Neue"',
          'Arial',
          'sans-serif',
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(','),
        WebkitFontSmoothing: 'auto',
        letterSpacing: 'normal',
        '& .MuiDataGrid-columnsContainer': {
          backgroundColor: theme.palette.mode === 'light' ? '#fafafa' : '#1d1d1d',
        },
        '& .MuiDataGrid-iconSeparator': {
          display: 'none',
        },
        '& .MuiDataGrid-colCell, .MuiDataGrid-cell': {
          borderRight: `1px solid ${
            theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'
          }`,
        },
        '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
          borderBottom: `1px solid ${
            theme.palette.mode === 'light' ? '#f0f0f0' : '#303030'
          }`,
        },
        '& .MuiDataGrid-cell': {
          color:
            theme.palette.mode === 'light'
              ? 'rgba(0,0,0,.85)'
              : 'rgba(255,255,255,0.65)',
        },
        '& .MuiPaginationItem-root': {
          borderRadius: 0,
        },
        '& .MuiCheckbox-root svg': {
          width: 16,
          height: 16,
          backgroundColor: 'white',
          border: `1px solid ${
            theme.palette.mode === 'light' ? '#d9d9d9' : 'rgb(67, 67, 67)'
          }`,
          borderRadius: 2,
        },
        '& .MuiCheckbox-root svg path': {
          display: 'none',
        },
        '& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg': {
          backgroundColor: '#1890ff',
          borderColor: '#1890ff',
        },
        '& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after': {
          position: 'absolute',
          display: 'table',
          border: '2px solid #fff',
          borderTop: 0,
          borderLeft: 0,
          transform: 'rotate(45deg) translate(-50%,-50%)',
          opacity: 1.0,
          transition: 'all .2s cubic-bezier(.12,.4,.29,1.46) .1s',
          content: '""',
          top: '50%',
          left: '39%',
          width: 5.71428571,
          height: 9.14285714,
        },
        '& .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after': {
          width: 8,
          height: 8,
          backgroundColor: '#1890ff',
          transform: 'none',
          top: '39%',
          border: 0,
        },
      },
    },
  }),
  { defaultTheme },
);

  // const useStyles = makeStyles(
  //   (theme) => ({
  //     root: {
  //       display: 'flex',
  //       flexDirection: 'column',
  //       height: 840,
  //       width: '100%',
  //       '& .MuiFormGroup-options': {
  //         alignItems: 'center',
  //         paddingBottom: theme.spacing(1),
  //         '& > div': {
  //           minWidth:  100,
  //           margin: theme.spacing(2, 2, 2, 0),
  //         },
  //       },
  //     },
  //   }),
  //   { defaultTheme },
  // );


function SettingsPanel(props) {
  const { onApply, type, size, theme } = props;
  const [sizeState, setSize] = React.useState(size);
  const [typeState, setType] = React.useState(type);
  const [selectedPaginationValue, setSelectedPaginationValue] = React.useState(-1);
  // const [activeTheme, setActiveTheme] = React.useState(theme);
  

  const handleSizeChange = React.useCallback((event) => {
    setSize(Number(event.target.value));
  }, []);

  const handleDatasetChange = React.useCallback((event) => {
    setType(event.target.value);
  }, []);

  const handlePaginationChange = React.useCallback((event) => {
    setSelectedPaginationValue(event.target.value);
  }, []);

  // const handleThemeChange = React.useCallback((event) => {
  //   setActiveTheme(event.target.value);
  // }, []);

  const handleApplyChanges = React.useCallback(() => {
    onApply({
      size: sizeState,
      type: typeState,
      pagesize: selectedPaginationValue,
      // theme: activeTheme,
    });
  }, [
    sizeState, 
    typeState, 
    selectedPaginationValue, 
    //activeTheme, 
    onApply,
  ]);

  return (
    <FormGroup className="MuiFormGroup-options" row>
      <FormControl variant="outlined">
        <InputLabel>View</InputLabel>
        <Select value={typeState} onChange={handleDatasetChange}>
          <MenuItem value="Employee">Day Ahead</MenuItem>
          <MenuItem value="Commodity">Real Time</MenuItem>
        </Select>
      </FormControl>
      <FormControl variant="outlined">
      <Stack component="form" noValidate spacing={3}>
      <TextField  variant="outlined"
        id="date"
        label="Opr Day"
        type="date"
        defaultValue="2017-05-24"
        sx={{ width: 220 }}
        InputLabelProps={{
          shrink: true,
        }}
      />
      </Stack>
      </FormControl>
      <FormControl variant="outlined">
        <InputLabel>Rows</InputLabel>
        <Select value={sizeState} onChange={handleSizeChange}>
          <MenuItem value={100}>100</MenuItem>
          <MenuItem value={1000}>{Number(1000).toLocaleString()}</MenuItem>
          <MenuItem value={10000}>{Number(10000).toLocaleString()}</MenuItem>
          <MenuItem value={100000}>{Number(100000).toLocaleString()}</MenuItem>
        </Select>
      </FormControl>
      <FormControl variant="outlined">
        <InputLabel>Page Size</InputLabel>
        <Select value={selectedPaginationValue} onChange={handlePaginationChange}>
          <MenuItem value={-1}>off</MenuItem>
          <MenuItem value={0}>auto</MenuItem>
          <MenuItem value={25}>25</MenuItem>
          <MenuItem value={100}>100</MenuItem>
          <MenuItem value={1000}>{Number(1000).toLocaleString()}</MenuItem>
        </Select>
      </FormControl>
      {/* <FormControl variant="standard">
        <InputLabel>Theme</InputLabel>
        <Select value={activeTheme} onChange={handleThemeChange}>
          <MenuItem value="default">Default Theme</MenuItem>
          <MenuItem value="ant">Bidman</MenuItem>
        </Select>
      </FormControl> */}
      <FormControl variant="outlined">
        <Button
          size="small"
          variant="outlined"
          color="primary"
          onClick={handleApplyChanges}
        >
          <KeyboardArrowRightIcon fontSize="small" /> Apply
        </Button>
      </FormControl>
    </FormGroup>
  );
}


SettingsPanel.propTypes = {
  onApply: PropTypes.func.isRequired,
  size: PropTypes.number.isRequired,
  // theme: PropTypes.oneOf(['ant', 'default']).isRequired,
  type: PropTypes.oneOf(['Commodity', 'Employee']).isRequired,
};

export default function FullFeaturedDemo() {
  // const classes = useStyles();
  const classes = useStylesAntDesign();
  //const antDesignClasses = useStylesAntDesign();
  // const [isAntDesign, setIsAntDesign] = React.useState(false);
  const [type, setType] = React.useState('Commodity');
  const [size, setSize] = React.useState(100);
  
  const [pagination, setPagination] = React.useState({
    pagination: false,
    autoPageSize: false,
    pageSize: undefined,
  });

  // const getActiveTheme = () => {
  //   return isAntDesign ? 'ant' : 'default';
  // };

  const handleApplyClick = (settings) => {
    if (size !== settings.size) {
      setSize(settings.size);
    }

    if (type !== settings.type) {
      setType(settings.type);
    }

    
    const newPaginationSettings = {
      pagination: settings.pagesize !== -1,
      autoPageSize: settings.pagesize === 0,
      pageSize: settings.pagesize > 0 ? settings.pagesize : undefined,
    };

    setPagination((currentPaginationSettings) => {
      if (
        currentPaginationSettings.pagination === newPaginationSettings.pagination &&
        currentPaginationSettings.autoPageSize ===
          newPaginationSettings.autoPageSize &&
        currentPaginationSettings.pageSize === newPaginationSettings.pageSize
      ) {
        return currentPaginationSettings;
      }
      return newPaginationSettings;
    });
  };

  const [rows, setRows] = useState([]);
  const headers = useHeader();

  useEffect(() => {
        
            
    // we will use async/await to fetch this data
    async function getData() {
      const response = await fetch(`${apiUrlPrefix}/CrystalBall/Intervals?viewToUse=vera.DA_Self_AS&whereClause=%20&startDate=8/11/2020&endDate=8/12/2020&intervalSize=60&dataType=%20&connectionStringName=IntervalDataConnection`,
      {
        headers: headers,
      });
      const data = await response.json();
    // store the data into our books variable
    setRows(data);
    }

    let abortController = new AbortController();  
    // your async action is here  
    getData();
    return () => {  
      abortController.abort();  
    }  

  }, []); // <- you may need to put the setBooks function in this array
  

  return (
    <div className={classes.root}>
      <SettingsPanel
        onApply={handleApplyClick}
        size={size}
        type={type}
        //theme={getActiveTheme()}
      />
      
              <DataGridPro
                showPagination={false}
                hideFooter={true}
                rows={dashboardRows} 
                //<DataGridProcolumns={[{ field: 'id' },{ field: 'username', minWidth: 150 },{ field: 'age', resizable: false },]}rows={rows}/>
                columns={dashboardColumns}
                //mts, 10/8/2021, these prevent my hard coded rows and columns (defined above) from being displayed.
                //className={isAntDesign ? antDesignClasses.root : undefined}
                className={classes.bidmanRoot}
                //{...data}
                density="compact"
                // components={{
                //   Toolbar: GridToolbar,
                // }}
                // checkboxSelection
                // disableSelectionOnClick
                // {...pagination}
              />
            
            
      {/* <div sx={{ height: 10, width: '100%' }} /> */}

      {/* <ControlPanel
        //onApply={handleApplyClick}
        size={size}
        type={type}
        theme={getActiveTheme()}
      /> */}
      <DataGridPro
        rows={rows} 
        //<DataGridProcolumns={[{ field: 'id' },{ field: 'username', minWidth: 150 },{ field: 'age', resizable: false },]}rows={rows}/>
        columns={columns}
        //mts, 10/8/2021, these prevent my hard coded rows and columns (defined above) from being displayed.
        // className={isAntDesign ? antDesignClasses.root : undefined}
        className={classes.bidmanRoot}
        //{...data}
        density="compact"
        components={{
          Toolbar: GridToolbar,
        }}
        checkboxSelection
        disableSelectionOnClick
        {...pagination}
      />
      
      
    </div>
  );
}
