import { TransDealSummaryView } from "./TransDealSummaryView";
import { AddNewOrUpdateDialog } from "./AddNewOrUpdateDialog";
import { useDashboard } from "../useDashboard";

export const TransDealSummaryDashboard = () => {
    const dashboardKey = 'trans-deal-summary';
    const useShared = true;

    const Dashboard = useDashboard({ dashboardKey, useShared, ViewComponent: TransDealSummaryView, AddNewOrUpdateDialog, });
    return Dashboard;
}