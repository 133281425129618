import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { routePaths } from '../routeConfig';
import { useTheme } from '@mui/material/styles';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Power System Operations
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

function PrivacyPolicy() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      <Link color="inherit" href="https://www.termsfeed.com/live/f1ac739e-1c87-4c6c-b87f-88f99e969b7c">
        Privacy Policy
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const footers = [
  {
    title: 'Company',
    description: ['Team', 'History', 'Contact us',],
  },
  /*{
    title: 'Features',
    description: ['Cool stuff', 'Random feature', 'Team feature', 'Developer stuff', 'Another one'],
  },
  {
    title: 'Resources',
    description: ['Resource', 'Resource name', 'Another resource', 'Final resource'],
  },*/
  {
    title: 'Legal',
    description: ['Privacy policy', 'Terms of use'],
  },
];

export default function Pricing() {
  const theme = useTheme();

  return (
    <footer>
      <CssBaseline />
      <Container maxWidth="md" sx={{ borderTop: `1px solid ${theme.palette.divider}`,
        marginTop: theme.spacing(8),
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(6),
      }}>
        <Grid container spacing={4} justifyContent="space-evenly">
          {footers.map((footer) => (
            <Grid item xs={6} sm={3} key={footer.title}>
              <Typography variant="h6" color="textPrimary" gutterBottom>
                {footer.title}
              </Typography>
              <ul>
                {footer.description.map((item) => (
                  <li key={item}>
                    <Link href="#" variant="subtitle1" color="textSecondary">
                      {item}
                    </Link>
                  </li>
                ))}
              </ul>
            </Grid>
          ))}
          <Grid item xs={6} sm={3} key='Features'>
            <Typography variant="h6" color="textPrimary" gutterBottom>Features</Typography>
            <nav>
              <ul>
                <li key='FastPath'>
                  <Link href={routePaths.fastpath} variant="subtitle1" color="textSecondary">
                    Fast Path
                  </Link>
                </li>
                <li key='TransNow'>
                  <Link href={routePaths.transnow} variant="subtitle1" color="textSecondary">
                    TransNow
                  </Link>
                </li>
                <li key='TransHunter'>
                  <Link href={routePaths.transhunter} variant="subtitle1" color="textSecondary">
                    TransHunter
                  </Link>
                </li>
              </ul>
            </nav>
          </Grid>
        </Grid>
        <Box mt={5}>
          <Copyright />
        </Box>
        <Box mt={5}>
          <PrivacyPolicy />
        </Box>
      </Container>
    </footer>
  );
}