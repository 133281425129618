import { Stack, Button, Box, Autocomplete, TextField, } from "@mui/material"
import PortfolioGrid from "./PortfolioGrid";
import PortfolioChart from "./PortfolioChart";
import { useEffect, useRef, useState } from "react";
import BarChartIcon from '@mui/icons-material/BarChart';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import NewPortfolioDialog from "./NewPortfolioDialog";
import AddIcon from '@mui/icons-material/Add';
import useHeader from "../useHeader";
import axios from "axios";
import { apiUrlPrefix } from "../../authConfig";
import { useSnackbar } from "notistack";
import { useLocalGuid } from "../../data/UserGuidContext/useLocalGuid";
import { AgGridContainer } from "../AgGrid/AgGridContainer";

export default (props) => {
  const { showAvailability, setShowAvailability, } = props;
  const portfolioGridRef = useRef();
  const [showChart, setShowChart] = useState(true);
  const [portfolios, setPortfolios] = useState([])
  const [selectedPortfolio, setSelectedPortfolio] = useState('')
  const [openNewPortfolioDialog, setOpenNewPortfolioDialog] = useState(false);
  const headers = useHeader();
  const { enqueueSnackbar } = useSnackbar();
  const guid = useLocalGuid();

  useEffect(() => {
    guid && fetch();
  }, [guid]);

  //Takes an array, returns a comma separated string
  function collapseArray(arr) {
    return arr?.length ? arr.reduce((current, next, i) => `${current}${(i > 0) ? ',' : ''}${next}`) : '';
  }

  function fetch() {
    portfolioGridRef.current?.api && portfolioGridRef.current.api.showLoadingOverlay();

    const criteria = {
      customerCode: 'HZN',
      legs: 10,
      por: 'BPAT.NWMT',
      pod: 'PALOVERDE500',
      excludeTp: [],
      minCapacity: 0,
      timezone: 'Pacific Standard Time',
      startDate: '2023-04-24 00:00:00.00',
      stopDate: '2023-04-25 00:00:00.00',
      tsClass: ['*'],
      tsIncrement: ['*'],
      tsType: ['*'],
      tsPeriod: ['*'],
      tsWindow: ['*'],
      tsSubclass: ['*'],
      excludePoints: [],
      hardLimit: 0,
    }

    const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_portfolioFetch`
      + `&parm=${guid ?? ''}`
      + `&parm=${criteria.customerCode ?? ''}`
      + `&parm=${criteria.legs ?? ''}`
      + `&parm=${criteria.por ?? ''}`
      + `&parm=${criteria.pod ?? ''}`
      + `&parm=${collapseArray(criteria.excludeTp)}`
      + `&parm=${criteria.minCapacity ?? ''}`
      + `&parm=${criteria.timezone ?? ''}`
      + `&parm=${criteria.startDate}`
      + `&parm=${criteria.stopDate}`
      + `&parm=${collapseArray(criteria.tsClass)}`
      + `&parm=${collapseArray(criteria.tsIncrement)}`
      + `&parm=${collapseArray(criteria.tsType)}`
      + `&parm=${collapseArray(criteria.tsPeriod)}`
      + `&parm=${collapseArray(criteria.tsWindow)}`
      + `&parm=${collapseArray(criteria.tsSubclass)}`
      + `&parm=${collapseArray(criteria.excludePoints)}`
      + `&parm=${criteria.hardLimit ? 1 : 0}`

    const options = {
      headers: headers,
      url: url,
    }

    axios(options).then(response => {
      setPortfolios(response.data);
      portfolioGridRef.current?.api && portfolioGridRef.current.api.hideOverlay();
    }).catch(error => {
      enqueueSnackbar(`Error fetching paths. ${error.message}`)
      portfolioGridRef.current?.api && portfolioGridRef.current.api.hideOverlay();
    })
  }

  function saveNewPortfolio(newPortfolio) {
    setOpenNewPortfolioDialog(false);
  }

  return (
    <div style={{ width: '100%', height: '95%', }}>
      <NewPortfolioDialog
        open={openNewPortfolioDialog}
        close={() => setOpenNewPortfolioDialog(false)}
        handleSave={saveNewPortfolio}
      />
      <Stack spacing={2} direction='row' sx={{ p: 1, }}>
        <Button
          endIcon={<QueryStatsIcon />}
          variant='contained'
          size='small'
          onClick={() => {
            setShowAvailability(show => !show);
          }}
        >{showAvailability ? 'Hide Availability' : 'Show Availability'}</Button>
        <Button
          endIcon={<AddIcon />}
          variant='contained'
          size='small'
          onClick={() => {
            setOpenNewPortfolioDialog(true);
          }}
        >Add Portfolio</Button>
        {/*<Autocomplete
            autoComplete
            autoSelect
            options={[]}
            value={selectedPortfolio ?? 'Portfolio'}
            onChange={(_, newValue) => {
              setSelectedPortfolio(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                variant="outlined"
                label="Portfolio"
                color="success" 
              />
            )}
            />*/}
        <Box sx={{ flexGrow: 1, }} />
        <Button
          endIcon={<BarChartIcon />}
          variant='contained'
          size='small'
          onClick={() => {
            setShowChart(show => !show);
          }}
        >{showChart ? 'Hide Chart' : 'View Chart'}</Button>
      </Stack>
      <div style={{ height: '100%', }}>
        <div style={{ height: '40%', display: showChart ? 'block' : 'none' }}>
          <PortfolioChart
            data={portfolios}
          />
        </div>
        <AgGridContainer
          style={{ width: '100%', height: showChart ? '60%' : '100%' }}
        >
          <PortfolioGrid
            data={portfolios}
            ref={portfolioGridRef}
          />
        </AgGridContainer>
      </div>
    </div>
  )
}