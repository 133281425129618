import { AgGridContainer } from "../../AgGrid/AgGridContainer"
import { useTheme } from "@emotion/react";
import moment from "moment";
import { useMemo, useCallback, useRef, useState, } from "react";
import { columnPanel, filterPanel } from "../../ToolPanels/DefaultToolPanels";
import { Dialog, IconButton, Typography, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import CloseIcon from "@mui/icons-material/Close";
import { tooltipRenderer, configureSparkline, } from "./UtilFunctions";

export const AddNewLegsDialog = ({ open, onClose, onAddLegs, rowData, }) => {
    const theme = useTheme();
    const ref = useRef();
    const [selectedRows, setSelectedRows] = useState([]);

    const markerFormatter = (params) => {
        const { min, highlighted } = params;
        return {
            size: highlighted ? 3 : 1,
            enabled: true,
            fill: params.yValue <= 0 ? theme.palette.primary.red : theme.palette.primary.green,
            stroke: params.yValue <= 0 ? theme.palette.primary.red : theme.palette.primary.green,
        };
    };

    const colDefs = useMemo(() => [
        {
            headerName: 'TSR Key',
            valueGetter: (params) => {
                const { PointOfReceipt, Provider, PointOfDelivery } = params?.data ?? {};
                if (PointOfReceipt && Provider && PointOfDelivery) {
                    return `${params.data.PointOfReceipt}-${params.data.Provider}-${params.data.PointOfDelivery}`;
                } else {
                    return "";
                }
            },
            checkboxSelection: true,
        },
        {
            headerName: 'Provider',
            field: 'Provider',
        },
        {
            headerName: "PointOfReceipt",
            field: "PointOfReceipt",
        },
        {
            headerName: 'PointOfDelivery',
            field: 'PointOfDelivery',
        },
        {
            headerName: 'Path Name',
            field: 'PathName',
        },
        {
            headerName: 'Capacity',
            cellRenderer: 'agSparklineCellRenderer',
            sortable: false,
            valueGetter: (params) => {
                if (params.data.Capacity) {
                    return configureSparkline(JSON.parse(params.data.Capacity));
                } else {
                    return [];
                }
            },
            cellRendererParams: {
                sparklineOptions: {
                    type: 'area',
                    axis: {
                        type: 'time',
                    },
                    tooltip: {
                        renderer: tooltipRenderer
                    },
                    marker: {
                        formatter: markerFormatter,
                    }
                },
            },
        },
        {
            headerName: 'Ceiling Price',
            cellRenderer: 'agSparklineCellRenderer',
            sortable: false,
            valueGetter: (params) => {
                if (params.data.CeilingPrice) {
                    return configureSparkline(JSON.parse(params.data.CeilingPrice));
                } else {
                    return [];
                }
            },
            cellRendererParams: {
                sparklineOptions: {
                    type: 'area',
                    axis: {
                        type: 'time',
                    },
                    tooltip: {
                        renderer: tooltipRenderer
                    },
                    marker: {
                        formatter: markerFormatter,
                    }
                },
            },
        },
        {
            headerName: 'Offer Price',
            cellRenderer: 'agSparklineCellRenderer',
            sortable: false,
            valueGetter: (params) => {
                if (params.data.OfferPrice) {
                    return configureSparkline(JSON.parse(params.data.OfferPrice));
                } else {
                    return [];
                }
            },
            cellRendererParams: {
                sparklineOptions: {
                    type: 'area',
                    axis: {
                        type: 'time',
                    },
                    tooltip: {
                        renderer: tooltipRenderer
                    },
                    marker: {
                        formatter: markerFormatter,
                    }
                },
            },
        },
        {
            headerName: 'Price Unit',
            field: 'PriceUnit',
        },
        {
            headerName: 'Offer Increment',
            field: 'OFFER_INCREMENT',
        },
        {
            headerName: 'Start Time',
            field: 'OfferStartTimeUtc',
        },
        {
            headerName: 'Stop Time',
            field: 'OfferStopTimeUtc',
        },
        {
            headerName: 'Posting Ref',
            field: 'PostingRef',
        },
        {
            headerName: 'Related Ref',
            field: 'relatedRef',
        },
        {
            headerName: 'Other Curtailment Priority',
            field: 'OTHER_CURTAILMENT_PRIORITY',
        },
        {
            headerName: 'Request Type',
            field: 'RequestType',
            rowGroup: true,
            hide: true,
        },
        {
            headerName: 'Sale Ref',
            field: 'SaleRef',
        },
        {
            headerName: 'Seller Code',
            field: 'SellerCode',
        },
        {
            headerName: 'Service Increment',
            field: 'ServiceIncrement',
        },
        {
            headerName: 'TS Class',
            field: 'TSClass',
        },
        {
            headerName: 'TS Period',
            field: 'TSPeriod',
        },
        {
            headerName: 'TS Type',
            field: 'TSType',
        },
        {
            headerName: 'TS Window',
            field: 'TSWindow',
        },
        {
            headerName: 'TS Subclass',
            field: 'TSSubclass',
        },
        {
            headerName: 'Time of Last Update',
            field: 'TimeOfLastUpdateUtc',
        },
        {
            headerName: 'NERC Priority',
            field: 'NERCcurtailmentPriority',
        },
        {
            headerName: 'Seller Comments',
            field: 'SELLER_COMMENTS',
        },
        {
            headerName: 'Seller DUNS',
            field: 'SELLER_DUNS',
        },
        {
            headerName: 'Seller Email',
            field: 'SELLER_EMAIL',
        },
        {
            headerName: 'Seller Fax',
            field: 'SELLER_FAX',
        },
        {
            headerName: 'Seller Name',
            field: 'SELLER_NAME',
        },
        {
            headerName: 'Seller Phone',
            field: 'SELLER_PHONE',
        },
        {
            headerName: 'Interface Type',
            field: 'INTERFACE_TYPE',
        },
        {
            headerName: 'ANC SVC REQ',
            field: 'ANC_SVC_REQ',
        },
    ], []);

    const defaultColDef = useMemo(() => ({
        editable: false,
        sortable: true,
        filter: "agMultiColumnFilter",
        resizable: true,
        floatingFilter: true,
        enableRowGroup: true,
    }), []);

    const statusBar = useMemo(() => {
        return {
            statusPanels: [
                { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
                { statusPanel: 'agTotalRowCountComponent', align: 'left' },
                { statusPanel: 'agSelectedRowCountComponent', align: 'left' },
                { statusPanel: 'agAggregationComponent', align: 'right' },
            ],
        };
    }, []);

    const sideBar = useMemo(() => {
        return {
            toolPanels: [
                columnPanel,
                filterPanel,
            ],
            position: 'right',
        }
    }, []);

    const noRowsOverlayText = 'No rows to display. The selected nodes have no outgoing legs.'

    const initialGroupOrderComparator = useCallback((params) => {
        // sort groups by RequestType in desc order
        const a = params.nodeA.key || "";
        const b = params.nodeB.key || "";
        return a < b ? 1 : a > b ? -1 : 0;
    }, []);

    function onSelectionChanged(params) {
        const rows = ref.current.api.getSelectedRows();
        setSelectedRows(rows);
    }

    function onRowDataUpdated(params) {
        params.columnApi.autoSizeAllColumns();
    }

    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth='lg'
        >
            <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography>Add New Legs</Typography>
                <IconButton onClick={onClose} size='large'>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Add new legs to the selected nodes. Check the legs to add, then click Add. Legs may not be shown if they would cause a loop in the network.
                </DialogContentText>
                <AgGridContainer style={{ height: '50vh', }}>
                    <AgGridReact
                        ref={ref}
                        columnDefs={colDefs}
                        rowData={rowData}
                        defaultColDef={defaultColDef}
                        statusBar={statusBar}
                        sideBar={sideBar}
                        rowSelection={"multiple"}
                        groupDisplayType='groupRows'
                        groupDefaultExpanded={1}
                        onRowDataUpdated={onRowDataUpdated}
                        initialGroupOrderComparator={initialGroupOrderComparator}
                        overlayNoRowsTemplate={`<span style="width: 70%; font-size: 20px">${noRowsOverlayText}</span>`}
                        onSelectionChanged={onSelectionChanged}
                    />
                </AgGridContainer>
            </DialogContent>
            <DialogActions sx={{ display: 'flex', justifyContent: 'space-between', }}>
                <Button
                    onClick={onClose}
                    color='primary'
                >Cancel</Button>
                <Button
                    onClick={() => onAddLegs(selectedRows)}
                    color='primary'
                    variant="contained"
                    disabled={!selectedRows.length}
                >Add</Button>
            </DialogActions>
        </Dialog>
    )
}