import { useTheme } from "@mui/styles";
import { Stack, Button, } from "@mui/material";
import { useSnackbar } from "notistack";

export const WorkspaceToolbar = ({ workspaceGridRef, data, setData, tsrKey, }) => {
    const theme = useTheme();
    const { enqueueSnackbar, } = useSnackbar();

    function clear(event) {
        event.stopPropagation();
        setData([]);
    }

    function removeSelectedRows(event) {
        event.stopPropagation();

        const selectedNodes = workspaceGridRef.current.api.getSelectedNodes();
        if (!selectedNodes.length) {
            enqueueSnackbar('No rows selected.', { variant: 'info' });
            return;
        }

        const toRemoveKeys = selectedNodes.map(node => tsrKey(node.data));

        const remaining = data.filter(row => !toRemoveKeys.includes(tsrKey(row)));
        setData(remaining);
    }

    function unselectAll(event) {
        event.stopPropagation();
        workspaceGridRef.current.api.deselectAll();
    }

    return (
        <Stack
            direction="row"
            spacing={2}
            sx={{ paddingRight: theme.spacing(2), width: '100%', justifyContent: 'flex-end', p: 1, }}
        >
            <Button
                variant='contained'
                onClick={unselectAll}
                size="small"
            >
                Unselect
            </Button>
            <Button
                variant='contained'
                onClick={removeSelectedRows}
                size="small"
            >
                Remove
            </Button>
            <Button
                variant='contained'
                onClick={clear}
                size="small"
            >
                Clear
            </Button>
        </Stack>
    );
}