import { DealEntry } from "./DealEntry";
import { Dialog, DialogContent, DialogTitle, Box, IconButton, Tooltip, Collapse, Stack, Slide } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupGuard from "../../Guards/GroupGuard";
import { userGroups } from "../../../authConfig";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useState, useRef } from "react";
import { Config } from "./Config";
import { useTheme } from "@mui/styles";

export const DealEntryDialog = ({ open, handleClose, handleSave, dealToEdit, }) => {
    const [viewConfig, setViewConfig] = useState(false);
    const contentContainerRef = useRef(null);
    const theme = useTheme();

    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth='lg'
            ref={contentContainerRef}
        >
            <DialogTitle sx={{ pb: 0, }}>
                <Stack spacing={2} direction='row' sx={{ display: 'flex', alignItems: 'center' }}>
                    {viewConfig && <Tooltip title='Back' placement='right'>
                        <IconButton onClick={() => setViewConfig(false)} size='large'>
                            <ChevronLeftIcon />
                        </IconButton>
                    </Tooltip>}
                    <Box sx={{ flexGrow: 1, }} />
                    <GroupGuard groups={[userGroups.tenantAdmin]}>
                        <IconButton onClick={() => setViewConfig(v => !v)} size='large'>
                            <SettingsIcon />
                        </IconButton>
                    </GroupGuard>
                    <IconButton onClick={handleClose} size='large'>
                        <CloseIcon />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent sx={{ display: 'flex', flexDirection: 'row', overflowX: 'hidden' }}>
                <Slide in={viewConfig} direction='left' container={contentContainerRef.current}>
                    <div style={{ display: viewConfig ? 'flex' : 'none', flexGrow: 1, padding: theme.spacing(1) }}>
                        <Config hide={() => setViewConfig(false)} />
                    </div>
                </Slide>
                <Slide in={!viewConfig} direction='right' container={contentContainerRef.current}>
                    <div style={{ display: !viewConfig ? 'flex' : 'none', flexGrow: 1 }}>
                        <DealEntry handleCancel={handleClose} handleSaveAndClose={handleSave} dealToEdit={dealToEdit} />
                    </div>
                </Slide>
            </DialogContent>
        </Dialog>
    );
};