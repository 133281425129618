import { TagReportView } from "./TagReportView";
import { AddNewOrUpdateDialog } from "./AddNewOrUpdateDialog";
import { useDashboard } from "../useDashboard";

export const TagReportDashboard = () => {
    const dashboardKey = 'tag-report';
    const useShared = true;

    const Dashboard = useDashboard({ dashboardKey, useShared, ViewComponent: TagReportView, AddNewOrUpdateDialog, });
    return Dashboard;
}