import { debounce } from 'lodash';
import { apiUrlPrefix } from '../../../authConfig';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import useHeader from '../../useHeader';
import 'ag-grid-community/styles/ag-grid.css'
import { AgGridReact, } from 'ag-grid-react';
import { useState, useMemo, useEffect, useRef, useCallback } from 'react';
import { LMPToolbar } from './LMPToolbar';
import _ from 'lodash';
import useHubAction from '../../HubContext/useHubAction_v2';
import dayjs from 'dayjs';
import { useColumnTypes } from '../../AgGrid/useColumnTypes';
import { AgGridContainer } from '../../AgGrid/AgGridContainer';
import { columnPanel, filterPanel } from '../../ToolPanels/DefaultToolPanels';
import { defaultColumnDef, defaultStatusBar } from '../../AgGrid/defaultGridProps';
import { useActionAudit } from '../../useActionAudit';
import { Ribbon } from '../Ribbon';
import { LMPGridActions, LMPActions } from './LMPToolbar';

export const LMPGrid = (props) => {
  const { view, visible, } = props;
  const headers = useHeader();
  const { enqueueSnackbar } = useSnackbar();
  const gridRef = useRef();
  const pivotOptions = { location: 'location', endTime: 'endTime', }
  const marketTypeStorageKey = 'defaultLmpMarketType';
  const defaultPivotStorageKey = 'lmpPivot';
  const defaultMarketType = localStorage.getItem(marketTypeStorageKey) ?? 'DA';
  const defaultPivot = localStorage.getItem(defaultPivotStorageKey);
  const [isLoading, setIsLoading] = useState(false);
  const [autoRefresh, setAutoRefresh] = useState(true);
  const newRows = useRef();
  const [loaded, setLoaded] = useState(false);
  const { columnTypes } = useColumnTypes();
  const [marketType, setMarketType] = useState(defaultMarketType);
  const lmpRef = useRef({});
  const allDataRef = useRef([]);
  const [pivot, setPivot] = useState(defaultPivot === pivotOptions.endTime ? pivotOptions.endTime : pivotOptions.location);
  const { logAction } = useActionAudit();

  useEffect(() => {
    if (gridRef.current && loaded) {
      retrieveTsrs();
    }
  }, [view]);

  useEffect(() => {
    visible && logAction(`User is viewing the LMP heatmap view ${view.label}`, 'LMPs', JSON.stringify({ marketType, pivot, ...view, }));
  }, [visible]);

  const fetchDebounced = useCallback(debounce(async () => {
    return silentRetrieveTsrs();
  }, 2000, { leading: true, }), [view, gridRef.current])

  const { diffData, } = useHubAction({
    action: fetchDebounced,
    allowedMessages: ['trg_TransQueueUpdateTrigger'],
    predicate: visible && autoRefresh && !!gridRef.current?.api,
    callbackDependencies: [gridRef.current?.api, headers],
  });

  const defaultColDef = useMemo(() => ({
    editable: false,
    ...defaultColumnDef,
  }), []);

  const colDefs = useMemo(() => [
    {
      headerName: 'ISO',
      field: 'ISO',
    },
    {
      headerName: 'Location',
      field: 'Location',
      rowGroup: pivot === pivotOptions.endTime,
      hide: true,
      pivot: pivot === pivotOptions.location,
    },
    /*{
      headerName: 'Market Type',
      field: 'Market_Type',
      rowGroup: true,
      hide: true,
    },*/
    {
      headerName: 'Total LMP',
      field: 'Total_LMP',
      type: 'numericColumn',
      valueFormatter: params => params.value?.toFixed(2),
      cellStyle: params => ({
        backgroundColor: colorForLmpValue(params.value),
        textShadow: 'black 1px 1px 4px',
        color: 'white',
      }),
      aggFunc: 'sum',
    },
    {
      headerName: 'End Time',
      type: 'dateColumn',
      //field: 'EndTime',
      valueGetter: params => dayjs(params.data.EndTime).format('MM/DD/YYYY HH:mm:ss'),
      rowGroup: pivot === pivotOptions.location,
      hide: true,
      pivot: pivot === pivotOptions.endTime,
    },
  ], [pivot]);

  const sideBar = useMemo(() => {
    return {
      toolPanels: [
        columnPanel,
        filterPanel,
      ],
      position: 'right',
    }
  }, []);

  const statusBar = useMemo(() => defaultStatusBar, []);

  function onRowDataUpdated(params) {
    if (newRows.current?.length && loaded) {
      const nodes = newRows.current.map(id => params.api.getRowNode(id)).filter(node => node);
      params.api.flashCells({
        rowNodes: nodes,
        flashDelay: 1000,
        fadeDelay: 2000,
      });
      newRows.current = undefined;
    }
  }

  function getRowNodeId(params) {
    return params.data.ID;
  }

  async function silentRetrieveTsrs() {
    console.log(`TSRs retrieved silently at ${dayjs().format('HH:mm:ss.SSS')}.`)
    return fetch().then(response => {
      if (response) {
        allDataRef.current = response.data ?? [];
        handleDataUpdate(response.data ?? []);
      }
    });
  }

  async function fetch() {
    const uri = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_fetchCurrentLMPs`
      + `&parm=${view.timezone}`
      + `&parm=${view.locations}`

    const options = {
      headers: headers,
      url: uri,
    }

    return axios(options).catch(error => {
      enqueueSnackbar(`Error loading data for a grid from ${uri}. ${error.response?.data} Status: ${error.response?.status}. Message: ${error}`, { variant: 'error', })
    }).finally(() => {
      gridRef.current?.api.hideOverlay();
      setIsLoading(false);
    });
  }

  function retrieveTsrs() {
    gridRef.current.api.showLoadingOverlay();
    setIsLoading(true);

    fetch().then(response => {
      allDataRef.current = response.data ?? [];
      handleDataUpdate(response.data ?? [])
      enqueueSnackbar(`${view.label} TSR data retrieved.`, { variant: 'info', });
      gridRef.current.api.redrawRows();
      gridRef.current.api.hideOverlay();
      setIsLoading(false);
    })
  }

  const rowKey = (row) => `${row.ISO}_${row.Location}_${row.Market_Type}_${row.EndTime}`;

  function handleDataUpdate(newData, type = marketType) {
    const timestamp = dayjs();

    const filtered = newData.filter(row => row.Market_Type === type);
    filtered.forEach(row => row.ID = rowKey(row));

    const minLmp = Math.min(...filtered.map(row => row.Total_LMP));
    const maxLmp = Math.max(...filtered.map(row => row.Total_LMP));
    lmpRef.current = { minLmp, maxLmp, };

    const oldData = [];
    gridRef.current.api.forEachNode(node => {
      !node.group && oldData.push(node.data);
    });

    const { toAdd, toUpdate, toDelete, } = diffData(filtered, oldData);

    gridRef.current.api.applyTransaction({
      add: toAdd,
      addIndex: 0,
      update: toUpdate,
      remove: toDelete,
    });

    console.log(`LMP grid update complete. ${dayjs().diff(timestamp)}ms elapsed.`)
    setLoaded(true);
  }

  const onGridReady = () => {
    if (gridRef.current) {
      silentRetrieveTsrs();
    }
  }

  const colorForLmpValue = (lmp) => {
    const { minLmp, maxLmp, } = lmpRef.current;
    const minCap = Math.max(minLmp, -100);
    const maxCap = Math.min(maxLmp, 100);
    const slope = 1.0 / (maxCap - minCap);
    const intercept = -minCap / (maxCap - minCap);
    const effectivePrice = Math.max(Math.min(lmp, maxCap), minCap);
    const val = slope * effectivePrice + intercept;
    const h = (1.0 - val) * 240;
    return "hsl(" + h + ", 100%, 40%)";
  }

  function handleMarketTypeChange(_, newMarketType) {
    if (newMarketType) {
      logAction(`User changed LMP market type to ${newMarketType}`, 'LMPs', JSON.stringify({ marketType: newMarketType, pivot: pivot, ...view, }));
      setMarketType(newMarketType);
      handleDataUpdate(allDataRef.current, newMarketType);
      localStorage.setItem(marketTypeStorageKey, newMarketType);
      gridRef.current.api.redrawRows();
    }
  }

  function handlePivotChange(newPivot) {
    setPivot(newPivot);
    logAction(`User changed LMP pivot to ${newPivot}`, 'LMPs', JSON.stringify({ marketType, pivot: newPivot, ...view, }));
    localStorage.setItem('lmpPivot', newPivot);
  }

  function handleToggleAutoRefresh() {
    logAction(`User toggled LMP auto-refresh`, 'LMPs', JSON.stringify({ autoRefresh: !autoRefresh, marketType, pivot, ...view, }));
    setAutoRefresh(!autoRefresh);
  }

  function handleRefresh() {
    logAction(`User manually refreshed LMP data`, 'LMPs', JSON.stringify({ autoRefresh, marketType, pivot, ...view, }));
    retrieveTsrs();
  }

  /*const processPivotResultColGroupDef = (colGroupDef) => {
    if (dayjs(colGroupDef.headerName).isValid()) {
      colGroupDef.headerName = dayjs(colGroupDef.headerName).format('MM/DD/YYYY HH:mm:ss');
    } else {
      colGroupDef.headerName = colGroupDef.headerName;
    }
  };*/

  return (
    <AgGridContainer
      style={{
        width: "100%", height: '73vh'
      }}
    >
      <Ribbon>
        {[
          { title: 'Actions', content: <LMPActions /> },
          {
            title: 'Grid', content: <LMPGridActions
              onRefresh={handleRefresh}
              disableAllButtons={isLoading}
              autoRefresh={autoRefresh}
              toggleAutoRefresh={handleToggleAutoRefresh}
              marketType={marketType}
              handleMarketTypeChange={handleMarketTypeChange}
              timezone={view.timezone}
              pivot={pivot}
              handlePivotChange={handlePivotChange}
            />
          },
        ]}
      </Ribbon>
      <AgGridReact
        ref={gridRef}
        onGridReady={onGridReady}
        columnDefs={colDefs}
        getRowId={getRowNodeId}
        onRowDataUpdated={onRowDataUpdated}
        defaultColDef={defaultColDef}
        sideBar={sideBar}
        statusBar={statusBar}
        enableCharts={true}
        enableCellTextSelection={true}
        paginationAutoPageSize={true}
        groupDefaultExpanded={1}
        removePivotHeaderRowWhenSingleValueColumn
        //processPivotResultColGroupDef={processPivotResultColGroupDef}
        suppressAggFuncInHeader
        groupDisplayType='groupRows'
        animateRows={true}
        groupSelectsChildren={true}
        columnTypes={columnTypes}
        pivotMode={true}
      />
    </AgGridContainer>
  )
}
