import { useImperativeHandle, useRef, forwardRef, useState, useEffect, useCallback, } from "react"
import { Box, Tooltip, Stack } from "@mui/material"
import { FilterCriteriaFormGroup } from "./FilterCriteriaFormGroup"
import { AgGridContainer } from "../../AgGrid/AgGridContainer"
import { Ribbon, } from "../Ribbon"
import { UnscheduledMWsGrid } from "./UnscheduledMWsGrid"
import { ScheduleDialog } from "../Scheduling/ScheduleDialog"
import RemoveDoneIcon from '@mui/icons-material/RemoveDone';
import { RibbonButton } from "../Ribbon"
import Refresh from "@mui/icons-material/Refresh";
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth';
import { useApi } from "../../useApi"
import dayjs from "dayjs"
import debounce from "lodash/debounce"

export const UnscheduledMWsView = forwardRef(({ view, }, ref) => {
    const [viewData, setViewData] = useState();
    const [date, setDate] = useState(dayjs().format('MM/DD/YYYY'));
    const gridRef = useRef();
    const [selectedRows, setSelectedRows] = useState({
        purchases: [],
        sales: [],
    });
    const { get, headers, apiUrlPrefix, enqueueSnackbar, logAction } = useApi();

    useEffect(() => {
        fetchData(view);
    }, [view]);

    const fetchData = useCallback(debounce(async (data) => {
        logAction('User fetched Unscheduled MWs Data', 'Unscheduled MWs', data);
        const { timezone, startDate, stopDate } = data;
        gridRef.current?.showLoadingOverlay();
        setDate(startDate);

        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=dealrizz.UI_fetchUnscheduledMW`
            + `&parm=${headers.userGuid}`
            + `&parm=${dayjs(startDate).format('YYYY-MM-DD')}`
            + `&parm=${dayjs(stopDate).format('YYYY-MM-DD')}`
            + `&parm=${timezone}`

        return get(url, { headers: headers }).then(response => {
            let newData = response?.data ?? [];
            setViewData(newData);
        }).catch((error) => {
            enqueueSnackbar(error.message, { variant: 'error' });
        }).finally(() => {
            gridRef.current?.hideOverlay();
        });
    }, 100), [headers]);

    useImperativeHandle(ref, () => {
        return {
            captureLayout: () => gridRef.current?.captureLayout(),
            refresh: (data) => fetchData(data),
        };
    });

    const toolbarFormId = `unscheduled-mws-toolbar-form-${view.id}`;

    function handleRowSelection(rows = []) {
        //only make the rows available to the toolbar if there are two rows selected, one purchase and one sale
        const purchases = rows.filter(row => row.Transaction_Type === 'Purchase');
        const sales = rows.filter(row => row.Transaction_Type === 'Sale');
        setSelectedRows({ purchases, sales });
    }

    return (
        <Box sx={{ height: '86vh', display: 'flex', flexDirection: 'column' }}>
            <Ribbon>
                {[
                    {
                        title: 'Grid', content: <ActionsGroup
                            formId={toolbarFormId}
                            selectedRows={selectedRows}
                            context={view}
                            sourceSinkOptions={[]}
                            scheduleFormId={'unschedule-mws-scheduler-form'}
                            gridRef={gridRef}
                        />
                    },
                    { title: 'Filter', content: <FilterCriteriaFormGroup view={view} onSubmit={fetchData} formId={toolbarFormId} /> },
                ]}
            </Ribbon>
            <AgGridContainer style={{ display: 'flex', flex: 1, width: '100%' }}>
                <UnscheduledMWsGrid
                    context={view}
                    ref={gridRef}
                    setSelectedRows={handleRowSelection}
                    data={viewData}
                    date={date}
                />
            </AgGridContainer>
        </Box>
    )
});

const ActionsGroup = ({ formId, expanded, selectedRows, context, sourceSinkOptions, scheduleFormId, gridRef }) => {
    const [openScheduleDialog, setOpenScheduleDialog] = useState(false);
    const allowSchedule = !!selectedRows.purchases?.length && !!selectedRows.sales?.length;

    return (
        <Stack direction='row' spacing={1} sx={{ p: 1, display: 'flex', flexWrap: 'wrap', rowGap: '16px', alignItems: 'start', flexDirection: 'row' }}>
            <ScheduleDialog
                open={openScheduleDialog}
                handleCancel={() => setOpenScheduleDialog(false)}
                selectedRows={selectedRows}
                context={context}
                formId={scheduleFormId}
                sourceSinkOptions={sourceSinkOptions}
            />
            <RibbonButton
                onMouseDown={() => gridRef.current?.clearSelected()}
                icon={<RemoveDoneIcon fontSize='small' />}
                label={'Clear Selections'}
                expanded={expanded}
            />
            <RibbonButton
                type='submit'
                id={`${formId}-refresh`}
                form={formId}
                icon={<Refresh fontSize='small' />}
                label={'Refresh All'}
                expanded={expanded}
            />
            <Tooltip title='Select a purchase and a sale below, then click here to create a schedule. '>
                <span>
                    <RibbonButton
                        disabled={!allowSchedule}
                        icon={<CalendarViewMonthIcon fontSize='small' />}
                        label={'Schedule'}
                        expanded={expanded}
                        onMouseDown={() => setOpenScheduleDialog(true)}
                    />
                </span>
            </Tooltip>
        </Stack>
    )
}