import { IconButton, Stack, TextField, } from "@mui/material"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, } from "@mui/x-date-pickers";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import { useMemo, useEffect, useState } from "react";
import dayjs from "dayjs";
import FormAutocomplete from "../../FormControls/FormAutocomplete";
import FormDatePicker from "../../FormControls/FormDatePicker";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { apiUrlPrefix } from "../../../authConfig";
import axios from "axios";
import { useSnackbar } from "notistack";
import useHeader from "../../useHeader";
import { timeTypes, } from "../../../constants";

const schema = yup.object().shape({
    timezone: yup.string().required('Timezone is required'),
    timeType: yup.string().required('Time Type is required'),
});

export const Toolbar = ({ view, onSubmit, formId, expanded }) => {
    const { enqueueSnackbar } = useSnackbar();
    const headers = useHeader();
    const [bookOptions, setBookOptions] = useState([]);

    const defaults = {
        timezone: 'Pacific Standard Time',
        book: '',
        timeType: 'daily',
        ...view,
        date: dayjs(),
    }

    const defaultValues = useMemo(() => defaults, [view]);

    const { handleSubmit, formState: { errors }, control, watch, getValues, setValue, reset, register, } = useForm({
        resolver: yupResolver(schema),
        defaultValues,
        mode: 'onBlur',
    });

    useEffect(() => {
        const subscription = watch(handleSubmit(onSubmit));
        return () => subscription.unsubscribe();
    }, [handleSubmit, watch]);

    useEffect(() => {
        fetchBookOptions();
    }, []);

    useEffect(() => {
        reset(defaults);
    }, [view]);

    async function fetchBookOptions() {
        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=dealrizz.UI_fetchBookName`

        return axios.get(url, { headers: headers }).then(response => {
            const allBooks = response?.data?.map(obj => obj.book) ?? [];
            const parsed = allBooks.reduce((acc, val) => {
                //this is a bit of a mess, but the values can be in a few different formats, don't ask why
                //values may be comma separated lists, like 'MyBook,MyOtherBook'
                //they may also be stringified arrays, like'[{"label":"MyBook","percentage":"100"}]' 
                //try to parse, if it fails, split
                let parsedVal;
                try {
                    parsedVal = JSON.parse(val)?.map(b => b.label);
                } catch (e) {
                    parsedVal = val.split(',').map(b => b.trim());
                }
                return [...acc, ...parsedVal];
            }, []);
            setBookOptions([...new Set(parsed)]); //remove duplicates
        }).catch((error) => {
            enqueueSnackbar(error.message, { variant: 'error' });
        })
    }

    function handleIncrementDate(days) {
        const tradeDate = getValues('date');
        setValue('date', tradeDate.add(days, 'day'));
    }

    return (
        <form id={formId} onSubmit={handleSubmit(onSubmit)}>
            <Stack direction='row' spacing={2} alignItems='stretch' sx={{ p: 1, pt: 2, display: 'flex', flexWrap: 'wrap', rowGap: '16px' }}>
                <div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <IconButton size='small' onClick={() => {
                            handleIncrementDate(-1);
                        }}>
                            <ArrowLeftIcon />
                        </IconButton>
                        <FormDatePicker
                            name='date'
                            control={control}
                            label="Flow Date"
                            slotProps={{
                                textField: {
                                    size: 'small',
                                    error: !!errors.date,
                                    helperText: expanded && (errors.date?.message ?? `Time Zone: ${getValues('timezone')?.split(' ')[0]}`),
                                    sx: { minWidth: '140px', maxWidth: '150px', },
                                }
                            }}
                        />
                        <IconButton size='small' onClick={() => {
                            handleIncrementDate(1);
                        }}>
                            <ArrowRightIcon />
                        </IconButton>
                    </LocalizationProvider>
                </div>

                {/* <FormAutocomplete
                    autoHighlight
                    options={timeTypes}
                    getOptionLabel={(option) => {
                        return option.charAt(0).toUpperCase() + option.slice(1);
                    }}
                    control={control}
                    name='timeType'
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label="Time Type"
                            size='small'
                            color="success"
                            sx={{ minWidth: '150px', }}
                        />
                    )}
                /> */}

                {/* <FormAutocomplete
                    autoHighlight
                    options={bookOptions}
                    control={control}
                    name='book'
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label="Book"
                            size='small'
                            color="success"
                            sx={{ minWidth: '250px', }}
                        />
                    )}
                /> */}

            </Stack>
        </form>
    );
}