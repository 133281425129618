import { useDashboard } from "../useDashboard";
import { AddNewOrUpdateDialog } from "./AddNewOrUpdateDialog";
import { IndexPricingView } from "./IndexPricingView";

export const IndexPricingDashboard = () => {
    const dashboardKey = 'IndexPricing';
    const useShared = true;

    const Dashboard = useDashboard({ dashboardKey, useShared, ViewComponent: IndexPricingView, AddNewOrUpdateDialog, });
    return Dashboard;
}