import { AgGridReact } from "ag-grid-react"
import { forwardRef, useCallback, useState, useMemo, useRef, useEffect, useImperativeHandle } from "react";
import { defaultColumnDef, defaultStatusBar } from "../../AgGrid/defaultGridProps"
import { useColumnTypes } from "../../AgGrid/useColumnTypes"
import { columnPanel, filterPanel, palettePanel, } from "../../ToolPanels/DefaultToolPanels"
import { LayoutToolPanel } from "../../ToolPanels/LayoutToolPanel"
import useGridLayout from "../../useGridLayout"
import dayjs from "dayjs"
import { heatmapStyle, tooltipRenderer, handleUpdateTotalRows, redPastMarkerFormatter, diffData } from "../Utils"
import { PaletteToolPanel } from "../../ToolPanels/PalettePanel"
import { ProfileDetailRenderer } from "./ProfileDetailRenderer";
import debounce from "lodash/debounce"
import { useGridCrossHighlight } from "../useGridCrossHighlight"
import { Box } from "@mui/material";
import { useApi } from "../../useApi";

export const TagReportGrid = forwardRef(({ context, setSelectedRows, showStatusBar = true, setSourceSinkOptions, setSelectedAdjustment }, ref) => {
    const layoutStorageKey = `deal-rizz-tag-report-grid-layout-${context.id}`;
    const { columnTypes } = useColumnTypes();
    const paletteRef = useRef({ showHeatmap: false });
    const gridRef = useRef();
    const [fetchContext, setFetchContext] = useState(context);
    const { highlightingCellClassRules, clearHighlighting, handleCellMouseOverHighlight, } = useGridCrossHighlight(gridRef);
    const { get, post, logAction, headers, apiUrlPrefix, enqueueSnackbar } = useApi();

    const fetchData = useCallback(debounce(async (data) => {
        logAction('User fetched Tag Report Data', 'Tag Reports', data);
        const { timezone, startDate, stopDate, onlyShowNonZeroCuts, source, excludeMarketPathPSE } = data;
        gridRef.current?.api?.showLoadingOverlay();
        setFetchContext({ ...fetchContext, ...data });

        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=dealrizz.UI_TagReport_FetchSummary_for_scheduling`
            + `&parm=${headers.userGuid}`
            + `&parm=${timezone}`
            + `&parm=${dayjs(startDate).format('MM/DD/YYYY')}`
            + `&parm=${dayjs(stopDate ?? startDate).format('MM/DD/YYYY')}`;

        return get(url).then(response => {
            const newData = response?.data ?? [];

            //clear old data
            const oldData = [];
            gridRef.current.api.forEachNode(node => {
                oldData.push(node.data);
            });

            const { toAdd, toUpdate, toDelete, } = diffData(newData, oldData, (data) => getRowId({ data, }));
            gridRef.current.api.applyTransaction({ add: toAdd, update: toUpdate, remove: toDelete });

            if (!!setSourceSinkOptions) {
                const options = newData.reduce((acc, next) => {
                    acc.sources.add(next.Source);
                    acc.sinks.add(next.Sink);
                    return acc;
                }, { sources: new Set(), sinks: new Set() });
                setSourceSinkOptions({ sources: Array.from(options.sources), sinks: Array.from(options.sinks) });
            }
        }).finally(() => {
            gridRef.current?.api?.hideOverlay();
        });
    }, 100), [headers]);

    const baseColDefs = useMemo(() => ([
        {
            headerName: 'Tag Idx',
            field: 'tagIdx',
            checkboxSelection: true,
            cellDataType: 'text',
            cellRenderer: 'agGroupCellRenderer',
        },
        {
            headerName: 'Tag Code',
            field: 'tagCode',
        },
        {
            headerName: 'Tag Status',
            field: 'tagStatus',
        },
        {
            headerName: 'Date',
            field: 'tagDate',
            type: 'dateColumn',
            dateFormat: 'MM/DD/YYYY',
        },
        { field: 'firstPOR', headerName: 'First POR', },
        { field: 'lastPOD', headerName: 'Last POD', },
        {
            field: 'Source',
        },
        {
            field: 'Sink',
        },
        {
            headerName: 'Profile Graph',
            cellRenderer: 'agSparklineCellRenderer',
            sortable: false,
            initialWidth: 300,
            valueGetter: (params) => {
                const { data, } = params;
                if (!data) return [];
                return [...Array.from({ length: 26 }).keys()].reduce((acc, next) => {
                    const hour = next + 1;

                    acc.push(
                        { x: dayjs(data.tagDate).startOf('day').add(hour - 1, 'hours').toDate(), y: data[hour] ?? 0 },
                        { x: dayjs(data.tagDate).startOf('day').add(hour, 'hours').toDate(), y: data[hour] ?? 0 },
                    );
                    return acc;
                }, []);
            },
            cellRendererParams: {
                sparklineOptions: {
                    type: 'area',
                    axis: {
                        type: 'time',
                    },
                    tooltip: {
                        renderer: tooltipRenderer
                    },
                    marker: {
                        formatter: redPastMarkerFormatter,
                    },
                },
            },
        },
        ...Array.from({ length: 26 }, (_, i) => ({
            headerName: `${i + 1}`,
            field: `${i + 1}`,
            // aggFunc: 'sum',
            // enableValue: true,
            editable: params => params.data.ProfileType?.toLowerCase() === 'current',
            enableRowGroup: false,
            aggFunc: 'sum',
            minWidth: 25,
            chartDataType: 'series',
            type: 'numericColumn',
            cellDataType: 'number',
            cellStyle: params => heatmapStyle(params, paletteRef),
        })),
        {
            headerName: 'OASIS #',
            field: 'OASIS Number',
        },
        {
            headerName: 'Deal Type',
            field: 'dealType',
        },
        {
            headerName: 'Counterparty',
            field: 'counterParty',
        },
        {
            headerName: 'Composite State',
            field: 'compositeState',
        },
        {
            headerName: 'PSE Contact',
            field: 'PSE Contact',
        },
        {
            headerName: 'Profile Type',
            field: 'ProfileType',
        },
        {
            headerName: 'CC List',
            field: 'CC List',
            valueFormatter: params => {
                //insert spaces after each comma for readability
                return params.value?.split(',').join(', ');
            },
        },
        {
            field: 'LCA',
        },
        {
            field: 'GCA',
        },
        {
            field: 'CPSE',
        },
        {
            field: 'Buy/Sell',
        },
        {
            field: 'Start Time',
            type: 'dateColumn',
        },
        {
            field: 'Stop Time',
            type: 'dateColumn',
        },
        {
            field: 'LastUpdated',
            headerName: 'Last Updated',
            type: 'dateColumn',
            aggFunc: 'max',
            enableValue: true,
            enableRowGroup: false,
            valueFormatter: params => {
                //the time is in UTC, so we need to convert it to the local time zone. Shift the time by the offset.
                const offset = dayjs().utcOffset();
                return params.value ? dayjs(params.value).add(offset, 'minute').format('MM/DD/YYYY HH:mm') : '';
            },
        },
        {
            headerName: 'Schedule ID',
            field: 'scheduleId',
        },
        {
            headerName: 'Deal Id',
            field: 'dealId',
        },
        {
            field: 'Notes',
        },
        {
            field: 'Market Path',
        },
        {
            field: 'Total',
        },
    ]), []);

    const defaultColDef = useMemo(() => ({
        ...defaultColumnDef,
        editable: false,
        minWidth: 100,
        //enableCellChangeFlash: true,
    }), []);

    const { colDefs, layoutPanel, applyLayout, loadLayout, captureLayout, applyFilters, applySavedFilters, } = useGridLayout(layoutStorageKey, gridRef, baseColDefs, defaultColDef);

    const styledColDefs = useMemo(() => colDefs.map(colDef => ({
        ...colDef,
        cellClassRules: highlightingCellClassRules,
    })), [colDefs, highlightingCellClassRules]);

    useImperativeHandle(ref, () => {
        return {
            captureLayout,
            refresh: (data) => fetchData(data),
            clearSelected: () => gridRef.current?.api.deselectAll(),
            applyTransaction: (transaction) => gridRef.current?.api?.applyTransaction(transaction),
            forEachNode: (callback) => gridRef.current?.api?.forEachNode(callback),
        };
    });

    function onGridReady(params) {
        if (context?.layout && !localStorage.getItem(layoutStorageKey)) {
            applyLayout(context.layout);
        } else {
            loadLayout();
        }
    }

    const handleFirstDataRendered = useCallback(() => {
        if (context?.layout?.filters && !localStorage.getItem(layoutStorageKey)) {
            applyFilters(context.layout.filters);
        } else if (localStorage.getItem(layoutStorageKey)) {
            applySavedFilters();
        }
    }, []);

    const handleRowSelected = useCallback((params) => {
        const selectedRows = gridRef.current?.api?.getSelectedRows();
        setSelectedRows(selectedRows);
    }, []);

    const updateTotalRows = useCallback(({ api, }) => {
        handleUpdateTotalRows(api, 'tagIdx');
    }, []);

    const gridOptions = useMemo(() => ({
        rowClassRules: {
            "ag-custom-total-row": params => !!params.node.rowPinned,
        },
    }), []);

    const sideBar = useMemo(() => ({
        toolPanels: [
            columnPanel,
            filterPanel,
            layoutPanel,
            palettePanel(gridRef, paletteRef, 'tag-report-palette'),
        ]
    }), []);

    const detailCellRenderer = useMemo(() => {
        return (props) => ProfileDetailRenderer(props);
    }, []);

    const detailRendererParams = useMemo(() => ({
        context: fetchContext,
        setSelectedAdjustment,
    }), [fetchContext]);

    const handleRowGroupOpened = useCallback((params) => {
        if (params.node.expanded && !params.node.selected) {
            params.node.setSelected(true);
        }
    }, []);

    function handleCellValueChanged(params) {
        const { data, } = params;
        if (data.tagStatus?.toLowerCase() === 'draft') {
            handleDraftTagUpdate(data);
        }
    }

    const handleDraftTagUpdate = useCallback(debounce((data) => {
        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Push?name=dealrizz.UI_TagReport_ModifyEnergyOnDraftTag`
            + `&parm=${headers.userGuid}`
            + `&parm=${data.tagIdx}`
            + `&parm=${context.timezone}`

        post(url, data).then(response => {
            enqueueSnackbar('Draft modification saved.', { variant: 'info' });
        });
    }, 300), [headers]);

    const getRowId = useCallback((params) => {
        const { data, } = params;
        return `${data.tagIdx}-${data.ProfileType}`;
    }, []);

    return (
        <Box onMouseLeave={clearHighlighting} sx={{ width: '100%', height: '100%' }}>
            <AgGridReact
                containerStyle={{ width: '100%', height: '100%' }}
                getRowId={getRowId}
                ref={gridRef}
                columnDefs={styledColDefs}
                gridOptions={gridOptions}
                onRowSelected={handleRowSelected}
                onFilterChanged={updateTotalRows}
                onRowDataUpdated={updateTotalRows}
                onCellValueChanged={handleCellValueChanged}
                onRowGroupOpened={handleRowGroupOpened}
                enableRangeSelection
                rowSelection="single"
                rowMultiSelectWithClick
                enableFillHandle
                enableCharts
                onFirstDataRendered={handleFirstDataRendered}
                suppressAggFuncInHeader={true}
                groupTotalRow={"bottom"}
                groupSuppressBlankHeader={false}
                stopEditingWhenCellsLoseFocus
                singleClickEdit
                onGridReady={onGridReady}
                columnTypes={columnTypes}
                statusBar={showStatusBar ? defaultStatusBar : undefined}
                sideBar={sideBar}
                masterDetail
                detailRowAutoHeight
                detailCellRenderer={'detailCellRenderer'}
                detailCellRendererParams={detailRendererParams}
                onCellMouseOver={handleCellMouseOverHighlight}
                components={{
                    layoutToolPanel: LayoutToolPanel,
                    paletteToolPanel: PaletteToolPanel,
                    detailCellRenderer: detailCellRenderer,
                }}
            />
        </Box>
    );
});