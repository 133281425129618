import { AgGridContainer } from "../../../AgGrid/AgGridContainer";
import { AgGridReact } from "ag-grid-react";
import { tooltipRenderer, redPastMarkerFormatter, heatmapStyle, } from "../../Utils";
import { columnPanel, filterPanel, palettePanel } from "../../../ToolPanels/DefaultToolPanels";
import { defaultColumnDef, defaultStatusBar } from "../../../AgGrid/defaultGridProps";
import { PaletteToolPanel } from "../../../ToolPanels/PalettePanel";
import { useColumnTypes } from "../../../AgGrid/useColumnTypes";
import { useRef, useMemo, forwardRef } from "react";
import dayjs from "dayjs";

export const AddTsrGrid = forwardRef(({ setSelectedTsrs, data }, ref) => {
    const { columnTypes } = useColumnTypes();
    const paletteRef = useRef({ showHeatmap: false });

    function configureSparkline(availabilityData) {
        const parsed = availabilityData.map(block => ({
            Capacity: parseFloat(block.capacityAvailable ?? 0),
            Start: new dayjs(block.startDateTime).toDate(),
            Stop: new dayjs(block.endDateTime).toDate(),
        })).sort((a, b) => a.Start - b.Start);

        return parsed.reduce((data, xyObj) => {
            return [...data, {
                x: xyObj.Start,
                y: xyObj.Capacity,
            }, {
                x: xyObj.Stop,
                y: xyObj.Capacity,
            }]
        }, []);
    }

    const colDefs = useMemo(() => ([
        { field: "AssignmentRef", headerName: "Aref", checkboxSelection: true, cellDataType: 'text' },
        { field: "ID", headerName: "ID", initialHide: true },
        { field: "Status", headerName: "Status" },
        { field: "Provider", headerName: "Provider" },
        { field: "SellerCode", headerName: "Seller Code" },
        { field: "CustomerCode", headerName: "Customer Code" },
        { field: "PointOfReceipt", headerName: "POR" },
        { field: "PointOfDelivery", headerName: "POD" },
        { field: "Source", headerName: "Source" },
        { field: "Sink", headerName: "Sink" },
        {
            headerName: 'Position Graph',
            cellRenderer: 'agSparklineCellRenderer',
            sortable: false,
            minWidth: 350,
            flex: 4,
            valueGetter: (params) => {
                if (params.data.profileJson) {
                    return configureSparkline(JSON.parse(params.data.profileJson));
                } else {
                    return [];
                }
            },
            cellRendererParams: {
                sparklineOptions: {
                    type: 'area',
                    axis: {
                        type: 'time',
                    },
                    tooltip: {
                        renderer: tooltipRenderer
                    },
                    marker: {
                        formatter: redPastMarkerFormatter,
                    },
                },
            },
        },
        { field: "Capacity", headerName: "Capacity" },
        { field: "ServiceIncrement", headerName: "Service Increment" },
        { field: "TSClass", headerName: "TS Class" },
        { field: "TSType", headerName: "TS Type" },
        { field: "TSPeriod", headerName: "TS Period" },
        { field: "TSWindow", headerName: "TS Window" },
        { field: "TSSubclass", headerName: "TS Subclass" },
        { field: "SaleRef", headerName: "Sale Reference" },
        { field: "ReassignedStopTime", headerName: "Reassigned Stop Time", type: 'dateColumn', },
        { field: "RequestType", headerName: "Request Type" },
        { field: "RelatedRef", headerName: "Related Reference" },
        { field: "NERCcurtailmentPriority", headerName: "NERC Curtailment Priority" },
        { field: "Preconfirmed", headerName: "Preconfirmed" },
        { field: "TimeQueued", headerName: "Time Queued", type: 'dateColumn', },
        { field: "ResponseTimeLimit", headerName: "Response Time Limit", type: 'dateColumn', },
        { field: "TimeOfLastUpdate", headerName: "Time Of Last Update", type: 'dateColumn', },
        { field: "SELLER_NAME", headerName: "Seller Name" },
        { field: "CUSTOMER_NAME", headerName: "Customer Name" },
        { field: "CUSTOMER_PHONE", headerName: "Customer Phone" },
        { field: "CUSTOMER_FAX", headerName: "Customer Fax" },
        { field: "CUSTOMER_EMAIL", headerName: "Customer Email" }
    ]), []);

    const defaultColDef = useMemo(() => ({
        ...defaultColumnDef,
        minWidth: 120,
        flex: 1,
    }), []);

    const sideBar = useMemo(() => ({
        toolPanels: [
            columnPanel,
            filterPanel,
            palettePanel(ref, paletteRef, 'transmission-position-palette'),
        ]
    }), []);

    function handleFirstDataRendered() {
        //autosize all columns
        ref.current.columnApi.autoSizeAllColumns();
    }

    function handleSelectionChanged() {
        const selectedData = ref.current.api.getSelectedRows();
        setSelectedTsrs(selectedData);
    }

    return (
        <AgGridContainer style={{ height: '65vh' }}>
            <AgGridReact
                containerStyle={{ display: 'flex', flexDirection: 'column', flex: 1, width: '100%', }}
                rowData={data}
                ref={ref}
                columnDefs={colDefs}
                defaultColDef={defaultColDef}
                enableRangeSelection
                rowSelection="multiple"
                rowMultiSelectWithClick
                onFirstDataRendered={handleFirstDataRendered}
                enableCharts
                onSelectionChanged={handleSelectionChanged}
                suppressAggFuncInHeader={true}
                enableFillHandle
                groupTotalRow={"bottom"}
                groupSuppressBlankHeader={true}
                stopEditingWhenCellsLoseFocus
                singleClickEdit
                columnTypes={columnTypes}
                statusBar={defaultStatusBar}
                sideBar={sideBar}
                components={{
                    paletteToolPanel: PaletteToolPanel,
                }}
            />
        </AgGridContainer>
    )
});