import { useMemo, useState } from "react";
import { AgChartsReact } from 'ag-charts-react';
import { useTheme } from "@mui/material";
import { useColorMode } from "../../styles/ColorMode/useColorMode";

export default (props) => {
  const { data, } = props;
  const theme = useTheme();
  const colors = theme.palette.primary;
  const { mode } = useColorMode();

  function getData() {
    return [
      {
        quarter: "Q1'2023",
        unfufilled: 140,
        originalfirm: 20,
        originalnonfirm: 0,
        resalenonfirm: 10,
        resalefirm: 20,
      },
      {
        quarter: "Q2'2023",
        unfufilled: 100,
        originalfirm: 30,
        originalnonfirm: 0,
        resalenonfirm: 0,
        resalefirm: 50,
      },
      {
        quarter: "Q3'2023",
        unfufilled: 80,
        originalfirm: 20,
        originalnonfirm: 30,
        resalenonfirm: 0,
        resalefirm: 30,
      },
      {
        quarter: "Q4'2023",
        unfufilled: 40,
        originalfirm: 50,
        originalnonfirm: 20,
        resalenonfirm: 30,
        resalefirm: 50,
      },
      {
        quarter: "Q1'2024",
        unfufilled: 20,
        originalfirm: 20,
        originalnonfirm: 20,
        resalenonfirm: 20,
        resalefirm: 20,
      },
      {
        quarter: "Q2'2024",
        unfufilled: 30,
        originalfirm: 50,
        originalnonfirm: 50,
        resalenonfirm: 20,
        resalefirm: 20,
      },
      {
        quarter: "Q3'2024",
        unfufilled: 10,
        originalfirm: 20,
        originalnonfirm: 30,
        resalenonfirm: 40,
        resalefirm: 60,
      },
      {
        quarter: "Q4'2024",
        unfufilled: 80,
        originalfirm: 20,
        originalnonfirm: 20,
        resalenonfirm: 20,
        resalefirm: 20,
      },
    ];
  }

  const options = useMemo(() => ({
    data: getData(),
    theme: {
      baseTheme: mode === 'light' ? 'ag-material' : 'ag-material-dark',
      palette: {
        fills: [colors.dark, colors.light, colors.green, colors.yellow, colors.main,],
        strokes: [colors.dark, colors.light, colors.green, colors.yellow, colors.main,],
      },
    },
    series: [
      {
        type: 'column',
        xKey: 'quarter',
        yKey: 'unfufilled',
        yName: 'Unfufilled',
        stacked: true,
      },
      {
        type: 'column',
        xKey: 'quarter',
        yKey: 'originalfirm',
        yName: 'Original Firm',
        stacked: true,
      },
      {
        type: 'column',
        xKey: 'quarter',
        yKey: 'originalnonfirm',
        yName: 'Original Non-Firm',
        stacked: true,
      },
      {
        type: 'column',
        xKey: 'quarter',
        yKey: 'resalefirm',
        yName: 'Resale Firm',
        stacked: true,
      },
      {
        type: 'column',
        xKey: 'quarter',
        yKey: 'resalenonfirm',
        yName: 'Resale Non-Firm',
        stacked: true,
      },
    ],
  }), [mode])

  return (
    <AgChartsReact options={options} />
  )
}