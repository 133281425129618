import { Box, Button, Typography, IconButton, Grid, } from '@mui/material';
import { styled } from '@mui/styles'
import { ColorThemeProvider } from '../../../styles/ColorMode/ColorThemeProvider';
import { Close } from '@mui/icons-material';

export const ContextMenu = (props) => {
    const { data, onClose, addAllChildren, addChild, handleRemoveLeg, handleRemoveNode, removeParallel, } = props;

    const defaultButtonProps = {
        variant: 'contained',
        size: 'small',
        fullWidth: true,
    };

    const isEdge = !!data.source;
    const menuTitle = isEdge ? `${data.source} \u2192 ${data.target}` : data.label;

    function handleRemove() {
        if (isEdge) {
            handleRemoveLeg(data);
        } else {
            onClose();
            handleRemoveNode(data);
        }
    }

    function handleRemoveParallel() {
        onClose();
        removeParallel([data.id]);
    }

    return (
        <ColorThemeProvider>
            <MenuContainer sx={{ p: 1, }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '3px', }}>
                    <Typography variant='title'>{menuTitle}</Typography>
                    <IconButton onClick={onClose}>
                        <Close />
                    </IconButton>
                </Box>
                {!isEdge && <Grid container spacing={1} sx={{ paddingBottom: '5px', }}>
                    <Grid item xs={5}>
                        <Button
                            onClick={() => addChild(data.id)}
                            {...defaultButtonProps}
                        >Add Child</Button>
                    </Grid>
                    <Grid item xs={7}>
                        <Button
                            onClick={() => addAllChildren(data.id)}
                            {...defaultButtonProps}
                        >Add All Children</Button>
                    </Grid>
                </Grid>}
                <Grid container spacing={1}>
                    <Grid item xs={5}>
                        <Button
                            onClick={handleRemove}
                            {...defaultButtonProps}
                        >Remove</Button>
                    </Grid>
                    {isEdge && <Grid item xs={7}>
                        <Button
                            onClick={handleRemoveParallel}
                            {...defaultButtonProps}
                        >Remove Parallel</Button>
                    </Grid>}
                </Grid>
                <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <Button onClick={onClose}>Close</Button>
                </Box>
            </MenuContainer>
        </ColorThemeProvider>
    );
}

const MenuContainer = styled(Box)(({ theme, }) => ({
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.primary.contrastText,
    width: '280px',
}));