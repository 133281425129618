import makeStyles from '@mui/styles/makeStyles';
import { createTheme } from '@mui/material/styles';
import { drawerWidth } from "../constants";

const defaultTheme = createTheme();
const useLookupStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  fetchButton: {
    color: "#CEDA2"
  },
  textLabels: {
    color: "#FFFFFFDE",
    color: "#272360",
    verticalAlign: 'middle',    
  },
  inputBox: {
    color: "#FFFFFFDE",
    border: "#272360",
    //backgroundColor: "#FFFFFFDE",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  drawerToggleContainerOpen: {
    //background: "gold",
    position: "absolute",
    height: "100vh",
    width: drawerWidth + 15,
    display: "flex",
    justifyContent: "flex-end",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    "&:hover": {
      cursor: "pointer",
    },
  },
  drawerToggleContainerClosed: {
    // background: "gold",
    position: "absolute",
    height: "100vh",
    width: theme.spacing(7) + 30,
    display: "flex",
    justifyContent: "flex-end",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    "&:hover": {
      cursor: "pointer",
    },
  },
  drawerToggle: {
    alignSelf: "center",
    //kj: trying out some different styles
    //background: "transparent linear-gradient(180deg, #10A9FF 0%, #1C66B0 35%, ##272360 100%) 0% 0% no-repeat padding-box",
    //opacity: "1",
    //background: "#CEDA28",
    border: "2px solid black",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 100,
    position: "fixed",
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    //background: "transparent linear-gradient(180deg, #10A9FF 0%, #1C66B0 35%, ##272360 100%) 0% 0% no-repeat padding-box",
    //opacity: "1",
    //background: "#272360",
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    zIndex: 1,
  },
  drawerOpen: {
    //background: "transparent linear-gradient(180deg, #10A9FF 0%, #1C66B0 35%, ##272360 100%) 0% 0% no-repeat padding-box",
    //color: "transparent linear-gradient(180deg, #10A9FF 0%, #1C66B0 35%, ##272360 100%) 0% 0% no-repeat padding-box",
    //background: "linear-gradient(180deg, #10A9FF 0%, ##272360 100%)",
    //background: "#272360", //provided by eileen
    background: "#ECEFF1",
    //opacity: "1",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    //background: "transparent linear-gradient(180deg, #10A9FF 0%, #1C66B0 35%, ##272360 100%) 0% 0% no-repeat padding-box",
    //background: "#272360", //provided by eileen
    background: "#ECEFF1", 
    //opacity: "1",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  fastpathToolbar: {
    display: "flex",
    alignItems: "center",
    background: "#ECEFF1",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  inputMarginDense: {
    paddingTop: "2px",
    paddingBottom: "2px",
  },
  checkboxRoot: {
    color: "#272360",
    padding: "0px",
  },
}),
  { defaultTheme },
);

export { useLookupStyles };
