import { TextField, Dialog, Stack, FormControlLabel, DialogContent, Checkbox, DialogTitle, Typography, Box, IconButton, DialogActions, Button, Autocomplete } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import ProfileEditor from "./ProfileEditor";
import { useMemo, useState, useCallback, useEffect } from "react";
import dayjs from "dayjs";
import { useFormContext } from "react-hook-form";
import { useApi } from "../../../useApi";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useProfileTemplates } from "./useProfileTemplates_v2";
import FormAutocomplete from "../../../FormControls/FormAutocomplete";
import FormCheckbox from "../../../FormControls/FormCheckbox";

const schema = yup.object().shape({ //TODO: min and max dates not working
    //startDateTime: date().required('Start date is required').min(defaults.startDateTime?.format(formatString), 'Start date is outside acceptable range.').max(defaults.stopDateTime.format(formatString), 'Start date is outside acceptble range.'),
    //stopDateTime: date().required('Stop date is required').min(defaults.startDateTime.format(formatString), 'Stop date is outside acceptable range.').max(defaults.stopDateTime.format(formatString), 'Stop date is outside acceptble range.'),
    Term: yup.string().required('Term is required'),
    timezone: yup.string().required('Timezone is required'),
    mwOn: yup.number().required('MW On is required').typeError('MW On must be a number.').min(0, 'MW On must be greater than or equal to 0'),
    priceOn: yup.number().required('Price On is required').typeError('Price On must be a number.').min(0, 'Price On must be greater than or equal to 0'),
    mwOff: yup.number().required('MW Off is required').typeError('MW Off must be a number.').min(0, 'MW Off must be greater than or equal to 0'),
    priceOff: yup.number().required('Price Off is required').typeError('Price Off must be a number.').min(0, 'Price Off must be greater than or equal to 0'),
    adder: yup.string().test(
        'is-falsey-or-numeric',
        'Adder must be a number.',
        (value) => !value || !isNaN(value),
    ),
})

export const ProfileEditorDialog = ({ open, handleClose, handleCancel, dropdownOptions, indexName, }) => {
    const formId = 'profile-editor-form';
    const { getValues, } = useFormContext();
    const [defaultProfile = [], defaultTimezone, defaultTerm, indexType, forecast, dynamic, adder,] = getValues(['profile', 'timezone', 'Term', 'indexType', 'forecast', 'dynamic', 'adder']);
    const [profile, setProfile] = useState(defaultProfile);
    const { get, apiUrlPrefix, headers, enqueueSnackbar } = useApi();
    const { generateTransmissionBlocksUsingTemplate, } = useProfileTemplates();

    const defaults = useMemo(() => {
        const firstStartDate = defaultProfile.reduce((acc, block) => {
            if (acc === null || dayjs(block.startDateTime).isBefore(dayjs(acc))) {
                return dayjs(block.startDateTime);
            }
            return acc;
        }, null);

        const lastStopDate = defaultProfile.reduce((acc, block) => {
            if (acc === null || dayjs(block.endDateTime).isAfter(dayjs(acc))) {
                return dayjs(block.endDateTime);
            }
            return acc;
        }, null);

        const smallestOffPeakPrice = defaultProfile.reduce((acc, block) => {
            if (acc === null || block.price < acc) {
                return block.price;
            }
            return acc;
        }, null);

        const largestOnPeakPrice = defaultProfile.reduce((acc, block) => {
            if (acc === null || block.price > acc) {
                return block.price;
            }
            return acc;
        }, null);

        const smallestOffPeakMW = defaultProfile.reduce((acc, block) => {
            if (acc === null || block.capacityRequested < acc) {
                return block.capacityRequested;
            }
            return acc;
        }, null);

        const largestOnPeakMW = defaultProfile.reduce((acc, block) => {
            if (acc === null || block.capacityRequested > acc) {
                return block.capacityRequested;
            }
            return acc;
        }, null);

        return {
            startDateTime: firstStartDate ?? dayjs().startOf('day'),
            stopDateTime: lastStopDate ?? dayjs().add(1, 'day').startOf('day'),
            mwOn: largestOnPeakMW ?? 0,
            mwOff: smallestOffPeakMW ?? 0,
            priceOn: largestOnPeakPrice ?? 0,
            priceOff: smallestOffPeakPrice ?? 0,
            timezone: defaultTimezone ?? 'America/Los_Angeles',
            Term: defaultTerm,
            indexType,
            forecast,
            dynamic: !!dynamic,
            adder: adder ?? '',
        }
    }, [defaultProfile]);

    const methods = useForm({
        resolver: yupResolver(schema),
        defaultValues: defaults,
    });

    const { handleSubmit, watch, control, setValue, } = methods;

    useEffect(() => {
        const subscription = watch(handleSubmit(handleUpdateProfile, onError));
        return () => subscription.unsubscribe();
    }, [handleSubmit, watch]);

    const handleUpdateProfile = (formData) => {
        const data = { ...formData };
        const unsortedData = generateTransmissionBlocksUsingTemplate(data);
        const newData = unsortedData.sort((a, b) => dayjs(a.startDateTime).diff(dayjs(b.startDateTime)));
        setProfile(newData);
    }

    function onSubmit(data) {
        handleClose({
            profile,
            ...data
        });
    }

    function onError(errors) {
        if (!!errors.profile) {
            enqueueSnackbar('No profile data - use the template buttons to add data to the grid.', { variant: 'error' });
        }
    }

    return (
        <FormProvider {...methods}>
            <Dialog
                open={open}
                fullWidth
                maxWidth='lg'
                keepMounted
                component='form'
                id={formId}
                onSubmit={handleSubmit(onSubmit, onError)}
            >
                <DialogTitle sx={{ pb: 0, pt: 1, px: 2 }}>
                    <Stack spacing={2} direction='row' sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant='h6' sx={{ flexGrow: 1, }}>Edit Profile</Typography>
                        <Box sx={{ flexGrow: 1, }} />
                        <FormControlLabel
                            control={<FormCheckbox
                                name='dynamic'
                                control={control}
                            />}
                            label="Dynamic"
                        />
                        <FormAutocomplete
                            control={control}
                            name={'indexType'}
                            autoComplete
                            autoHighlight
                            autoSelect
                            disableClearable
                            options={dropdownOptions['indexType'] ?? []}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Index Type"
                                    size='small'
                                    sx={{ minWidth: 150, }}
                                />
                            )}
                        />
                        <FormAutocomplete
                            control={control}
                            name={'forecast'}
                            autoComplete
                            autoHighlight
                            autoSelect
                            disableClearable
                            options={dropdownOptions['forecast'] ?? []}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Forecast"
                                    size='small'
                                    sx={{ minWidth: 150, }}
                                />
                            )}
                        />
                        <IconButton onClick={handleCancel} size='large'>
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                </DialogTitle>
                <DialogContent sx={{ height: '75vh', pb: 0, px: 0.5, }}>
                    <ProfileEditor
                        disabled={false}
                        profile={profile}
                        setProfile={setProfile}
                    />
                </DialogContent>
                <DialogActions sx={{ display: 'flex', justifyContent: 'space-between', pt: 0 }}>
                    <Button onClick={handleCancel} color='error'>Cancel</Button>
                    <Button type='submit' form={formId} color='primary' variant='contained'>Save & Close</Button>
                </DialogActions>
            </Dialog>
        </FormProvider>
    );
}