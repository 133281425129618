import { FormGroup, FormControlLabel, Box, Switch, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

export const PaletteToolPanel = ({ gridRef, paletteRef, storageKey }) => {
    const savedSettings = JSON.parse(localStorage.getItem(storageKey)) ?? {};
    const [showHeatmap, setShowHeatmap] = useState(savedSettings.showHeatmap ?? false);

    useEffect(() => {
        paletteRef.current = { showHeatmap };
    }, []);

    function handleToggleHeatmap() {
        setShowHeatmap(!showHeatmap);
        //eventually we should use the grid context instead of a separate ref
        paletteRef.current = { ...paletteRef.current, showHeatmap: !showHeatmap };
        gridRef.current.api.redrawRows();
        localStorage.setItem(storageKey, JSON.stringify({ showHeatmap: !showHeatmap }));
    }

    return (
        <Box sx={{ display: 'flex', p: 1, flexDirection: 'column' }}>
            <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', p: 1, }}>
                <Typography variant='h6'>Cell Palette</Typography>
            </Box>

            <Box sx={{ width: '100%', display: 'flex', p: 1, }}>
                <FormGroup>
                    <FormControlLabel
                        label='Show Heatmap'
                        control={
                            <Switch
                                checked={showHeatmap}
                                onMouseDown={handleToggleHeatmap}
                            />
                        }
                    />
                </FormGroup>
            </Box>
        </Box>
    );
};