import { useImperativeHandle, useRef, useMemo, useEffect, useState, useCallback, forwardRef } from "react"
import { Box } from "@mui/material"
import { Toolbar } from "./Toolbar"
import { AgGridContainer } from "../../AgGrid/AgGridContainer"
import { AgGridReact } from "ag-grid-react"
import { defaultColumnDef, defaultStatusBar } from "../../AgGrid/defaultGridProps"
import { useColumnTypes } from "../../AgGrid/useColumnTypes"
import { columnPanel, filterPanel, } from "../../ToolPanels/DefaultToolPanels"
import { LayoutToolPanel } from "../../ToolPanels/LayoutToolPanel"
import useGridLayout from "../../useGridLayout"
import dayjs from "dayjs"
import { Ribbon, GridActionsGroup, DealActionsGroup } from "../Ribbon"
import { CheckboxCellRenderer } from '../../AgGrid/CheckboxCellRenderer';
import { useGridCrossHighlight } from "../useGridCrossHighlight"
import { jsonOrCommaSeparatedFormatter } from "../Utils"
import ConfirmationDialog from "../../TSRActivity/ConfirmationDialog"
import debounce from 'lodash/debounce'
import { useApi } from "../../useApi"

export const DealSummaryView = forwardRef(({ view, }, ref) => {
    const layoutStorageKey = `deal-rizz-deal-summary-grid-layout-${view.id}`;
    const gridRef = useRef();
    const { columnTypes } = useColumnTypes();
    const [rowData, setRowData] = useState();
    const [confirmationDialogProps, setConfirmationDialogProps] = useState({});
    const [selectedRow, setSelectedRow] = useState();
    const { highlightingCellClassRules, clearHighlighting, handleCellMouseOverHighlight, } = useGridCrossHighlight(gridRef);
    const buttonDebounce = 1500;
    const { headers, apiUrlPrefix, get, logAction, enqueueSnackbar, } = useApi();

    useEffect(() => {
        fetchDealSummaryData(view);
    }, [view]);

    const fetchDealSummaryData = useCallback(async (data) => {
        logAction('User fetched Deal Summary Data', 'Deal Summary', data);
        const { timezone, startDate, endDate, } = data;
        gridRef.current?.api?.showLoadingOverlay();

        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=dealrizz.UI_fetchDealSummary_v2`
            + `&parm=${headers.userGuid}`
            + `&parm=${dayjs(startDate).format('MM/DD/YYYY')}`
            + `&parm=${dayjs(endDate).format('MM/DD/YYYY')}`
            + `&parm=${timezone}`

        return get(url).then(response => {
            setRowData(response?.data ?? []);
            setSelectedRow(null);
        }).finally(() => {
            gridRef.current?.api?.hideOverlay();
        });
    }, [headers]);

    function handleConfirmVoid() {
        setConfirmationDialogProps({
            open: true,
            message: `You are about to void the selected schedule (${selectedRow.dealID}). Continue?`,
            onConfirmation: handleVoid,
            onCancel: () => setConfirmationDialogProps({ open: false }),
        });
    }

    const handleConfirmDeal = useCallback(debounce(async () => {
        const confirmed = selectedRow.confirmed;
        logAction(`User ${confirmed ? 'un' : ''}confirmed deal with id ${selectedRow.dealID}`, 'Deal Summary', selectedRow);

        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=dealrizz.UI_confirmDeal`
            + `&parm=${headers.userGuid}`
            + `&parm=${selectedRow.dealID}`
            + `&parm=${confirmed ? 0 : 1}`

        return get(url).then(response => {
            enqueueSnackbar(`Deal ${confirmed ? 'un' : ''}confirmed successfully`, { variant: 'success' });
            setRowData(rowData.map(row => row.dealID === selectedRow.dealID ? { ...row, confirmed: !confirmed } : row));
            setSelectedRow();
        });
    }, buttonDebounce, { leading: true, }), [headers, selectedRow, rowData]);

    const handleVoid = useCallback(debounce(async () => {
        setConfirmationDialogProps({ open: false });
        const toVoid = selectedRow.Status === 'VOIDED' ? 0 : 1;
        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=dealrizz.UI_voidDeal`
            + `&parm=${headers.userGuid}`
            + `&parm=${selectedRow.dealID}`
            + `&parm=${toVoid}`

        return get(url).then(response => {
            enqueueSnackbar(`Deal ${toVoid ? '' : 'un'}voided successfully.`, { variant: 'success' });
            logAction(`User ${toVoid ? '' : 'un'}voided deal with id ${selectedRow.scheduleID}`, 'Deal Summary');
            setRowData(rowData.map(row => row.dealID === selectedRow.dealID ? { ...row, Status: toVoid ? 'VOIDED' : 'DRAFT' } : row));
        });
    }, buttonDebounce, { leading: true, }), [headers, apiUrlPrefix, selectedRow]);

    const baseColDefs = useMemo(() => ([
        {
            field: 'dealID',
            headerName: 'Deal ID',
            checkboxSelection: true,
        },
        {
            field: "confirmed",
            headerName: "Confirmed",
            cellRenderer: 'checkboxCellRenderer',
        },
        {
            field: 'Accounting',
        },
        {
            field: 'Book',
            valueFormatter: (params) => {
                return jsonOrCommaSeparatedFormatter(params.value);
            },
        },
        {
            field: 'Broker',
        },
        {
            field: 'Broker_Cost',
            headerName: 'Broker Cost',
        },
        {
            field: 'Broker_Price',
            headerName: 'Broker Price',
        },
        {
            field: 'Broker_Type',
            headerName: 'Broker Type',
        },
        {
            field: 'Buying_Trader',
            headerName: 'Buying Trader',
        },
        {
            field: 'Component',
        },
        {
            field: 'Component_2',
            headerName: 'Component 2',
        },
        {
            field: 'Component_Off',
            headerName: 'Component Off',
        },
        {
            field: 'Confirmation',
        },
        {
            field: 'Contract',
        },
        {
            field: 'Counterparty',
        },
        {
            field: 'Creation_Time_MPT',
            headerName: 'Creation Time MPT',
        },
        {
            field: 'Deal_Currency',
            headerName: 'Deal Currency',
        },
        {
            field: 'Deal_Locked',
            headerName: 'Deal Locked',
        },
        {
            field: 'Deal_Number',
            headerName: 'Old Deal ID',
        },
        {
            field: 'Deal_Trait_1',
            headerName: 'Deal Trait 1',
        },
        {
            field: 'Deal_Trait_2',
            headerName: 'Deal Trait 2',
        },
        {
            field: 'Deal_Type',
            headerName: 'Deal Type',
        },
        {
            field: 'Designated_Parent_Number',
            headerName: 'Designated Parent #',
        },
        {
            field: 'Due_Date',
            headerName: 'Due Date',
        },
        {
            field: 'End_Date',
            headerName: 'End Date',
        },
        {
            field: 'Executing_Trader',
            headerName: 'Executing Trader',
        },
        {
            field: 'Exercise_Date',
            headerName: 'Exercise Date',
        },
        {
            field: 'Exercised_From',
            headerName: 'Exercised From',
        },
        {
            field: 'Expiration_Time_MPT',
            headerName: 'Expiration Time MPT',
        },
        {
            field: 'Expired_By',
            headerName: 'Expired By',
        },
        {
            field: 'Export_Time_MPT',
            headerName: 'Export Time MPT',
        },
        {
            field: 'Export_User',
            headerName: 'Export User',
        },
        {
            field: 'External_Key',
            headerName: 'External Key',
        },
        {
            field: 'FERC_Form_552',
            headerName: 'FERC Form 552',
        },
        {
            field: 'Financial_Type',
            headerName: 'Firm/Non-Firm',
        },
        {
            field: 'Formula',
        },
        {
            field: 'Formula_2',
            headerName: 'Formula 2',
        },
        {
            field: 'Formula_Off',
            headerName: 'Formula Off',
        },
        {
            field: 'Include_In_Confirmation',
            headerName: 'Include In Confirmation',
        },
        {
            field: 'index_name',
            headerName: 'Index',
        },
        {
            field: 'Index_2',
            headerName: 'Index 2',
        },
        {
            field: 'Index_Off',
            headerName: 'Index Off',
        },
        {
            field: 'Internal',
            headerName: 'Comments',
        },
        {
            field: 'Market',
        },
        {
            field: 'Market_Type',
            headerName: 'Market Type',
        },
        {
            field: 'Market_Type_2',
            headerName: 'Market Type 2',
        },
        {
            field: 'Market_Type_Off',
            headerName: 'Market Type Off',
        },
        {
            field: 'Max_Price',
            headerName: 'Max Price',
        },
        {
            field: 'Min_Price',
            headerName: 'Min Price',
        },
        {
            field: 'Misc',
        },
        {
            field: 'Modification_Time_MPT',
            headerName: 'Modification Time MPT',
        },
        {
            field: 'Modification_User',
            headerName: 'Modification User',
        },
        {
            field: 'Multi_Location_Component_2',
            headerName: 'Multi Location Component 2',
        },
        {
            field: 'Multi_Location_Index_2',
            headerName: 'Multi Location Index 2',
        },
        {
            field: 'Multi_Location_Market_2',
            headerName: 'Multi Location Market 2',
        },
        {
            field: 'Multi_Location_Market_Type_2',
            headerName: 'Multi Location Market Type 2',
        },
        {
            field: 'Multi_Location_Pricing_Type_2',
            headerName: 'Multi Location Pricing Type 2',
        },
        {
            field: 'Multi_Location_Zone_2',
            headerName: 'Multi Location Zone 2',
        },
        {
            field: 'MW',
        },
        {
            field: 'MWOff',
            headerName: 'MW Off',
        },
        {
            field: 'Option',
        },
        {
            field: 'Option_Type',
        },
        {
            field: 'Price',
        },
        {
            field: 'PriceOff',
            headerName: 'Price Off',
        },
        {
            field: 'Product',
        },
        {
            field: 'Purch_Sales_FERC_552',
            headerName: 'Purch Sales FERC 552',
        },
        {
            field: 'Ramp',
        },
        {
            field: 'Resource_Type',
            headerName: 'Resource Type',
        },
        {
            field: 'Scheduling_Status',
            headerName: 'Scheduling Status',
        },
        {
            field: 'Seller',
        },
        {
            field: 'Selling_Trader',
            headerName: 'Selling Trader',
        },
        {
            field: 'Sleeve_Cost',
            headerName: 'Sleeve Cost',
        },
        {
            field: 'Sleeve_Price',
            headerName: 'Sleeve Price',
        },
        {
            field: 'Sleeve_Price_Type',
            headerName: 'Sleeve Price Type',
        },
        {
            field: 'Start_Date',
            type: 'dateColumn',
        },
        {
            field: 'Status',
        },
        {
            field: 'Strategy',
            valueFormatter: (params) => {
                return jsonOrCommaSeparatedFormatter(params.value);
            },
        },
        {
            field: 'Strategy_2',
            headerName: 'Strategy 2',
        },
        {
            field: 'Strike_Price',
            headerName: 'Strike Price',
        },
        {
            field: 'Tax',
        },
        {
            field: 'Term',
        },
        {
            field: 'indexType',
            headerName: 'Index Type',
        },
        {
            field: 'dynamic',
            headerName: 'Dynamic',
        },
        {
            field: 'forecast',
            headerName: 'Forecast',
        },
        {
            field: 'Time_Zone',
            headerName: 'Time Zone',
        },
        {
            field: 'Total_MWh',
            headerName: 'Total MWh',
        },
        {
            field: 'Total_Settlement',
            headerName: 'Total Settlement',
        },
        {
            field: 'Trade_Date',
            headerName: 'Trade Date',
        },
        {
            field: 'Trader',
        },
        {
            field: 'Trading_Violation',
            headerName: 'Trading Violation',
        },
        {
            field: 'Transaction_Type',
            headerName: 'Transaction Type',
        },
        {
            field: 'Type_F_P',
            headerName: 'Type F P',
        },
        {
            field: 'Type_of_Transaction_for_FERC_552',
            headerName: 'Type of Transaction for FERC 552',
        },
        {
            field: 'Unit1_Name',
            headerName: 'Unit 1 Name',
        },
        {
            field: 'Unti1_Pct',
            headerName: 'Unit 1 Pct',
        },
        {
            field: 'Unit1_Plant',
            headerName: 'Unit 1 Plant',
        },
        {
            field: 'Unit2_SSE',
            headerName: 'Unit 2 SSE',
        },
        {
            field: 'Unit1_Zone',
            headerName: 'Unit 1 Zone',
        },
        {
            field: 'Unit2_Name',
            headerName: 'Unit 2 Name',
        },
        {
            field: 'Unit2_Pct',
            headerName: 'Unit 2 Pct',
        },
        {
            field: 'Unit2_Plant',
            headerName: 'Unit 2 Plant',
        },
        {
            field: 'Unit2_SSE',
            headerName: 'Unit 2 SSE',
        },
        {
            field: 'Unit2_Zone',
            headerName: 'Unit 2 Zone',
        },
        {
            field: 'Voiding_Time_MPT',
            headerName: 'Voiding Time MPT',
        },
        {
            field: 'Voiding_User',
            headerName: 'Voiding User',
        },
        {
            field: 'Zone',
        },
    ]), []);

    const defaultColDef = useMemo(() => ({
        ...defaultColumnDef,
        editable: false,
        enableRowGroup: true,
        minWidth: 100,
        flex: 1,
    }), []);

    const { colDefs, layoutPanel, applyLayout, loadLayout, captureLayout, applyFilters, applySavedFilters } = useGridLayout(layoutStorageKey, gridRef, baseColDefs, defaultColDef);

    const styledColDefs = useMemo(() => colDefs.map(colDef => ({
        ...colDef,
        cellClassRules: highlightingCellClassRules,
    })), [colDefs, highlightingCellClassRules]);

    useImperativeHandle(ref, () => {
        return {
            captureLayout,
        };
    });

    const sideBar = useMemo(() => ({
        toolPanels: [
            columnPanel,
            filterPanel,
            layoutPanel,
        ]
    }), []);

    function onGridReady(params) {
        if (view?.layout && !localStorage.getItem(layoutStorageKey)) {
            applyLayout(view.layout);
        } else {
            loadLayout();
        }
    }

    const handleFirstDataRendered = useCallback(() => {
        if (view?.layout?.filters && !localStorage.getItem(layoutStorageKey)) {
            applyFilters(view.layout.filters);
        } else if (localStorage.getItem(layoutStorageKey)) {
            applySavedFilters();
        }
    }, []);

    const toolbarFormId = `deal-summary-toolbar-form-${view.id}`;

    const handleSelectionChanged = useCallback(() => {
        const selected = gridRef.current?.api?.getSelectedRows();
        setSelectedRow(selected[0]);
    }, []);

    return (
        <Box sx={{ height: '86vh', display: 'flex', flexDirection: 'column' }}>
            <ConfirmationDialog {...confirmationDialogProps} />
            <Ribbon>
                {[
                    {
                        title: 'Deals',
                        content: <DealActionsGroup
                            formId={toolbarFormId}
                            handleConfirmDeal={handleConfirmDeal}
                            selectedRow={selectedRow}
                            handleConfirmVoid={handleConfirmVoid}
                        />
                    },
                    { title: 'Grid', content: <GridActionsGroup formId={toolbarFormId} /> },
                    { title: 'Filters', content: <Toolbar view={view} onSubmit={fetchDealSummaryData} formId={toolbarFormId} /> },
                ]}
            </Ribbon>
            <AgGridContainer style={{ display: 'flex', flex: 1, width: '100%' }} onMouseLeave={clearHighlighting}>
                <AgGridReact
                    containerStyle={{ display: 'flex', flexDirection: 'column', flex: 1, width: '100%', }}
                    rowData={rowData}
                    ref={gridRef}
                    enableCharts
                    columnDefs={styledColDefs}
                    enableRangeSelection
                    onFirstDataRendered={handleFirstDataRendered}
                    onCellMouseOver={handleCellMouseOverHighlight}
                    rowMultiSelectWithClick
                    rowSelection="single"
                    onSelectionChanged={handleSelectionChanged}
                    enableFillHandle
                    stopEditingWhenCellsLoseFocus
                    singleClickEdit
                    onGridReady={onGridReady}
                    columnTypes={columnTypes}
                    statusBar={defaultStatusBar}
                    sideBar={sideBar}
                    components={{
                        layoutToolPanel: LayoutToolPanel,
                        checkboxCellRenderer: CheckboxCellRenderer,
                    }}
                />
            </AgGridContainer>
        </Box>
    )
});