import { useMemo, useState, useCallback, useEffect, useRef, } from "react";
import { Box, Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material";
import { UnscheduledMWsGrid } from "../UnscheduledMWs/UnscheduledMWsGrid";
import { AgGridContainer } from "../../AgGrid/AgGridContainer";
import { Toolbar } from "./Toolbar";
import { TagReportGrid } from "./TagReportGrid";
import { useTheme } from "@emotion/react";
import dayjs from "dayjs";
import ExpandLess from "@mui/icons-material/ExpandLess";
import { styled } from "@mui/styles";
import { useApi } from "../../useApi";

export const Scheduling = () => {
    const theme = useTheme();
    const controlPanelFormId = 'deal-rizz-scheduling-control-panel-form';
    const [selectedPurchases, setSelectedPurchases] = useState([]);
    const [selectedSales, setSelectedSales] = useState([]);
    const [selectedTag, setSelectedTag] = useState([]);
    const [sourceSinkOptions, setSourceSinkOptions] = useState({});
    const { get, headers, apiUrlPrefix, enqueueSnackbar, logAction } = useApi();
    const [data, setData] = useState([]);
    const [date, setDate] = useState(dayjs().format('MM/DD/YYYY'));
    const tagReportGridRef = useRef();
    const purchaseGridRef = useRef();
    const saleGridRef = useRef();
    const [context, setContext] = useState({
        timezone: 'Pacific Standard Time',
        startDate: dayjs(),
    });

    useEffect(() => {
        fetchData(context);
        tagReportGridRef.current?.refresh(context);
    }, [context]);

    const fetchData = useCallback(async (data) => {
        logAction('User fetched Unscheduled MWs Data on Scheduling screen', 'Unscheduled MWs', data);
        const { timezone, startDate, } = data;
        purchaseGridRef.current?.showLoadingOverlay();
        saleGridRef.current?.showLoadingOverlay();
        setDate(startDate);

        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=dealrizz.UI_fetchUnscheduledMWForScheduling`
            + `&parm=${headers.userGuid}`
            + `&parm=${dayjs(startDate).format('YYYY-MM-DD')}`
            + `&parm=${timezone}`

        return get(url, { headers: headers }).then(response => {
            let newData = response?.data ?? [];
            setData(newData);
        }).catch((error) => {
            enqueueSnackbar(error.message, { variant: 'error' });
        }).finally(() => {
            purchaseGridRef.current?.hideOverlay();
            saleGridRef.current?.hideOverlay();
        });
    }, [headers]);

    const selectedRows = useMemo(() => {
        return {
            purchases: selectedPurchases,
            sales: selectedSales,
            tag: selectedTag[0],
        }
    }, [selectedPurchases, selectedSales, selectedTag]);

    const purchaseData = useMemo(() => {
        return data.filter(row => row.Transaction_Type === 'Purchase');
    }, [data]);

    const saleData = useMemo(() => {
        return data.filter(row => row.Transaction_Type === 'Sale');
    }, [data]);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', p: 0.5, height: '92vh' }}>
            <Toolbar
                context={context}
                setContext={setContext}
                formId={controlPanelFormId}
                selectedRows={selectedRows}
                sourceSinkOptions={sourceSinkOptions}
            />
            <Box sx={{ width: '100%', display: 'flex', flexGrow: 1, }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '50%', }}>
                    <Typography variant='title' fontSize='0.75rem' fontWeight='bold'>Purchases</Typography>
                    <AgGridContainer style={{ width: '100%', paddingRight: theme.spacing(0.5), flexGrow: 1, }}>
                        <UnscheduledMWsGrid
                            context={{ ...context, id: 'purchases' }}
                            showStatusBar={false}
                            setSelectedRows={setSelectedPurchases}
                            data={purchaseData}
                            date={date}
                            ref={purchaseGridRef}
                        />
                    </AgGridContainer>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '50%', }}>
                    <Typography variant='title' fontSize='0.75rem' fontWeight='bold'>Sales</Typography>
                    <AgGridContainer style={{ width: '100%', paddingLeft: theme.spacing(0.5), flexGrow: 1, }}>
                        <UnscheduledMWsGrid
                            context={{ ...context, id: 'sales' }}
                            showStatusBar={false}
                            setSelectedRows={setSelectedSales}
                            data={saleData}
                            date={date}
                            ref={saleGridRef}
                        />
                    </AgGridContainer>
                </Box>
            </Box>
            <Accordion
                defaultExpanded
                disableGutters
            >
                <StyledAccordionSummary
                    expandIcon={<ExpandLess />}
                    sx={{ minHeight: '24px', py: theme.spacing(0.5) }}
                >
                    <Typography>Tags</Typography>
                </StyledAccordionSummary>
                <AccordionDetails sx={{ p: 0, }}>
                    <AgGridContainer style={{ height: '40vh' }}>
                        <TagReportGrid
                            ref={tagReportGridRef}
                            context={context}
                            setSelectedRows={setSelectedTag}
                            showStatusBar={false}
                            setSourceSinkOptions={setSourceSinkOptions}
                        />
                    </AgGridContainer>
                </AccordionDetails>
            </Accordion>
        </Box>
    );
}

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    [`& .MuiAccordionSummary-content`]: {
        margin: 0,
    },
}));