import { makeStyles } from "@mui/styles";

const useLoginButtonStyles = makeStyles({
    signInButtonRoot: {
        border: "none",
        borderRadius: "0",
        background: "#1c174d",
        fontSize: "16px",
        color: "white",
        fontWeight: 700,
        "&:hover": {
            background: "#1851a1",
        },
    },
},{ index: 1 });


const useLoginStyles = makeStyles({
    formContainer: {
        marginTop: "150px",
    },
    loginForm: {
        display: "flex",
        flexDirection: "column",
        border: "1px solid #dddddd",
        borderRadius: "3px",
        padding: "50px 30px 35px 30px",
        width: "420px",
        height: "279px",
    },
    forgotPasswordLink: {
        textDecoration: "none",
        fontSize: "12px",
        color: "#000",
        fontWeight: 600,
        alignSelf: "flex-end",
        marginBottom: "15px",
    },
    outlinedRoot: {
        background: "#efefef",
        color: "#000",
        border: "none",

        "& $notchedOutline": {
            border: "none",
            borderRadius: "none",
        },
        /*
        "&:hover $notchedOutline": {
            border: `2px solid blue`,
        },
        */
        "&$focused $notchedOutline": {
            border: `2px solid grey`,
        },

    },
    inputLabelRoot: {
        fontWeight: "400",
        fontStyle: "italic",
        "&$focused ": {
            color: "blue",
            fontWeight: "bold",
        },
    },
    focused: {}, // keep these here. (allows material UI styles to apply to the textFields)
    notchedOutline: {}, //keep these here
},{ index: 1 });

export { useLoginStyles, useLoginButtonStyles };
