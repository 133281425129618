import * as React from 'react';
//import { styled, createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import styled from '@mui/system/styled';
//import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { mainListItems, secondaryListItems } from './bidmanMenuItemsMockup';
import { BidmanDataGridMockup } from '../components';

const drawerWidth = 240;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

//const mdTheme = createTheme();

function BidmanMockup() {
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <div sx={{ height: '100vh', width: '100%' }}>
    {/* <StyledEngineProvider injectFirst>
      <ThemeProvider theme={mdTheme}>  */}
        <Box sx={{ display: 'flex' }}>
          {/* <CssBaseline /> */}
           <Drawer variant="permanent" open={open}>
            <Toolbar
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                px: [1],
              }}
            >
              <IconButton onClick={toggleDrawer} size="large">
                <ChevronLeftIcon />
              </IconButton>
            </Toolbar>
            <Divider />
            <List>{mainListItems}</List>
            <Divider />
            <List>{secondaryListItems}</List>
          </Drawer>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              height: '100vh',
            }}
          >
            
            {/* <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
              <Grid container spacing={3}>
                <Grid item xs={12}> */}
                  <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    <BidmanDataGridMockup />
                  </Paper>
                {/* </Grid>
              </Grid>
            </Container>  */}
          </Box>
        </Box>
      {/* </ThemeProvider>
    </StyledEngineProvider> */}
  </div>
  );
}

export default function BidmanContentMockup() {
  return <BidmanMockup />;
}