import { useImperativeHandle, useRef, useMemo, useEffect, useState, useCallback, forwardRef } from "react"
import { Box } from "@mui/material"
import { Toolbar } from "./Toolbar"
import { AgGridContainer } from "../../AgGrid/AgGridContainer"
import { AgGridReact } from "ag-grid-react"
import { defaultColumnDef, defaultStatusBar } from "../../AgGrid/defaultGridProps"
import { useColumnTypes } from "../../AgGrid/useColumnTypes"
import { columnPanel, filterPanel, } from "../../ToolPanels/DefaultToolPanels"
import { LayoutToolPanel } from "../../ToolPanels/LayoutToolPanel"
import { apiUrlPrefix } from "../../../authConfig"
import { useSnackbar } from "notistack"
import useGridLayout from "../../useGridLayout"
import axios from "axios"
import useHeader from "../../useHeader"
import dayjs from "dayjs"
import { useActionAudit } from "../../useActionAudit"
import { GridActionsGroup, Ribbon } from "../Ribbon"
import { useGridCrossHighlight } from "../useGridCrossHighlight"
import { jsonOrCommaSeparatedFormatter } from "../Utils"
import { ScheduleActionsGroup } from "./ScheduleActionsGroup"

export const ScheduleSummaryView = forwardRef(({ view, }, ref) => {
    const layoutStorageKey = `deal-rizz-schedule-summary-grid-layout-${view.id}`;
    const gridRef = useRef();
    const { columnTypes } = useColumnTypes();
    const [rowData, setRowData] = useState();
    const headers = useHeader();
    const { enqueueSnackbar } = useSnackbar();
    const { logAction } = useActionAudit();
    const { highlightingCellClassRules, clearHighlighting, handleCellMouseOverHighlight, } = useGridCrossHighlight(gridRef);
    const [selectedRow, setSelectedRow] = useState();

    useEffect(() => {
        fetchScheduleSummaryData(view);
    }, [view]);

    const fetchScheduleSummaryData = useCallback(async (data) => {
        logAction('User fetched Schedule Summary Data', 'Schedule Summary', data);
        const { timezone, startDate, endDate } = data;
        gridRef.current?.api?.showLoadingOverlay();

        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=dealrizz.UI_fetchScheduleSummary_v3`
            + `&parm=${headers.userGuid}`
            //+ `&parm=${period === 'any' ? '' : period}` //send empty string to fetch results for any period
            + `&parm=${dayjs(startDate).format('MM/DD/YYYY')}`
            + `&parm=${dayjs(endDate).format('MM/DD/YYYY')}`
            + `&parm=${timezone}`
        // + `&parm=${excludeOasis ? 1 : 0}`;

        return axios.get(url, { headers: headers }).then(response => {
            setRowData(response?.data ?? []);
        }).catch((error) => {
            enqueueSnackbar(error.message, { variant: 'error' });
            setRowData([]);
        }).finally(() => {
            gridRef.current?.api?.hideOverlay();
        });
    }, [headers]);

    const baseColDefs = useMemo(() => ([
        { field: "scheduleID", headerName: "Schedule ID" },
        { field: "Schedule_Number", headerName: "Schedule Number" },
        { field: "Schedule_Status", headerName: "Schedule Status" },
        { field: "Purchase_Deals", headerName: "Purchase Deals" },
        { field: "Deal_ID_P", headerName: "Deal ID P" },
        {
            field: "Deal_Strategy1_P",
            headerName: "Deal Strategy1 P",
            valueFormatter: (params) => {
                return jsonOrCommaSeparatedFormatter(params.value);
            },
        },
        {
            field: "Deal_Strategy2_P",
            headerName: "Deal Strategy2 P",
            valueFormatter: (params) => {
                return jsonOrCommaSeparatedFormatter(params.value);
            },
        },
        { field: "Deal_Trait1_P", headerName: "Deal Trait1 P" },
        { field: "Deal_Trait2_P", headerName: "Deal Trait2 P" },
        { field: "Deal_Trait3_P", headerName: "Deal Trait3 P" },
        { field: "Deal_Trait4_P", headerName: "Deal Trait4 P" },
        { field: "Deal_Trait5_P", headerName: "Deal Trait5 P" },
        { field: "Sale_Deals", headerName: "Sale Deals" },
        { field: "Deal_ID_S", headerName: "Deal ID S" },
        { field: "Deal_Strategy1_S", headerName: "Deal Strategy1 S" },
        { field: "Deal_Strategy2_S", headerName: "Deal Strategy2 S" },
        { field: "Deal_Trait1_S", headerName: "Deal Trait1 S" },
        { field: "Deal_Trait2_S", headerName: "Deal Trait2 S" },
        { field: "Deal_Trait3_S", headerName: "Deal Trait3 S" },
        { field: "Deal_Trait4_S", headerName: "Deal Trait4 S" },
        { field: "Deal_Trait5_S", headerName: "Deal Trait5 S" },
        { field: "CA_Schedule", headerName: "CA Schedule" },
        { field: "CA_Status", headerName: "CA Status" },
        { field: "CPSE", headerName: "CPSE" },
        { field: "Tag_Code", headerName: "Tag Code" },
        { field: "Req_Type", headerName: "Req Type" },
        { field: "Req_Status", headerName: "Req Status" },
        { field: "GPE", headerName: "GPE" },
        { field: "GCA", headerName: "GCA" },
        { field: "LCA", headerName: "LCA" },
        { field: "LSE", headerName: "LSE" },
        { field: "TPs", headerName: "TPs" },
        { field: "Sched_Entities", headerName: "Sched Entities" },
        { field: "TX_Cust", headerName: "TX Cust" },
        { field: "Tag_Last_Action", headerName: "Tag Last Action" },
        { field: "Tag_Status", headerName: "Tag Status" },
        { field: "Tag_Error", headerName: "Tag Error" },
        { field: "Column1", headerName: "Column1" },
        { field: "Column2", headerName: "Column2" },
        { field: "Schedule_Comment", headerName: "Schedule Comment" },
        { field: "Sequential_Schedule", headerName: "Sequential Schedule" },
        { field: "Buyer", headerName: "Buyer" },
        { field: "Seller", headerName: "Seller" },
        { field: "Creator", headerName: "Creator" },
        { field: "Exporter", headerName: "Exporter" },
        { field: "Modifier", headerName: "Modifier" },
        { field: "Product", headerName: "Product" },
        { field: "Misc", headerName: "Misc" },
        { field: "Event_Origin", headerName: "Event Origin" },
        { field: "Start_Date", headerName: "Start Date" },
        { field: "End_Date", headerName: "End Date" },
        { field: "Column3", headerName: "Column3" },
        { field: "Column4", headerName: "Column4" },
        { field: "Column5", headerName: "Column5" },
        { field: "Source", headerName: "Source" },
        { field: "Sink", headerName: "Sink" },
        { field: "POR", headerName: "POR" },
        { field: "POD", headerName: "POD" },
        { field: "Gen_MW", headerName: "Gen MW" },
        { field: "Purchase_Book", headerName: "Purchase Book" },
        { field: "Sale_Book", headerName: "Sale Book" },
        { field: "PSE_Comment", headerName: "PSE Comment" },
        { field: "Purchase_Term", headerName: "Purchase Term" },
        { field: "Sale_Term", headerName: "Sale Term" },
        { field: "Schedule_Book", headerName: "Schedule Book" },
        { field: "Schedule_Term", headerName: "Schedule Term" },
        { field: "Slice_Transaction", headerName: "Slice Transaction" },
        { field: "Interface", headerName: "Interface" },
        { field: "CEC_ID", headerName: "CEC ID" },
        { field: "Schedule_Trait_4", headerName: "Schedule Trait 4" },
        { field: "Schedule_Trait_5", headerName: "Schedule Trait 5" }
    ]), []);

    const defaultColDef = useMemo(() => ({
        ...defaultColumnDef,
        editable: false,
        minWidth: 100,
        flex: 1,
    }), []);

    const { colDefs, layoutPanel, applyLayout, loadLayout, captureLayout, applyFilters, applySavedFilters, } = useGridLayout(layoutStorageKey, gridRef, baseColDefs, defaultColDef);

    const styledColDefs = useMemo(() => {
        const firstVisibleColumn = colDefs.find(colDef => !colDef.hide && !colDef.initialHide);
        //set checkbox selection to first visible column
        if (firstVisibleColumn) {
            firstVisibleColumn.checkboxSelection = true;
        }

        return colDefs.map(colDef => ({
            ...colDef,
            cellClassRules: highlightingCellClassRules,
        }));
    }, [colDefs, highlightingCellClassRules]);

    useImperativeHandle(ref, () => {
        return {
            captureLayout,
        };
    });

    const sideBar = useMemo(() => ({
        toolPanels: [
            columnPanel,
            filterPanel,
            layoutPanel,
        ]
    }), []);

    function onGridReady(params) {
        if (view?.layout && !localStorage.getItem(layoutStorageKey)) {
            applyLayout(view.layout);
        } else {
            loadLayout();
        }
    }

    const handleFirstDataRendered = useCallback(() => {
        if (view?.layout?.filters && !localStorage.getItem(layoutStorageKey)) {
            applyFilters(view.layout.filters);
        } else if (localStorage.getItem(layoutStorageKey)) {
            applySavedFilters();
        }
    }, []);

    const autoGroupColumnDef = useMemo(() => {
        return {
            // group columns configured to use the Group Column Filter
            filter: 'agGroupColumnFilter',
        };
    }, []);

    const toolbarFormId = 'schedule-summary-toolbar';

    const handleSelectionChanged = useCallback(() => {
        const selected = gridRef.current?.api?.getSelectedRows();
        setSelectedRow(selected[0]);
    }, []);

    function handleRowVoidUpdate(scheduleID, toVoid) {
        const updatedRowData = rowData.map(row => {
            if (row.scheduleID === scheduleID) {
                row.Schedule_Status = toVoid ? 'VOIDED' : 'DRAFT';
            }
            return row;
        });
        setRowData(updatedRowData);
    }

    function handleRowConfirmUpdate(scheduleID, toConfirm) {
        const updatedRowData = rowData.map(row => {
            if (row.scheduleID === scheduleID) {
                row.Schedule_Status = toConfirm ? 'CONFIRMED' : 'DRAFT';
            }
            return row;
        });
        setRowData(updatedRowData);
    }

    return (
        <Box sx={{ height: '86vh', display: 'flex', flexDirection: 'column' }}>
            <Ribbon>
                {[
                    {
                        title: 'Schedules',
                        content: <ScheduleActionsGroup
                            selectedSchedule={selectedRow}
                            handleRowVoidUpdate={handleRowVoidUpdate}
                            handleRowConfirmUpdate={handleRowConfirmUpdate}
                        />
                    },
                    { title: 'Grid', content: <GridActionsGroup formId={toolbarFormId} /> },
                    { title: 'Filters', content: <Toolbar view={view} onSubmit={fetchScheduleSummaryData} formId={toolbarFormId} /> },
                ]}
            </Ribbon>
            <AgGridContainer style={{ display: 'flex', flex: 1, width: '100%' }} onMouseLeave={clearHighlighting}>
                <AgGridReact
                    containerStyle={{ display: 'flex', flexDirection: 'column', flex: 1, width: '100%', }}
                    rowData={rowData}
                    ref={gridRef}
                    columnDefs={styledColDefs}
                    enableCharts
                    autoGroupColumnDef={autoGroupColumnDef}
                    onFirstDataRendered={handleFirstDataRendered}
                    rowSelection="single"
                    rowMultiSelectWithClick
                    onSelectionChanged={handleSelectionChanged}
                    enableRangeSelection
                    enableFillHandle
                    grandTotalRow={"bottom"}
                    stopEditingWhenCellsLoseFocus
                    onCellMouseOver={handleCellMouseOverHighlight}
                    singleClickEdit
                    onGridReady={onGridReady}
                    columnTypes={columnTypes}
                    statusBar={defaultStatusBar}
                    sideBar={sideBar}
                    components={{
                        layoutToolPanel: LayoutToolPanel,
                    }}
                />
            </AgGridContainer>
        </Box>
    )
});