import { IconButton, Stack, TextField, } from "@mui/material"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, } from "@mui/x-date-pickers";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import { useMemo, useEffect, useCallback, } from "react";
import dayjs from "dayjs";
import FormAutocomplete from "../../FormControls/FormAutocomplete";
import FormDatePicker from "../../FormControls/FormDatePicker";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { debounce } from "lodash";

const schema = yup.object().shape({
    timezone: yup.string().required('Timezone is required'),
    startDate: yup.date().required('Start Date is required'),
    endDate: yup.date().required('End Date is required'),
});

export const Toolbar = ({ view, onSubmit, formId, }) => {
    const defaults = {
        timezone: 'Pacific Standard Time',
        ...view,
        startDate: dayjs(),
        endDate: dayjs().add(1, 'day'),
    }

    const defaultValues = useMemo(() => defaults, [view]);

    const { handleSubmit, formState: { errors }, control, watch, getValues, setValue, reset, } = useForm({
        resolver: yupResolver(schema),
        defaultValues,
        mode: 'onBlur',
    });

    useEffect(() => {
        const subscription = watch(handleSubmit(onSubmit));
        return () => subscription.unsubscribe();
    }, [handleSubmit, watch]);

    useEffect(() => {
        reset(defaults);
    }, [view]);

    const handleDateUpdate = useCallback(debounce((key, value) => {
        //make sure start date is before stop date
        const start = getValues('startDate');
        const stop = getValues('endDate');
        if ((key === 'startDate' && dayjs(stop).isBefore(value)) || (key === 'endDate' && dayjs(start).isAfter(value))) {
            setValue('startDate', value);
            setValue('endDate', value);
        } else {
            setValue(key, value);
        }
    }, 400), [setValue, getValues]);

    function handleIncrementDates(days) {
        const start = getValues('startDate');
        const stop = getValues('endDate');
        setValue('startDate', start.add(days, 'day'));
        setValue('endDate', stop.add(days, 'day'));
    }

    return (
        <form id={formId} onSubmit={handleSubmit(onSubmit)}>
            <Stack direction='row' spacing={2} alignItems='center' sx={{ py: 2, display: 'flex', flexWrap: 'wrap', rowGap: '16px' }}>
                <div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <IconButton size='small' onClick={() => {
                            handleIncrementDates(-1);
                        }}>
                            <ArrowLeftIcon />
                        </IconButton>
                        <FormDatePicker
                            name='startDate'
                            control={control}
                            label="Flow Date Start"
                            onChange={(newValue) => handleDateUpdate('startDate', newValue?.startOf('date'))}
                            slotProps={{
                                textField: {
                                    size: 'small',
                                    error: !!errors.startDate,
                                    helperText: errors.startDate?.message,
                                    sx: { width: '170px', }
                                }
                            }}
                        />&nbsp;
                        <FormDatePicker
                            name='endDate'
                            control={control}
                            label="Flow Date End"
                            onChange={(newValue) => handleDateUpdate('endDate', newValue?.startOf('date'))}
                            slotProps={{
                                textField: {
                                    size: 'small',
                                    error: !!errors.endDate,
                                    helperText: errors.endDate?.message,
                                    sx: { width: '170px', }
                                }
                            }}
                        />
                        <IconButton size='small' onClick={() => {
                            handleIncrementDates(1);
                        }}>
                            <ArrowRightIcon />
                        </IconButton>
                    </LocalizationProvider>
                </div>

                <FormAutocomplete
                    autoHighlight
                    options={['Pacific Standard Time', 'Eastern Standard Time', 'Central Standard Time', 'Mountain Standard Time']}
                    getOptionLabel={(option) => option.split(' ')[0]}
                    control={control}
                    name='timezone'
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label="Timezone"
                            size='small'
                            color="success"
                            error={!!errors.timezone}
                            helperText={errors.timezone?.message}
                            sx={{ minWidth: '170px', }}
                        />
                    )}
                />

            </Stack>
        </form>
    );
}