import { useEffect, useMemo, useState } from "react";
import { useLocalLayout } from "../useLocalLayout";
import { useLocalGuid } from "../../data/UserGuidContext/useLocalGuid";
import axios from "axios";
import { apiUrlPrefix, subscriptionKey, userGroups } from '../../authConfig';
import { useSnackbar } from 'notistack';
import useHeader from "../useHeader";
import { selectedGridRowsCountSelector } from "@mui/x-data-grid-pro";

export const useViewPanel = (gridName, ref, storageLocation=`${gridName}-grid-layout`, colDefs=null, useColDefs=false) => {
  const [views, setViews] = useState([]);
  const guid = useLocalGuid();
  const { enqueueSnackbar } = useSnackbar();
  const header = useHeader();
  const { applyLayout, clearLayout, loadLayoutLocal, captureLayout, deleteLayoutLocal, saveLayoutLocal, onRowDataChanged} = useLocalLayout(storageLocation, ref, colDefs, useColDefs);

  function updateView(view) {
    view 
    ? applyLayout(view)
    : clearLayout();
  }

  useEffect(() => {
    getViews();
  }, [])

  const viewPanel = useMemo(() => {
    return {
      id: 'gridViewToolPanel',
      labelDefault: 'Layout',
      labelKey: 'gridViewToolPanel',
      toolPanel: 'viewToolPanel',
      toolPanelParams: {
        views: views,
        saveView: postView,
        updateView: updateView,
        deleteView: deleteView,
        getViews: getViews,
      },
      minWidth: 100,
      maxWidth: 300,
      width: 200
    }
  }, [views]);

  async function deleteView(name) {
    const view = views.find(v => v.template === name);
    const deleteUri = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_UserLayoutDelete&parm=${guid}&parm=${gridName}&parm=${view.template}`;
    const options = {
      headers: header,
      url: deleteUri,
    }

    await axios(options).catch(err => { enqueueSnackbar(`Error deleting view(s) in real time tsrs.  ${err}`) });
  }

  async function getViews() {
    const loadUri = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_UserLayoutFetch&parm=${guid}&parm=${gridName}`;
    const options = {
      headers: header,
      url: loadUri,
    }
    axios(options).then(response => { 
      setViews(response.data); 
    }).catch(err => { enqueueSnackbar(`Error getting view data in real time tsrs.  ${err}`) });
  };

  function postView(viewName) {
    const newView = {
      grid: gridName,
      json: captureLayout(),
      template: viewName,
    }

    const saveUri = `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Push?name=PowerStationMetaData.UI_UserLayoutInsertUpdate&parm=${guid}&parm=${newView.grid}&parm=${newView.template}`;
    
    const options = {
      method: 'POST',
      headers: header,
      data: newView.json,
      url: saveUri,
    }

    axios(options).then(response => {
      getViews()
    }).catch(err => { enqueueSnackbar(`Error saving the view data for real time TSRs.  ${err}`) });
  }

  return {
    loadLayoutLocal,
    saveLayoutLocal,
    captureLayout,
    viewPanel,
    onRowDataChanged,
    updateView,
  }
};