import { Dialog, Stack, Slide, DialogContent, DialogTitle, Typography, Box, IconButton, DialogActions, Button } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { apiUrlPrefix } from "../../../authConfig";
import BarChartIcon from '@mui/icons-material/BarChart';
import { CreateScheduleForm } from "./CreateScheduleForm";
import { useTheme } from "@mui/material";
import { AnalysisChart } from "./AnalysisChart";
import { useApi } from "../../useApi";
import { AgGridContainer } from "../../AgGrid/AgGridContainer";
import { AgGridReact } from "ag-grid-react";
import { defaultColumnDef } from "../../AgGrid/defaultGridProps";
import axios from "axios";
import useHeader from "../../useHeader";
import dayjs from "dayjs";
import { generateTotalRowData } from "../Utils";

export const ScheduleDialog = ({ open, handleCancel, context, selectedRows, formId, sourceSinkOptions, }) => {
    const [showAnalysis, setShowAnalysis] = useState(false);
    const contentContainerRef = useRef(null);
    const theme = useTheme();
    const { enqueueSnackbar, logAction, handleErrorResponse, post } = useApi();
    const headers = useHeader();

    const defaultRowData = useMemo(() => [
        ...selectedRows.purchases,
        ...selectedRows.sales,
    ], [selectedRows]);
    const [rowData, setRowData] = useState(defaultRowData);

    const tagIds = useMemo(() => {
        return selectedRows.tag?.tagIdx;
    }, [selectedRows]);

    useEffect(() => {
        setRowData(defaultRowData);
    }, [selectedRows]);

    async function insertUpdateSchedule(data) {
        handleCancel(); // close the dialog
        const { scheduleID, comments, source, sink, por, pod } = data;
        const profileInfo = formatProfileInfo(rowData);
        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Push?name=dealrizz.UI_scheduleInsertUpdate_v3`
            + `&parm=${headers.userGuid}`
            + `&parm=${scheduleID ?? ''}`
            //+ `&parm=${selectedRows.purchase.dealID ?? ''}`
            //+ `&parm=${selectedRows.sale.dealID ?? ''}`
            + `&parm=${comments ?? ''}`
            + `&parm=${por ?? ''}`
            + `&parm=${pod ?? ''}`
            + `&parm=${source ?? ''}`
            + `&parm=${sink ?? ''}`
            + `&parm=${tagIds ?? ''}`

        return post(url, profileInfo).then(response => {
            enqueueSnackbar('Schedule saved successfully.', { variant: 'success' });
            logAction('User saved schedule', 'Deal Rizz Scheduling', { ...data, deals: selectedRows });
        });
    }

    function formatProfileInfo(data) {
        return data.reduce((acc, row) => {
            const dealID = row.dealID;
            const start = dayjs(context.startDate).startOf('day');
            Array.from({ length: 26 }, (_, i) => {
                const startDateTime = start.add(i, 'hour').format('MM/DD/YYYY HH:mm');
                const endDateTime = start.add(i + 1, 'hour').format('MM/DD/YYYY HH:mm');
                acc.push({ dealID, startDateTime, endDateTime, capacityRequested: row[i + 1] });
            });
            return acc;
        }, []);
    }

    const colDefs = useMemo(() => [
        { field: 'dealID', headerName: 'Deal ID', minWidth: 83, cellDataType: 'text' },
        ...Array.from({ length: 26 }, (_, i) => ({
            field: `${i + 1}`,
            minWidth: 55,
            type: 'numericColumn',
            cellDataType: 'number',
            editable: true,
        })),
    ], []);

    const defaultColDef = useMemo(() => ({
        ...defaultColumnDef,
        flex: 1,
    }), []);

    const gridOptions = useMemo(() => ({
        rowClassRules: {
            "ag-row-purchase": params => params.data.Transaction_Type?.toLowerCase() === 'purchase' && theme.palette.mode === 'light',
            "ag-row-sale": params => params.data.Transaction_Type?.toLowerCase() === 'sale' && theme.palette.mode === 'light',
            "ag-row-purchase-dark": params => params.data.Transaction_Type?.toLowerCase() === 'purchase' && theme.palette.mode === 'dark',
            "ag-row-sale-dark": params => params.data.Transaction_Type?.toLowerCase() === 'sale' && theme.palette.mode === 'dark',
        },
    }), [theme.palette.mode]);

    const purchaseColorClass = theme.palette.mode === 'light' ? 'ag-row-purchase' : 'ag-row-purchase-dark';
    const saleColorClass = theme.palette.mode === 'light' ? 'ag-row-sale' : 'ag-row-sale-dark';

    const handleUpdateTotalRows = useCallback(({ api, }) => {
        const visibleRows = [];
        api.forEachNodeAfterFilterAndSort((node) => {
            if (node.data.Transaction_Type === 'Purchase') {
                visibleRows.push(node.data);
            } else {
                //negate the sales values, so they subtract from the total
                const data = { ...node.data };
                for (let i = 1; i <= 26; i++) {
                    data[i] = -data[i];
                }
                visibleRows.push(data);
            }
        });
        const totalRows = generateTotalRowData(visibleRows, 'dealID');
        //api.setGridOption('pinnedBottomRowData', totalRows);
        api.setPinnedBottomRowData(totalRows);
    }, []);

    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth={false}
            ref={contentContainerRef}
        >
            <DialogTitle sx={{ pb: 0, pt: 0.5, px: 2 }}>
                <Stack spacing={2} direction='row' sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant='h6' sx={{ flexGrow: 1, }}>Create Schedule</Typography>
                    <Box sx={{ flexGrow: 1, }} />
                    <IconButton onClick={() => setShowAnalysis(s => !s)} size='large'>
                        <BarChartIcon />
                    </IconButton>
                    <IconButton onClick={handleCancel} size='large'>
                        <CloseIcon />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent sx={{ display: 'flex', flexDirection: 'row', overflowX: 'hidden' }}>
                <Slide in={showAnalysis} direction='left' container={contentContainerRef.current}>
                    <div style={{ display: showAnalysis ? 'block' : 'none', width: '100%', padding: theme.spacing(1), height: '40vh' }}>
                        <AnalysisChart deals={rowData} date={context.startDate} />
                    </div>
                </Slide>
                <Slide in={!showAnalysis} direction='right' container={contentContainerRef.current}>
                    <div style={{ display: !showAnalysis ? 'flex' : 'none', width: '100%', flexDirection: 'column' }}>
                        <CreateScheduleForm
                            onSubmit={insertUpdateSchedule}
                            formId={formId}
                            context={context}
                            selectedRows={selectedRows}
                            sourceSinkOptions={sourceSinkOptions}
                        />
                        <Stack spacing={2} direction='row' sx={{ display: 'flex', alignItems: 'center', pt: 1, }}>
                            <Typography variant='title'>Selected Deals</Typography>
                            <Box sx={{ flexGrow: 1, }} />
                            <Stack direction="row" alignItems="center" spacing={1}>
                                <Box className={purchaseColorClass} sx={{ width: 20, height: 20, borderWidth: '1px', borderStyle: 'solid' }} />
                                <Typography align='center'>- Purchases</Typography>
                            </Stack>
                            <Stack direction="row" alignItems="center" spacing={1}>
                                <Box className={saleColorClass} sx={{ width: 20, height: 20, borderWidth: '1px', borderStyle: 'solid' }} />
                                <Typography align='center'>- Sales</Typography>
                            </Stack>
                        </Stack>
                        <AgGridContainer style={{ padding: theme.spacing(1), }}>
                            <AgGridReact
                                columnDefs={colDefs}
                                defaultColDef={defaultColDef}
                                rowData={rowData}
                                domLayout={'autoHeight'}
                                gridOptions={gridOptions}
                                onFilterChanged={handleUpdateTotalRows}
                                onRowDataUpdated={handleUpdateTotalRows}
                                onCellValueChanged={handleUpdateTotalRows}
                            />
                        </AgGridContainer>
                    </div>
                </Slide>
            </DialogContent>
            <DialogActions sx={{ display: 'flex', justifyContent: 'space-between', pt: 0 }}>
                <Button onClick={handleCancel} color='error'>Cancel</Button>
                <Button type='submit' form={formId} color='primary' variant='contained'>Save & Close</Button>
            </DialogActions>
        </Dialog>
    );
}