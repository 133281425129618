import { IconButton, Stack, } from "@mui/material"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, } from "@mui/x-date-pickers";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import { useMemo, useEffect, useCallback } from "react";
import dayjs from "dayjs";
import FormDatePicker from "../../FormControls/FormDatePicker";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import debounce from 'lodash/debounce';

const schema = yup.object().shape({
    startDate: yup.date().required('Start Date is required'),
    endDate: yup.date().required('End Date is required'),
});

export const FilterCriteriaFormGroup = ({ view, onSubmit, formId, expanded }) => {
    const defaults = {
        ...view,
        startDate: dayjs(),
        endDate: dayjs().add(1, 'day'),
    }

    const defaultValues = useMemo(() => defaults, [view]);

    const { handleSubmit, formState: { errors }, control, watch, getValues, setValue, reset, register, } = useForm({
        resolver: yupResolver(schema),
        defaultValues,
        mode: 'onBlur',
    });

    useEffect(() => {
        const subscription = watch(handleSubmit(onFormSubmit));
        return () => subscription.unsubscribe();
    }, [handleSubmit, watch]);

    useEffect(() => {
        onSubmit(defaultValues);
    }, []);

    useEffect(() => {
        reset(defaults);
    }, [view]);

    const handleDateUpdate = useCallback(debounce((key, value) => {
        //make sure start date is before stop date
        const start = getValues('startDate');
        const end = getValues('endDate');
        if ((key === 'startDate' && dayjs(end).isBefore(value)) || (key === 'endDate' && dayjs(start).isAfter(value))) {
            setValue('startDate', value);
            setValue('endDate', value);
        } else {
            setValue(key, value);
        }
    }, 400), [setValue, getValues]);

    function handleIncrementDates(days) {
        const startDate = getValues('startDate');
        const endDate = getValues('endDate');
        setValue('startDate', startDate.add(days, 'day'));
        setValue('endDate', endDate.add(days, 'day'));
    }

    function onFormSubmit(data) {
        onSubmit(data);
    }

    return (
        <form id={formId} onSubmit={handleSubmit(onFormSubmit)}>
            <Stack direction='row' spacing={2} alignItems='stretch' sx={{ p: 1, pt: 2, display: 'flex', flexWrap: 'wrap', rowGap: '16px' }}>
                <div style={{ display: 'flex', alignItems: 'start' }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <IconButton size='small' onClick={() => {
                            handleIncrementDates(-1);
                        }}>
                            <ArrowLeftIcon />
                        </IconButton>
                        <FormDatePicker
                            name='startDate'
                            control={control}
                            label="Flow Date Start"
                            onChange={(newValue) => handleDateUpdate('startDate', newValue?.startOf('date'))}
                            slotProps={{
                                textField: {
                                    size: 'small',
                                    error: !!errors.startDate,
                                    helperText: expanded && (errors.startDate?.message ?? `Time Zone: ${view.timezone?.split(' ')[0]}`),
                                    sx: { minWidth: '140px', maxWidth: '150px', },
                                }
                            }}
                        />&nbsp;
                        <FormDatePicker
                            name='endDate'
                            control={control}
                            label="Flow Date End"
                            onChange={(newValue) => handleDateUpdate('endDate', newValue?.startOf('date'))}
                            slotProps={{
                                textField: {
                                    size: 'small',
                                    error: !!errors.endDate,
                                    helperText: errors.endDate?.message,
                                    sx: { minWidth: '140px', maxWidth: '150px', },
                                }
                            }}
                        />
                        <IconButton size='small' onClick={() => {
                            handleIncrementDates(1);
                        }}>
                            <ArrowRightIcon />
                        </IconButton>
                    </LocalizationProvider>
                </div>

            </Stack>
        </form>
    );
}