import * as React from 'react';
import { useState, useEffect, useMemo, forwardRef, useContext, } from "react";
import { columnPanel, filterPanel } from '../ToolPanels/DefaultToolPanels';
import { AgGridReact } from "ag-grid-react"
import { useActionAudit } from "../useActionAudit";
import { Box, Button, Grid, Stack, Typography, Fade } from '@mui/material';
import PathGridDetail from './PathGridDetail';

export default forwardRef((props, ref) => {
  const { logAction } = useActionAudit();
  const { data, setSelectedRow, selectedMapNodes } = props;
  const [rowData, setRowData] = useState([]);

  useEffect(() => {
    setRowData(data);
  }, [data])

  const detailCellRenderer = useMemo(() => {
    return (props) => PathGridDetail({...props,});
  }, []);

  function onRowClicked(params) {
    //toggle detail grid
    params.node.setExpanded(!params.node.expanded)
  }

  const gridOptions = {
    rowClassRules: {
      "row-refused": params => params.data.Status === 'REFUSED',
      "row-confirmed": params => params.data.Status === 'CONFIRMED',
      "row-queued": params => params.data.Status === 'QUEUED'
    },
  };

  // all rows assigned CSS class 'default-class'
const rowClass = 'default-class';

// all even rows assigned 'my-shaded-effect'
const getRowClass = params => {
    if (params.node.rowIndex % 2 === 0) {
        return 'my-shaded-effect';
    }
};

  const colDefs = useMemo(() => [
    {
      headerName: 'Path',
      field: 'routeName',
      flex: 4,
      cellRenderer: 'agGroupCellRenderer',
      checkboxSelection: true, 
      
    },
    {
      headerName: 'Pathway',
      field: 'pathway',
      flex: 4,
      cellRenderer: 'agGroupCellRenderer',
      initialHide: true,
    },
    {
      headerName: 'Legs',
      field: 'legs',
      flex: 1,
    },
    {
      headerName: 'Max Capacity',
      field: 'maxCapacity',
      flex: 1,
    },
    {
      headerName: 'Providers',
      field: 'TPs',
      flex: 1,
    },
    {
      headerName: 'Total Cost',
      field: 'totalPrice',
      flex: 1,
      valueFormatter: params => `${new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD"
      }).format(params.value)}`
    }
  ], [])

  const sideBar = useMemo(() => ({
    toolPanels: [
      columnPanel,
      filterPanel,
    ]
  }), []);

  const defaultColDef = useMemo(() => ({
    editable: false,
    sortable: true,
    filter: "agMultiColumnFilter",
    resizable: true,
    floatingFilter: true,
  }), [])

  function onSelectionChanged(params) {
    const selected = params.api.getSelectedRows();
    setSelectedRow(
      selected.length ? selected[0] : undefined
    )
  }

  // dynamically assigning detail row height
  const getRowHeight = params => {
    const isDetailRow = params.node.detail;
    // for all rows that are not detail rows, return nothing
    if (!isDetailRow) { return undefined; }
  
    const detailDataArray = JSON.parse(params.data.detailJson)
    // otherwise return height based on number of rows in detail grid
    const detailPanelHeight = (54 + (detailDataArray.length * 32));
    return detailPanelHeight;
  };
  
  return (
    <AgGridReact 
      ref={ref}
      rowData={rowData}
      columnDefs={colDefs}
      gridOptions={gridOptions}
      // rowClass={rowClass}
      rowSelection={'single'}
      onSelectionChanged={onSelectionChanged}
      getRowClass={getRowClass}
      defaultColDef={defaultColDef}
      sideBar={sideBar}
      overlayNoRowsTemplate={'<span>Fetch routes to see data here.</span>'}
      animateRows={true}
      masterDetail={true}
      detailRowAutoHeight
      //getRowHeight={getRowHeight}
      onRowClicked={onRowClicked}
      detailCellRenderer={'detailCellRenderer'}
      components={{
        detailCellRenderer: detailCellRenderer,
      }}
    />
  )
})