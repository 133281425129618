import { IconButton, Stack, TextField, Box, Tooltip } from "@mui/material"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, } from "@mui/x-date-pickers";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import { useCallback, useEffect, useState } from "react";
import dayjs from "dayjs";
import FormAutocomplete from "../../FormControls/FormAutocomplete";
import FormDatePicker from "../../FormControls/FormDatePicker";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Ribbon, RibbonButton } from "../Ribbon";
import Refresh from "@mui/icons-material/Refresh";
import { debounce } from "lodash";
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth';
import { ScheduleDialog } from "./ScheduleDialog";
import PublishIcon from '@mui/icons-material/Publish';
import { LookupValuesProvider } from "../DealEntry/LookupContext";
import { DealEntryDialog } from "../DealEntry/DealEntryDialog";

const schema = yup.object().shape({
    timezone: yup.string().required('Timezone is required'),
    startDate: yup.date().required('Start Date is required'),
});

export const Toolbar = ({ context, setContext, formId, selectedRows, sourceSinkOptions }) => {
    function onFormSubmit(data) {
        setContext(data);
    }

    return (
        <Ribbon>
            {[
                { title: 'Actions', content: <ActionsGroup formId={formId} selectedRows={selectedRows} context={context} sourceSinkOptions={sourceSinkOptions} scheduleFormId={'scheduling-create-schedule-form'} /> },
                { title: 'Filters', content: <FilterCriteriaFormGroup context={context} onSubmit={onFormSubmit} formId={formId} /> },
            ]}
        </Ribbon>
    );
}

export const ActionsGroup = ({ formId, expanded, selectedRows, context, sourceSinkOptions, scheduleFormId }) => {
    const [openScheduleDialog, setOpenScheduleDialog] = useState(false);
    const [openDealEntryDialog, setOpenDealEntryDialog] = useState(false);
    const allowSchedule = !!selectedRows.purchases?.length && !!selectedRows.sales?.length;

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', p: 1, }}>
            <ScheduleDialog
                open={openScheduleDialog}
                handleCancel={() => setOpenScheduleDialog(false)}
                selectedRows={selectedRows}
                context={context}
                formId={scheduleFormId}
                sourceSinkOptions={sourceSinkOptions}
            />

            <LookupValuesProvider>
                <DealEntryDialog
                    open={openDealEntryDialog}
                    handleClose={() => setOpenDealEntryDialog(false)}
                    handleSave={() => {
                        setOpenDealEntryDialog(false);
                        //programmatically click the refresh button to update the grid
                        document.getElementById(`${formId}-refresh`).click();
                    }}
                />
            </LookupValuesProvider>

            <RibbonButton
                type='submit'
                id={`${formId}-refresh`}
                form={formId}
                icon={<Refresh fontSize='small' />}
                label={'Refresh All'}
                expanded={expanded}
            />

            <Tooltip title='Select a purchase and a sale below, then click here to create a schedule. '>
                <span>
                    <RibbonButton
                        disabled={!allowSchedule}
                        icon={<CalendarViewMonthIcon fontSize='small' />}
                        label={'Schedule'}
                        expanded={expanded}
                        onMouseDown={() => setOpenScheduleDialog(true)}
                    />
                </span>
            </Tooltip>

            <RibbonButton
                onMouseDown={() => setOpenDealEntryDialog(true)}
                icon={<PublishIcon fontSize='small' />}
                label={'Create Deal'}
                expanded={expanded}
            />
        </Box>
    )
}

const FilterCriteriaFormGroup = ({ context, onSubmit, formId }) => {
    const { handleSubmit, formState: { errors }, control, watch, getValues, setValue, } = useForm({
        resolver: yupResolver(schema),
        defaultValues: context,
        mode: 'onBlur',
    });

    useEffect(() => {
        const subscription = watch(handleSubmit(onSubmit));
        return () => subscription.unsubscribe();
    }, [handleSubmit, watch]);

    function handleIncrementDates(days) {
        const start = getValues('startDate');
        setValue('startDate', start.add(days, 'day'));
    }

    return (
        <Box
            sx={{ width: '100%', height: '100%', borderRadius: '0px', display: 'flex', alignItems: 'center', }}
            component='form'
            onSubmit={handleSubmit(onSubmit)}
            id={formId}
        >
            <Stack direction='row' spacing={2} alignItems='stretch' sx={{ pt: 1, display: 'flex', flexWrap: 'wrap', rowGap: '16px' }}>
                <div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <IconButton size='small' onClick={() => {
                            handleIncrementDates(-1);
                        }}>
                            <ArrowLeftIcon />
                        </IconButton>
                        <FormDatePicker
                            name='startDate'
                            control={control}
                            label="Flow Date"
                            slotProps={{
                                textField: {
                                    size: 'small',
                                    error: !!errors.startDate,
                                    helperText: errors.startDate?.message,
                                    sx: { minWidth: '140px', maxWidth: '150px', }
                                }
                            }}
                        />&nbsp;
                        <IconButton size='small' onClick={() => {
                            handleIncrementDates(1);
                        }}>
                            <ArrowRightIcon />
                        </IconButton>
                    </LocalizationProvider>
                </div>

                <FormAutocomplete
                    autoHighlight
                    options={['Pacific Standard Time', 'Eastern Standard Time', 'Central Standard Time', 'Mountain Standard Time']}
                    getOptionLabel={(option) => option.split(' ')[0]}
                    control={control}
                    name='timezone'
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label="Timezone"
                            size='small'
                            color="success"
                            error={!!errors.timezone}
                            helperText={errors.timezone?.message}
                            sx={{
                                minWidth: '170px',
                            }}
                        />
                    )}
                />

            </Stack>
        </Box>
    )
}