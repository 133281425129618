import { useState, useEffect, } from 'react';
import { userGroups } from "../authConfig";
import DivGuard from "../components/Guards/DivGuard";
import { TSRActivity } from '.';
import Collapse from '@mui/material/Collapse';
import Help from '../components/Help';
import { Box, } from '@mui/material';
import CriteriaProvider from '../components/CriteriaContext/CriteriaProvider';
import PowerFolioAppMenu from '../components/PowerFolio/PowerFolioAppMenu';
import PowerFolio from '../components/PowerFolio/PowerFolio';

export default () => {
  const viewStorageKey = 'power-folio-view';
  const savedView = localStorage.getItem(viewStorageKey);
  const [view, setView] = useState(savedView ?? 'powerfolio');
  const [loadedViews, setLoadedViews] = useState({});

  useEffect(() => {
    if (view && !loadedViews[view]) {
      setLoadedViews({
        ...loadedViews,
        [view]: true
      })
    }
  }, [view]);

  useEffect(() => {
    if (view) {
      localStorage.setItem(viewStorageKey, view);
    }
  }, [view])

  return (
    <DivGuard groups={[userGroups.admins]}>
      <CriteriaProvider>
        <Box sx={{ display: 'flex', }}>
          <PowerFolioAppMenu selected={view} handleSelection={(newView) => setView(newView)} />
          <Box
            component="main"
            sx={{
              p: '0px',
              flexGrow: 1,
            }}
          >
            {loadedViews['powerfoliohelp'] && <Collapse in={view === 'powerfoliohelp'}>
              <Help appName={'PowerFolio'} />
            </Collapse>}
            {loadedViews['powerfolio'] && <Collapse in={view === 'powerfolio'}>
              <PowerFolio />
            </Collapse>}
            {loadedViews['activity'] && <Collapse in={view === 'activity'}>
              <TSRActivity visible={view === 'activity'} application='powerfolio' />
            </Collapse>}
          </Box>
        </Box>
      </CriteriaProvider>
    </DivGuard>
  )
}