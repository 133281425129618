import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import UndoIcon from '@mui/icons-material/Undo';
import CheckIcon from '@mui/icons-material/Check';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import { Stack, Tooltip } from '@mui/material';
import { RibbonButton } from '../Ribbon';

export const TransmissionActionsRibbonGroup = ({ selectedTsr, expanded }) => {
    const enableClone = !!selectedTsr;

    return (
        <Stack direction='row' spacing={1} sx={{ p: 1, display: 'flex', flexWrap: 'wrap', rowGap: '16px', alignItems: 'center', flexDirection: 'row' }}>
            
            <RibbonButton
                label="New"
                expanded={expanded}
                disabled={true} //disabled until we hook up functions from TSR Activity
                icon={<AddCircleOutlineIcon
                    fontSize='small'
                />}
            />
            
            <RibbonButton
                label="Clone"
                expanded={expanded}
                // disabled={!enableClone}
                disabled={true} //disabled until we hook up functions from TSR Activity
                icon={<ContentCopyIcon
                    fontSize='small'
                />}
            />
            

            <RibbonButton
                label="Redirect"
                expanded={expanded}
                // disabled={!enableAdjust}
                // disabled
                disabled={true} //disabled until we hook up functions from TSR Activity
                icon={<EditIcon
                    fontSize='small'
                />}
            />

            <RibbonButton
                label="Resale"
                expanded={expanded}
                // disabled={!enableWithdraw}
                disabled={true} //disabled until we hook up functions from TSR Activity
                icon={<UndoIcon
                    fontSize='small'
                />}
            />

            <RibbonButton
                label="Zero"
                expanded={expanded}
                // disabled={!enableCorrect}
                disabled={true} //disabled until we hook up functions from TSR Activity
                icon={<CheckIcon
                    fontSize='small'
                />}
            />

            <RibbonButton
                label="Withdrawl"
                expanded={expanded}
                // disabled={!enableCancel}
                disabled={true} //disabled until we hook up functions from TSR Activity
                icon={<HighlightOffIcon
                    fontSize='small'
                />}
            />

            <RibbonButton
                label="Accept"
                expanded={expanded}
                // disabled={!enableTerminate}
                disabled={true} //disabled until we hook up functions from TSR Activity
                icon={<NotInterestedIcon
                    fontSize='small'
                />}
            />

        </Stack>
    )
};