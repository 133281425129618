import { useDashboard } from "../useDashboard";
import { UnscheduledMWsView } from "./UnscheduledMWsView";
import { AddNewOrUpdateDialog } from "./AddNewOrUpdateDialog";

export const UnscheduledMWsDashboard = () => {
    const dashboardKey = 'unscheduled-mws';
    const useShared = true;

    const Dashboard = useDashboard({ dashboardKey, useShared, ViewComponent: UnscheduledMWsView, AddNewOrUpdateDialog, });
    return Dashboard;
}