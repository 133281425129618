import { useState, useEffect, } from 'react';
import Collapse from '@mui/material/Collapse';
import Menu from './Menu';
import { Box } from '@mui/material';
import CriteriaProvider from '../CriteriaContext/CriteriaProvider';
import DivGuard from '../Guards/DivGuard';
import { userGroups } from '../../authConfig';
import BrainTopology from './BrainTopology/BrainTopology';
import { ForecastDashboard } from '../DealRizz/WeatherForecast/ForecastDashboard';
import { LMPs } from '../DealRizz/LMPs/LMPs';
import { Status } from './Status';
import { CorrelationsDashboard } from './Correlations/CorrelationsDashboard';
import { Adjustments } from './Adjustments/Adjustments';

export default () => {
    const viewStorageKey = 'brain-saved-view';
    const savedView = localStorage.getItem(viewStorageKey);
    const [view, setView] = useState(savedView ?? 'braintopology');
    const [loadedViews, setLoadedViews] = useState({});

    useEffect(() => {
        if (view && !loadedViews[view]) {
            setLoadedViews({
                ...loadedViews,
                [view]: true
            })
        }
    }, [view]);

    useEffect(() => {
        if (view) {
            localStorage.setItem(viewStorageKey, view);
        }
    }, [view])

    return (
        <DivGuard authorizeIfAuthenticated>
            <CriteriaProvider>
                <Box sx={{ display: 'flex' }}>
                    <Menu selected={view} setSelected={(newView) => setView(newView)} />
                    <Box sx={{ flexGrow: 1 }}>
                        {loadedViews['braintopology'] && <Collapse in={view === 'braintopology'}>
                            <BrainTopology />
                        </Collapse>}
                        {loadedViews['forecast'] && <Collapse in={view === 'forecast'}>
                            <ForecastDashboard visible={view === 'forecast'} />
                        </Collapse>}
                        {loadedViews['lmps'] && <Collapse in={view === 'lmps'}>
                            <LMPs visible={view === 'lmps'} />
                        </Collapse>}
                        {loadedViews['brainstatus'] && <Collapse in={view === 'brainstatus'}>
                            <Status />
                        </Collapse>}
                        {loadedViews['correlations'] && <Collapse in={view === 'correlations'}>
                            <DivGuard groups={[userGroups.brain]}>
                                <CorrelationsDashboard />
                            </DivGuard>
                        </Collapse>}
                        {loadedViews['adjustments'] && <Collapse in={view === 'adjustments'}>
                            <DivGuard groups={[userGroups.brain]}>
                                <Adjustments />
                            </DivGuard>
                        </Collapse>}
                    </Box>
                </Box>
            </CriteriaProvider>
        </DivGuard >
    )
}