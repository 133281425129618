import { forwardRef, useRef, useMemo, useCallback, useImperativeHandle, useState, useEffect } from "react"
import { AgGridReact } from "ag-grid-react"
import { defaultColumnDef, defaultStatusBar } from "../../AgGrid/defaultGridProps"
import { columnPanel, filterPanel, palettePanel } from "../../ToolPanels/DefaultToolPanels"
import useGridLayout from "../../useGridLayout"
import { PaletteToolPanel } from "../../ToolPanels/PalettePanel"
import { heatmapStyle, handleUpdateTotalRows, tooltipRenderer, redPastMarkerFormatter } from "../Utils"
import { LayoutToolPanel } from "../../ToolPanels/LayoutToolPanel"
import { useColumnTypes } from "../../AgGrid/useColumnTypes"
import dayjs from "dayjs"
import { jsonOrCommaSeparatedFormatter } from "../Utils"

export const UnscheduledMWsGrid = forwardRef(({ data, date, context, showStatusBar = true, setSelectedRows, }, ref) => {
    const gridRef = useRef();
    const { columnTypes } = useColumnTypes();
    const paletteRef = useRef({ showHeatmap: false });
    const { id, layout, } = context;
    const layoutStorageKey = `deal-rizz-unscheduled-mws-grid-layout-${id}`;

    const baseColDefs = useMemo(() => ([
        {
            headerName: 'Deal ID',
            checkboxSelection: true,
            field: 'dealID',
            cellDataType: 'text',
        },
        {
            field: 'Date',
        },
        {
            field: 'Deal_Token',
            headerName: 'Deal Token',
        },
        {
            field: 'Counterparty',
        },
        {
            field: 'Contract',
        },
        {
            headerName: 'Firm/Non-Firm',
            field: 'FirmNonFirm',
        },
        {
            field: 'Index',
        },
        {
            field: 'Creator',
        },
        {
            field: 'Market',
        },
        {
            field: 'Zone',
        },
        {
            field: 'Strategy',
            valueFormatter: (params) => {
                return jsonOrCommaSeparatedFormatter(params.value);
            },
        },
        {
            field: 'Term',
        },
        {
            headerName: 'Deal Position',
            cellRenderer: 'agSparklineCellRenderer',
            sortable: false,
            flex: 3,
            minWidth: 200,
            valueGetter: (params) => {
                const { data } = params;
                if (!data) return [];
                return [...Array.from({ length: 26 }).keys()].reduce((acc, next) => {
                    const hour = next + 1;

                    acc.push(
                        { x: dayjs(date).startOf('day').add(hour - 1, 'hours').toDate(), y: data[hour] ?? 0 },
                        { x: dayjs(date).startOf('day').add(hour, 'hours').toDate(), y: data[hour] ?? 0 },
                    );
                    return acc;
                }, []);
            },
            cellRendererParams: {
                sparklineOptions: {
                    type: 'area',
                    axis: {
                        type: 'time',
                    },
                    tooltip: {
                        renderer: tooltipRenderer
                    },
                    marker: {
                        formatter: redPastMarkerFormatter,
                    },
                },
            },
        },
        ...Array.from({ length: 26 }, (_, i) => ({
            headerName: `${i + 1}`,
            field: `${i + 1}`,
            flex: 1,
            enableRowGroup: false,
            aggFunc: 'sum',
            minWidth: 55,
            chartDataType: 'series',
            type: 'numericColumn',
            cellDataType: 'number',
            cellStyle: params => heatmapStyle(params, paletteRef),
        })),
        {
            field: 'Deal_Currency',
            headerName: 'Deal Currency',
        },
        {
            field: 'Total_Settlement',
            headerName: 'Total Settlement',
        },
        {
            field: 'Transaction_Type',
            headerName: 'Transaction Type',
        },
    ]), []);

    const defaultColDef = useMemo(() => ({
        ...defaultColumnDef,
        editable: false,
        enableRowGroup: true,
        minWidth: 100,
        flex: 2,
    }), []);

    const { colDefs, layoutPanel, applyLayout, loadLayout, captureLayout, applyFilters, applySavedFilters } = useGridLayout(layoutStorageKey, gridRef, baseColDefs, defaultColDef);

    useImperativeHandle(ref, () => {
        return {
            captureLayout,
            clearSelected: () => gridRef.current?.api.deselectAll(),
            showLoadingOverlay: () => gridRef.current?.api?.showLoadingOverlay(),
            hideOverlay: () => gridRef.current?.api?.hideOverlay(),
        };
    });

    const sideBar = useMemo(() => ({
        toolPanels: [
            columnPanel,
            filterPanel,
            layoutPanel,
            palettePanel(gridRef, paletteRef, 'deal-rizz-unscheduled-mws-palette'),
        ]
    }), []);

    function onGridReady(params) {
        if (layout && !localStorage.getItem(layoutStorageKey)) {
            applyLayout(layout);
        } else {
            loadLayout();
        }
    }

    const handleFirstDataRendered = useCallback(() => {
        if (layout?.filters && !localStorage.getItem(layoutStorageKey)) {
            applyFilters(layout.filters);
        } else if (localStorage.getItem(layoutStorageKey)) {
            applySavedFilters();
        }
    }, []);

    const gridOptions = useMemo(() => ({
        rowClassRules: {
            "ag-custom-total-row": params => !!params.node.rowPinned,
        },
    }), []);

    const updateTotalRows = useCallback(({ api, }) => {
        handleUpdateTotalRows(api, colDefs[0]?.field);
    }, [colDefs]);

    function handleSelectionChanged() {
        if (setSelectedRows) {
            const selectedRows = gridRef.current.api.getSelectedRows();
            setSelectedRows(selectedRows);
        }
    }

    return (
        <AgGridReact
            containerStyle={{ height: '100%', width: '100%' }}
            rowData={data}
            ref={gridRef}
            gridOptions={gridOptions}
            onFilterChanged={updateTotalRows}
            onRowDataUpdated={updateTotalRows}
            onSelectionChanged={handleSelectionChanged}
            rowSelection='multiple'
            rowMultiSelectWithClick
            overlayNoRowsTemplate="No rows. Change the start date in the ribbon to a time with deals."
            enableCharts
            columnDefs={colDefs}
            onFirstDataRendered={handleFirstDataRendered}
            enableFillHandle
            stopEditingWhenCellsLoseFocus
            singleClickEdit
            onGridReady={onGridReady}
            columnTypes={columnTypes}
            statusBar={showStatusBar ? defaultStatusBar : undefined}
            sideBar={sideBar}
            components={{
                layoutToolPanel: LayoutToolPanel,
                paletteToolPanel: PaletteToolPanel,
            }}
        />
    );
});