import { useImperativeHandle, useRef, useMemo, useEffect, useState, useCallback, forwardRef } from "react"
import { Box, Button } from "@mui/material"
import { Toolbar } from "./Toolbar"
import { AgGridContainer } from "../../AgGrid/AgGridContainer"
import { AgGridReact } from "ag-grid-react"
import { defaultColumnDef, defaultStatusBar } from "../../AgGrid/defaultGridProps"
import { useColumnTypes } from "../../AgGrid/useColumnTypes"
import { columnPanel, filterPanel, palettePanel, } from "../../ToolPanels/DefaultToolPanels"
import { LayoutToolPanel } from "../../ToolPanels/LayoutToolPanel"
import { apiUrlPrefix } from "../../../authConfig"
import { useSnackbar } from "notistack"
import useGridLayout from "../../useGridLayout"
import axios from "axios"
import useHeader from "../../useHeader"
import dayjs from "dayjs"
import { useActionAudit } from "../../useActionAudit"
import { Ribbon, RibbonButton, } from "../Ribbon"
import { handleUpdateTotalRows, heatmapStyle, tooltipRenderer, redPastMarkerFormatter } from "../Utils"
import { TransmissionActionsRibbonGroup } from "./TransmissionActionsRibbonGroup"
import { PaletteToolPanel } from "../../ToolPanels/PalettePanel"
import { useGridCrossHighlight } from "../useGridCrossHighlight"
import RemoveDoneIcon from '@mui/icons-material/RemoveDone';
import Refresh from "@mui/icons-material/Refresh";
import { useTheme } from "@mui/material"

export const TransmissionPositionView = forwardRef(({ view, }, ref) => {
    const layoutStorageKey = `deal-rizz-transmission-position-grid-layout-${view.id}`;
    const gridRef = useRef();
    const { columnTypes } = useColumnTypes();
    const [rowData, setRowData] = useState();
    const headers = useHeader();
    const { enqueueSnackbar } = useSnackbar();
    const { logAction } = useActionAudit();
    const [date, setDate] = useState(dayjs().format('MM/DD/YYYY'));
    const [selectedRow, setSelectedRow] = useState([]);
    const paletteRef = useRef({ showHeatmap: false });
    const { highlightingCellClassRules, clearHighlighting, handleCellMouseOverHighlight, } = useGridCrossHighlight(gridRef);

    const fetchTransmissionPositionData = useCallback(async (data) => {
        logAction('User fetched Transmission Position Data', 'Transmission Position', data);
        const { timezone, book, date, } = data;
        setDate(dayjs(date).format('MM/DD/YYYY'));
        gridRef.current?.api?.showLoadingOverlay();

        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=dealrizz.UI_fetchTransmissionPosition_v2`
            + `&parm=${headers.userGuid}`
            + `&parm=${dayjs(date).format('MM/DD/YYYY')}`
            + `&parm=${timezone}`
            + `&parm=${book ?? ''}`

        return axios.get(url, { headers: headers }).then(response => {
            setRowData(response?.data ?? []);
        }).catch((error) => {
            enqueueSnackbar(error.message, { variant: 'error' });
            if (!rowData?.length) { setRowData([]); }
        }).finally(() => {
            gridRef.current?.api?.hideOverlay();
        });
    }, [headers]);

    const baseColDefs = useMemo(() => ([
        { field: "AssignmentRef", headerName: "Aref", checkboxSelection: true, cellDataType: 'text' },
        { field: "ID", headerName: "ID", initialHide: true },
        { field: "Status", headerName: "Status" },
        { field: "Provider", headerName: "Provider" },
        { field: "SellerCode", headerName: "Seller Code" },
        { field: "CustomerCode", headerName: "Customer Code" },
        { field: "PathName", enableRowGroup: "true", headerName: "Path Name" },
        { field: "PointOfReceipt", headerName: "Point Of Receipt" },
        { field: "PointOfDelivery", headerName: "Point Of Delivery" },
        { field: "Source", headerName: "Source" },
        { field: "Sink", headerName: "Sink" },
        {
            field: "StartTime",
            headerName: "Start Time",
            type: 'dateColumn',
        },
        {
            field: "StopTime",
            headerName: "Stop Time",
            type: 'dateColumn',
        },
        {
            headerName: 'Position Graph',
            cellRenderer: 'agSparklineCellRenderer',
            sortable: false,
            flex: 3,
            valueGetter: (params) => {
                const { data } = params;
                if (!data) return [];
                return [...Array.from({ length: 26 }).keys()].reduce((acc, next) => {
                    const hour = next + 1;

                    acc.push(
                        { x: dayjs(date).startOf('day').add(hour - 1, 'hours').toDate(), y: data[hour] ?? 0 },
                        { x: dayjs(date).startOf('day').add(hour, 'hours').toDate(), y: data[hour] ?? 0 },
                    );
                    return acc;
                }, []);
            },
            cellRendererParams: {
                sparklineOptions: {
                    type: 'area',
                    axis: {
                        type: 'time',
                    },
                    tooltip: {
                        renderer: tooltipRenderer
                    },
                    marker: {
                        formatter: redPastMarkerFormatter,
                    },
                },
            },
        },
        ...Array.from({ length: 26 }, (_, i) => ({
            headerName: `${i + 1}`,
            field: `${i + 1}`,
            enableValue: true,
            enableRowGroup: false,
            aggFunc: 'sum',
            minWidth: 55,
            chartDataType: 'series',
            type: 'numericColumn',
            cellDataType: 'number',
            cellStyle: params => heatmapStyle(params, paletteRef),
        })),
        { field: "Capacity", headerName: "Capacity" },
        { field: "ServiceIncrement", headerName: "Service Increment" },
        { field: "TSClass", headerName: "TS Class" },
        { field: "TSType", headerName: "TS Type" },
        { field: "TSPeriod", headerName: "TS Period" },
        { field: "TSWindow", headerName: "TS Window" },
        { field: "TSSubclass", headerName: "TS Subclass" },
        { field: "SaleRef", headerName: "Sale Reference" },
        { field: "RequestRef", headerName: "Request Reference" },
        { field: "DealRef", headerName: "Deal Reference" },
        { field: "PostingRef", headerName: "Posting Reference" },
        { field: "ReassignedStopTime", headerName: "Reassigned Stop Time" },
        { field: "RequestType", headerName: "Request Type" },
        { field: "RelatedRef", headerName: "Related Reference" },
        { field: "SellerRef", headerName: "Seller Reference" },
        { field: "NERCcurtailmentPriority", headerName: "NERC Curtailment Priority" },
        { field: "Preconfirmed", headerName: "Preconfirmed" },
        { field: "TimeQueued", headerName: "Time Queued" },
        { field: "ResponseTimeLimit", headerName: "Response Time Limit" },
        { field: "TimeOfLastUpdate", headerName: "Time Of Last Update" },
        { field: "STATUS_COMMENTS", headerName: "Status Comments" },
        { field: "PRIMARY_PROVIDER_COMMENTS", headerName: "Primary Provider Comments" },
        { field: "SELLER_COMMENTS", headerName: "Seller Comments" },
        { field: "CUSTOMER_COMMENTS", headerName: "Customer Comments" },
        { field: "SELLER_NAME", headerName: "Seller Name" },
        { field: "SELLER_PHONE", headerName: "Seller Phone" },
        { field: "SELLER_FAX", headerName: "Seller Fax" },
        { field: "SELLER_EMAIL", headerName: "Seller Email" },
        { field: "CUSTOMER_NAME", headerName: "Customer Name" },
        { field: "CUSTOMER_PHONE", headerName: "Customer Phone" },
        { field: "CUSTOMER_FAX", headerName: "Customer Fax" },
        { field: "CUSTOMER_EMAIL", headerName: "Customer Email" }
    ]), []);

    const autoGroupColumnDef = useMemo(() => {
        return {
            // group columns configured to use the Group Column Filter
            filter: 'agGroupColumnFilter',
        };
    }, []);

    const defaultColDef = useMemo(() => ({
        ...defaultColumnDef,
        editable: false,
        minWidth: 100,
        // floatingFilter: true,
        // filter: true,
        flex: 1,
    }), []);

    const { colDefs, layoutPanel, applyLayout, loadLayout, captureLayout, applyFilters, applySavedFilters } = useGridLayout(layoutStorageKey, gridRef, baseColDefs, defaultColDef);

    const styledColDefs = useMemo(() => colDefs.map(colDef => ({
        ...colDef,
        cellClassRules: highlightingCellClassRules,
    })), [colDefs, highlightingCellClassRules]);

    useImperativeHandle(ref, () => {
        return {
            captureLayout,
        };
    });

    const sideBar = useMemo(() => ({
        toolPanels: [
            columnPanel,
            filterPanel,
            layoutPanel,
            palettePanel(gridRef, paletteRef, 'transmission-position-palette'),
        ]
    }), []);

    function onGridReady(params) {
        if (view?.layout && !localStorage.getItem(layoutStorageKey)) {
            applyLayout(view.layout);
        } else {
            loadLayout();
        }
    }

    const handleFirstDataRendered = useCallback(() => {
        if (view?.layout?.filters && !localStorage.getItem(layoutStorageKey)) {
            applyFilters(view.layout.filters);
        } else if (localStorage.getItem(layoutStorageKey)) {
            applySavedFilters();
        }
    }, []);

    const toolbarFormId = `transmission-position-toolbar-form-${view.id}`;

    const updateTotalRows = useCallback(({ api, }) => {
        handleUpdateTotalRows(api, 'AssignmentRef');
    }, []);

    const gridOptions = useMemo(() => ({
        rowClassRules: {
            "ag-custom-total-row": params => !!params.node.rowPinned,
        },
    }), []);

    function handleSelectionChanged() {
        const selectedRows = gridRef.current.api.getSelectedRows();
        setSelectedRow(selectedRows[0])
    }

    function handleDeselectAll() {
        gridRef.current.api.deselectAll();
    }

    return (
        <Box sx={{ height: '86vh', display: 'flex', flexDirection: 'column' }}>
            <Ribbon>
                {[
                    { title: 'TSRs', content: <TransmissionActionsRibbonGroup selectedTsr={selectedRow} /> },
                    { title: 'Grid', content: <GridActionsGroup formId={toolbarFormId} handleDeselectAll={handleDeselectAll} /> },
                    { title: 'Filter', content: <Toolbar view={view} onSubmit={fetchTransmissionPositionData} formId={toolbarFormId} /> },
                ]}
            </Ribbon>
            {/*<ChartDialog />*/}
            <AgGridContainer style={{ display: 'flex', flex: 1, width: '100%' }} onMouseLeave={clearHighlighting}>
                <AgGridReact
                    containerStyle={{ display: 'flex', flexDirection: 'column', flex: 1, width: '100%', }}
                    rowData={rowData}
                    ref={gridRef}
                    columnDefs={styledColDefs}
                    gridOptions={gridOptions}
                    enableRangeSelection
                    onFilterChanged={updateTotalRows}
                    onRowDataUpdated={updateTotalRows}
                    enableCharts
                    //popupParent={popupParent}
                    //createChartContainer={createChartContainer}
                    onSelectionChanged={handleSelectionChanged}
                    onFirstDataRendered={handleFirstDataRendered}
                    onCellMouseOver={handleCellMouseOverHighlight}
                    suppressAggFuncInHeader={true}
                    autoGroupColumnDef={autoGroupColumnDef}
                    groupDisplayType={"groupRows"}
                    enableFillHandle
                    groupTotalRow={"bottom"}
                    groupSuppressBlankHeader={true}
                    stopEditingWhenCellsLoseFocus
                    singleClickEdit
                    onGridReady={onGridReady}
                    columnTypes={columnTypes}
                    statusBar={defaultStatusBar}
                    sideBar={sideBar}
                    components={{
                        layoutToolPanel: LayoutToolPanel,
                        paletteToolPanel: PaletteToolPanel,
                    }}
                />
            </AgGridContainer>
        </Box>
    )
});

const GridActionsGroup = ({ formId, expanded, handleDeselectAll }) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'start', p: 1, }}>
            <RibbonButton
                type='submit'
                id={`${formId}-refresh`}
                form={formId}
                icon={<Refresh fontSize='small' />}
                label={'Refresh'}
                expanded={expanded}
            />

            <RibbonButton
                onMouseDown={handleDeselectAll}
                icon={<RemoveDoneIcon fontSize='small' />}
                label={'Clear Selections'}
                expanded={expanded}
            />
        </Box>
    )
}