import { useImperativeHandle, useState, forwardRef, useRef, useCallback } from "react"
import { Box, Stack } from "@mui/material"
import { Toolbar } from "./Toolbar"
import { AgGridContainer } from "../../AgGrid/AgGridContainer"
import { Ribbon, RibbonButton, } from "../Ribbon"
import { TagActionsRibbonGroup } from "./TagActionsRibbonGroup"
import { useChart } from "../useChart"
import Refresh from "@mui/icons-material/Refresh";
import BarChartIcon from '@mui/icons-material/BarChart';
import { TagReportGrid } from "./TagReportGrid"
import { TSRActionsRibbonGroup } from "./TSRActionsRibbonGroup"

export const TagReportView = forwardRef(({ view, }, ref) => {
    const { ChartContainer, createChartContainer, popupParent, } = useChart({ id: view.id });
    const [showChart, setShowChart] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedAdjustment, setSelectedAdjustment] = useState();
    const gridRef = useRef();

    const toolbarFormId = `tag-report-toolbar-form-${view.id}`;

    function handleClearGridSelections() {
        gridRef.current.clearSelected();
    }

    function handleRefresh(data) {
        gridRef.current?.refresh(data);
    }

    function handleGridTransaction(transaction) {
        gridRef.current?.applyTransaction(transaction);
    }

    function forEachGridNode(callback) {
        gridRef.current?.forEachNode(callback);
    }

    useImperativeHandle(ref, () => {
        return {
            captureLayout: () => gridRef.current?.captureLayout(),
        };
    });

    const GridActionsGroup = ({ formId, expanded, }) => {
        return (
            <Stack direction='row' spacing={1} sx={{ p: 1, display: 'flex', flexWrap: 'wrap', rowGap: '16px', alignItems: 'start', flexDirection: 'row' }}>
                <RibbonButton
                    type='submit'
                    form={formId}
                    icon={<Refresh fontSize='small' />}
                    label={'Refresh'}
                    expanded={expanded}
                />
                <RibbonButton
                    onMouseDown={() => setShowChart(!showChart)}
                    icon={<BarChartIcon fontSize='small' />}
                    disabled
                    label={showChart ? 'Hide Chart' : 'Show Chart'}
                    expanded={expanded}
                />
            </Stack>
        )
    }

    return (
        <Box sx={{ height: '86vh', display: 'flex', flexDirection: 'column' }}>
            <Ribbon>
                {[
                    {
                        title: 'Tags',
                        content: <TagActionsRibbonGroup
                            selectedTags={selectedRows}
                            clearSelected={handleClearGridSelections}
                            context={view}
                            selectedAdjustment={selectedAdjustment}
                            applyGridTransaction={handleGridTransaction}
                            forEachGridNode={forEachGridNode}
                        />
                    },
                    {
                        title: 'TSRs',
                        visible: selectedRows[0]?.tagStatus?.toLowerCase() === 'draft',
                        content:
                            <TSRActionsRibbonGroup
                                selectedTags={selectedRows}
                                context={view}
                            />
                    },
                    { title: 'Grid', content: <GridActionsGroup formId={toolbarFormId} /> },
                    {
                        title: 'Filter',
                        content: <Toolbar
                            view={view}
                            onSubmit={handleRefresh}
                            formId={toolbarFormId}
                        />
                    },
                ]}
            </Ribbon>
            <AgGridContainer style={{ display: 'flex', flex: 1, width: '100%' }}>
                <TagReportGrid
                    context={view}
                    setSelectedRows={setSelectedRows}
                    ref={gridRef}
                    setSelectedAdjustment={setSelectedAdjustment}
                />
            </AgGridContainer>
            <Box sx={{ display: showChart ? 'flex' : 'none', flex: 1, width: '100%' }} id='tag-report-chart-container'>
                <ChartContainer style={{ width: '100%' }} />
            </Box>
        </Box>
    )
});