import { RibbonButton, } from "../Ribbon";
import { Box } from "@mui/material";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useCallback, useState } from "react";
import debounce from "lodash/debounce";
import ConfirmationDialog from "../../TSRActivity/ConfirmationDialog";
import { useApi } from "../../useApi";
import CheckIcon from '@mui/icons-material/Check';

export const ScheduleActionsGroup = ({ expanded, selectedSchedule, handleRowVoidUpdate, handleRowConfirmUpdate }) => {
    const [confirmationDialogProps, setConfirmationDialogProps] = useState({});
    const { headers, apiUrlPrefix, enqueueSnackbar, logAction, get } = useApi();
    const buttonDebounce = 1500;

    function handleConfirmVoid() {
        setConfirmationDialogProps({
            open: true,
            message: `You are about to void the selected schedule (${selectedSchedule.scheduleID}). Continue?`,
            onConfirmation: handleVoid,
            onCancel: () => setConfirmationDialogProps({ open: false }),
        });
    }

    function handleConfirmConfirm() {
        setConfirmationDialogProps({
            open: true,
            message: `You are about to confirm the selected schedule (${selectedSchedule.scheduleID}). Continue?`,
            onConfirmation: handleConfirm,
            onCancel: () => setConfirmationDialogProps({ open: false }),
        });
    }

    const handleConfirm = useCallback(debounce(async () => {
        setConfirmationDialogProps({ open: false });
        const toConfirm = selectedSchedule.Schedule_Status === 'CONFIRMED' ? 0 : 1;
        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=dealrizz.UI_confirmSchedule`
            + `&parm=${headers.userGuid}`
            + `&parm=${selectedSchedule.scheduleID}`
            + `&parm=${toConfirm}`

        return get(url).then(response => {
            enqueueSnackbar('Schedule confirmed successfully.', { variant: 'success' });
            logAction(`User confirmed schedule with id ${selectedSchedule.scheduleID}`, 'Schedule Summary');
            handleRowConfirmUpdate(selectedSchedule.scheduleID, toConfirm);
        });
    }, buttonDebounce, { leading: true, }), [headers, apiUrlPrefix, selectedSchedule]);

    const handleVoid = useCallback(debounce(async () => {
        setConfirmationDialogProps({ open: false });
        const toVoid = selectedSchedule.Schedule_Status === 'VOIDED' ? 0 : 1;
        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=dealrizz.UI_voidSchedule`
            + `&parm=${headers.userGuid}`
            + `&parm=${selectedSchedule.scheduleID}`
            + `&parm=${toVoid}`

        return get(url).then(response => {
            enqueueSnackbar(`Schedule ${toVoid ? '' : 'un'}voided successfully.`, { variant: 'success' });
            logAction(`User ${toVoid ? '' : 'un'}voided schedule with id ${selectedSchedule.scheduleID}`, 'Schedule Summary');
            handleRowVoidUpdate(selectedSchedule.scheduleID, toVoid);
        });
    }, buttonDebounce, { leading: true, }), [headers, apiUrlPrefix, selectedSchedule]);

    const voidButtonText = selectedSchedule && selectedSchedule.Schedule_Status === 'VOIDED' ? 'Unvoid' : 'Void';
    const confirmButtonText = selectedSchedule && selectedSchedule.Schedule_Status === 'CONFIRMED' ? 'Unconfirm' : 'Confirm';

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'start', p: 1, }}>
            <ConfirmationDialog {...confirmationDialogProps} />

            <RibbonButton
                icon={<CheckIcon fontSize='small' />}
                onMouseDown={handleConfirmConfirm}
                label={confirmButtonText}
                expanded={expanded}
                disabled={!selectedSchedule}
            />

            <RibbonButton
                icon={<HighlightOffIcon fontSize='small' />}
                onMouseDown={handleConfirmVoid}
                label={voidButtonText}
                expanded={expanded}
                disabled={!selectedSchedule}
            />
        </Box>
    );
}