import { useDashboard } from "../../DealRizz/useDashboard";
import AddNewOrUpdateDialog from "./AddNewOrUpdateDialog";
import CorrelationsView from "./CorrelationsView";

export const CorrelationsDashboard = () => {
    const dashboardKey = 'correlations';
    const useShared = true;

    const Dashboard = useDashboard({ dashboardKey, useShared, ViewComponent: CorrelationsView, AddNewOrUpdateDialog, });
    return Dashboard;
}