import { PositionReportView } from "./PositionReportView";
import { AddNewOrUpdateDialog } from "./AddNewOrUpdateDialog";
import { useDashboard } from "../useDashboard";

export const PositionReportDashboard = () => {
    const dashboardKey = 'position-report';
    const useShared = true;

    const Dashboard = useDashboard({ dashboardKey, useShared, ViewComponent: PositionReportView, AddNewOrUpdateDialog, });
    return Dashboard;
}