import { Tooltip, TextField, Grid, Button, Badge, Divider, FormControlLabel, } from "@mui/material";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { object, string, array, } from "yup";
import FormAutocomplete from "../../FormControls/FormAutocomplete";
import useFilteredCriteria from "../../CriteriaContext/useFilteredCriteria";
import { useState } from "react";
import ProfileDialog from "../../ProfileDialog";
import { Check, ErrorOutline, } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { useTheme } from "@emotion/react";
import { useTransmissionTemplates } from "../../useTransmissionTemplates";
import ConfirmationDialog from "../../TSRActivity/ConfirmationDialog";

export default (props) => {
    const { defaultValues, formId, submit, } = props;
    const { pors, pods, providers, filteredTSCombos, } = useFilteredCriteria({});
    const [openProfileDialog, setOpenProfileDialog] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();

    const schema = object().shape({
        timezone: string().required('Timezone is required'),
        por: string().required('POR is required').min(1, 'POR is required'),
        pod: string().required('POD is required').min(1, 'POD is required'),
        tsClass: array().min(1, 'TS Class is required'),
        tsIncrement: array().min(1, 'TS Increment is required'),
        tsType: array().min(1, 'TS Type is required'),
        tsPeriod: array().min(1, 'TS Period is required'),
        tsWindow: array().min(1, 'TS Window is required'),
        profile: array().required('Profile is required').min(1, 'Profile is required'),
    })

    const { handleSubmit, control, formState: { errors, }, setValue, getValues, reset, trigger, } = useForm({
        defaultValues: defaultValues,
        resolver: yupResolver(schema),
        mode: 'onBlur',
    });

    const { TemplateAutocomplete, AddNewTemplateButton, AddNewTemplateDialog, confirmationDialogProps, } = useTransmissionTemplates({ getValues, reset, templateTypeId: 5, });

    function handleWildcardUpdate(key, value) {
        const [lastAdded] = value?.slice(-1);
        if (lastAdded === '*') {
            setValue(key, ['*'])
        } else {
            setValue(key, value.filter(option => option !== '*'))
        }
    }

    function onSubmit(data) {
        submit({
            ...data,
            ...data.template,
        });
    }

    function onError(errors) {
        const firstError = Object.keys(errors)[0];
        firstError && enqueueSnackbar(errors[firstError].message, { variant: 'error' });
    }

    function handleProfileUpdate(data) {
        setValue('profile', data);
        setOpenProfileDialog(false);
        trigger('profile');
    }

    return (
        <div>
            <ProfileDialog
                open={openProfileDialog}
                closeDialog={() => setOpenProfileDialog(false)}
                handleProfileSave={handleProfileUpdate}
                defaultValues={{
                    ...defaultValues?.profileConfig,
                }}
                version={2}
            />
            <AddNewTemplateDialog />
            <ConfirmationDialog {...confirmationDialogProps} />
            <form id={formId} onSubmit={handleSubmit(onSubmit, onError)}>
                <Grid container justifyContent="center" style={{ marginTop: '20px' }} spacing={2}>
                    <Grid item xs={3}>
                        <FormAutocomplete
                            fullWidth
                            options={['Pacific', 'Eastern', 'Central', 'Mountain']}
                            control={control}
                            name='timezone'
                            disableClearable
                            autoHighlight
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Timezone"
                                    size='small'
                                    color="success"
                                    error={!!errors.timezone}
                                    helperText={errors.timezone?.message}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Tooltip title="Choose a Point of Receipt." arrow placement="top">
                            <FormAutocomplete
                                name='por'
                                control={control}
                                fullWidth
                                autoHighlight
                                options={pors ?? []}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        size="small"
                                        variant="outlined"
                                        label="POR"
                                        error={!!errors.por}
                                        helperText={errors.por?.message}
                                    />
                                )}
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item xs={3}>
                        <Tooltip title="Choose a Point of Delivery." arrow placement="top">
                            <FormAutocomplete
                                name='pod'
                                control={control}
                                fullWidth
                                autoHighlight
                                options={pods ?? []}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        size="small"
                                        variant="outlined"
                                        label="POD"
                                        error={!!errors.pod}
                                        helperText={errors.pod?.message}
                                    />
                                )}
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item xs={3}>
                        <Badge invisible={!getValues('profile')?.length && !errors.profile} sx={{ width: '100%' }} color={errors.profile ? 'error' : 'success'} badgeContent={
                            errors.profile ? <Tooltip title={errors.profile?.message} arrow placement="top"><ErrorOutline fontSize="small" /></Tooltip> : <Check fontSize="small" />
                        }>
                            <Button
                                onClick={() => setOpenProfileDialog(true)}
                                variant="contained"
                                fullWidth
                            >
                                Set Profile
                            </Button>
                        </Badge>
                    </Grid>
                    <Grid item xs={12} >
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            labelPlacement="start"
                            control={<TemplateAutocomplete />}
                            label="Template: "
                            componentsProps={{
                                typography: {
                                    sx: { marginRight: theme.spacing(1), }
                                }
                            }}
                        />
                        &nbsp;&nbsp;&nbsp;
                        <AddNewTemplateButton />
                    </Grid>
                    <Grid item xs={6}>
                        <Tooltip title="Select * to include any TS Class" arrow placement="top">
                            <FormAutocomplete
                                name='template.tsClass'
                                control={control}
                                onChange={(_, newVal) => {
                                    handleWildcardUpdate('template.tsClass', newVal);
                                }}
                                fullWidth
                                multiple
                                autoHighlight
                                options={['*', ...filteredTSCombos.tsClasses]}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        size="small"
                                        variant="outlined"
                                        label="TS Class"
                                        color="success"
                                        error={!!errors.tsClass}
                                        helperText={errors.tsClass?.message}
                                    />
                                )}
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item xs={6}>
                        <Tooltip title="Select * to include any TS Increment" arrow placement="top">
                            <FormAutocomplete
                                name='template.tsIncrement'
                                control={control}
                                onChange={(_, newVal) => {
                                    handleWildcardUpdate('template.tsIncrement', newVal);
                                }}
                                fullWidth
                                multiple
                                autoHighlight
                                options={['*', ...filteredTSCombos.serviceIncrements]}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        size="small"
                                        variant="outlined"
                                        label="TS Increment"
                                        color="success"
                                        error={!!errors.tsIncrement}
                                        helperText={errors.tsIncrement?.message}
                                    />
                                )}
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item xs={6}>
                        <Tooltip title="Select * to include any TS Type" arrow placement="top">
                            <FormAutocomplete
                                name={'template.tsType'}
                                control={control}
                                onChange={(_, newVal) => {
                                    handleWildcardUpdate('template.tsType', newVal);
                                }}
                                fullWidth
                                multiple
                                autoHighlight
                                options={['*', ...filteredTSCombos.tsTypes]}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        size="small"
                                        variant="outlined"
                                        label="TS Type"
                                        color="success"
                                        error={!!errors.tsType}
                                        helperText={errors.tsType?.message}
                                    />
                                )}
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item xs={6}>
                        <Tooltip title="Select * to include any TS Period" arrow placement="top">
                            <FormAutocomplete
                                name={'template.tsPeriod'}
                                control={control}
                                onChange={(_, newVal) => {
                                    handleWildcardUpdate('template.tsPeriod', newVal);
                                }}
                                fullWidth
                                multiple
                                autoHighlight
                                options={['*', ...filteredTSCombos.tsPeriods]}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        size="small"
                                        variant="outlined"
                                        label="TS Period"
                                        color="success"
                                        error={!!errors.tsPeriod}
                                        helperText={errors.tsPeriod?.message}
                                    />
                                )}
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item xs={6}>
                        <Tooltip title="Select * to include any TS Window" arrow placement="top">
                            <FormAutocomplete
                                name={'template.tsWindow'}
                                control={control}
                                onChange={(_, newVal) => {
                                    handleWildcardUpdate('template.tsWindow', newVal);
                                }}
                                fullWidth
                                multiple
                                autoHighlight
                                options={['*', ...filteredTSCombos.tsWindows]}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        size="small"
                                        variant="outlined"
                                        label="TS Window"
                                        color="success"
                                        error={!!errors.tsWindow}
                                        helperText={errors.tsWindow?.message}
                                    />
                                )}
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item xs={6}>
                        <Tooltip title="Select * to include any TS Subclass" arrow placement="top">
                            <FormAutocomplete
                                name={'template.tsSubclass'}
                                control={control}
                                onChange={(_, newVal) => {
                                    handleWildcardUpdate('template.tsSubclass', newVal);
                                }}
                                autoHighlight
                                fullWidth
                                multiple
                                options={['*', ...filteredTSCombos.tsSubclasses]}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        size="small"
                                        variant="outlined"
                                        label="TS Subclass"
                                        color="success"
                                        error={!!errors.tsSubclass}
                                        helperText={errors.tsSubclass?.message}
                                    />
                                )}
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item xs={6}>
                        <Tooltip title="Selected providers will not appear in results." arrow placement="top">
                            <FormAutocomplete
                                name={'template.excludeTp'}
                                control={control}
                                fullWidth
                                multiple
                                autoHighlight
                                options={providers ?? []}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        size='small'
                                        label="Excluded Providers"
                                        color="success"
                                        error={!!errors.excludeTp}
                                        helperText={errors.excludeTp?.message}
                                    />
                                )}
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item xs={6}>
                        <Tooltip title="Selected points will not appear in results." arrow placement="top">
                            <FormAutocomplete
                                name={'template.excludePoints'}
                                control={control}
                                fullWidth
                                multiple
                                autoHighlight
                                options={pors ?? []}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        size='small'
                                        label="Excluded Points"
                                        color="success"
                                        error={!!errors.excludePoints}
                                        helperText={errors.excludePoints?.message}
                                    />
                                )}
                            />
                        </Tooltip>
                    </Grid>
                </Grid>
            </form>
        </div>
    )
}