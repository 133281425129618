import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import UndoIcon from '@mui/icons-material/Undo';
import CheckIcon from '@mui/icons-material/Check';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import { Stack } from '@mui/material';
import { RibbonButton } from '../Ribbon';
import { enqueueSnackbar } from 'notistack';
import { useActionAudit } from '../../useActionAudit';
import dayjs from 'dayjs';
import { useCallback, useState } from 'react';
import { useApi } from '../../useApi';
import { TagTerminationDialog } from './TagTerminationDialog';
import ConfirmationDialog from "../../TSRActivity/ConfirmationDialog";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PublishIcon from '@mui/icons-material/Publish';
import debounce from 'lodash/debounce';

export const TagActionsRibbonGroup = ({ selectedTags, expanded, clearSelected, context, selectedAdjustment, applyGridTransaction, forEachGridNode, }) => {
    const [openTerminationDialog, setOpenTerminationDialog] = useState(false);
    const [adjustDialogProps, setAdjustDialogProps] = useState({});
    const [confirmationDialogProps, setConfirmationDialogProps] = useState({});
    const selectedTag = selectedTags?.[0];
    const compositeState = selectedTag?.compositeState?.toLowerCase();
    const profileType = selectedTag?.ProfileType?.toLowerCase();
    const id = selectedTag?.tagIdx;
    const { logAction } = useActionAudit();
    const { post, headers, apiUrlPrefix, get } = useApi();

    const enableCorrect = ['pending'].includes(compositeState);
    const enableWithdraw = ['pending'].includes(compositeState);
    const enableAdjust = ['confirmed', 'implemented'].includes(compositeState) && ['current'].includes(profileType);
    const enableTerminate = ['confirmed', 'implemented'].includes(compositeState);
    const enableCancel = ['confirmed', 'implemented'].includes(compositeState);
    const enableCopy = !!selectedTag;
    const allowRemoveDrafts = !!id && !!selectedAdjustment?.RequestId && selectedAdjustment?.RequestStatus?.toLowerCase() === 'draft';
    const allowRemoveDraftTag = !!id && selectedTag?.tagStatus?.toLowerCase() === 'draft';
    const allowSubmitDraftTag = !!id && selectedTag?.tagStatus?.toLowerCase() === 'draft';
    const buttonDebounce = 1500;

    const handleCancel = useCallback(debounce(async () => {
        logAction(`User cancelled tag with id ${id}`, 'Tag Reports', selectedTag);

        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=dealrizz.UI_ETag_Cancel`
            + `&parm=${headers.userGuid}`
            + `&parm=${id}`

        return get(url).then(response => {
            enqueueSnackbar('Tag cancelled successfully', { variant: 'success' });
            clearSelected();
        });
    }, buttonDebounce, { leading: true, }), [headers, apiUrlPrefix, id, selectedTag]);

    const handleWithdraw = useCallback(debounce(async () => {
        logAction(`User withdrew tag with id ${id}`, 'Tag Reports', selectedTag);

        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=dealrizz.UI_ETag_Withdraw`
            + `&parm=${headers.userGuid}`
            + `&parm=${id}`

        return get(url).then(response => {
            enqueueSnackbar('Tag withdrawn successfully', { variant: 'success' });
            clearSelected();
        });
    }, buttonDebounce, { leading: true, }), [headers, apiUrlPrefix, id, selectedTag]);

    const handleTerminate = useCallback(debounce(async ({ endDate, notes }) => {
        setOpenTerminationDialog(false);
        logAction(`User terminated tag with id ${id}`, 'Tag Reports', selectedTag);

        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=dealrizz.UI_ETag_Terminate`
            + `&parm=${headers.userGuid}`
            + `&parm=${id}`
            + `&parm=${dayjs(endDate).format('YYYY-MM-DD HH:mm:ss')}`
            + `&parm=${context.timezone}`
            + `&parm=${notes}`

        return get(url).then(response => {
            enqueueSnackbar('Tag terminated successfully', { variant: 'success' });
            clearSelected();
        });
    }, buttonDebounce, { leading: true, }), [headers, apiUrlPrefix, id, selectedTag, context]);

    const handleSaveAdjustments = useCallback(debounce(async () => {
        logAction(`User adjusted tag with id ${id}`, 'Tag Reports', selectedTag);
        const response = await fetchTransmissionProfiles();
        const adjustmentInfo = response.data;

        const info = {
            transmissionInfo: adjustmentInfo,
            energyInfo: selectedTag,
            timezone: context.timezone,
        }

        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Push?name=dealrizz.UI_TagReport_AdjustTag`
            + `&parm=${headers.userGuid}`
            + `&parm=${id}`

        return post(url, JSON.stringify(info)).then(response => {
            enqueueSnackbar('Tag adjusted successfully', { variant: 'success' });
            clearSelected();
        });
    }, buttonDebounce, { leading: true, }), [selectedTag, context, headers, apiUrlPrefix]);

    async function fetchTransmissionProfiles() {
        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=dealrizz.UI_TagReport_transAllocForTag`
            + `&parm=${headers.userGuid}`
            + `&parm=${context.timezone}`
            + `&parm=${dayjs(context.startDate).format('MM/DD/YYYY')}`
            + `&parm=${dayjs(context.stopDate).format('MM/DD/YYYY')}`
            + `&parm=${selectedTag.tagIdx}`;

        return get(url);
    }

    const handleSubmitDraft = useCallback(debounce(async () => {
        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=dealrizz.UI_TagReport_SubmitDraftTag`
            + `&parm=${headers.userGuid}`
            + `&parm=${id}`

        return get(url).then(response => {
            enqueueSnackbar('Draft tag submitted successfully.', { variant: 'success' });
            logAction(`User submitted draft tag with id ${id}`, 'Tag Report');
        });
    }, buttonDebounce, { leading: true, }), [headers, apiUrlPrefix, id]);

    const handleRemoveDrafts = useCallback(debounce(async () => {
        setConfirmationDialogProps({ open: false });
        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=dealrizz.UI_TagReport_RemoveDraftAdjustment`
            + `&parm=${headers.userGuid}`
            + `&parm=${id}`
            + `&parm=${selectedAdjustment.RequestId}`

        return get(url).then(response => {
            enqueueSnackbar('Draft adjustment removed successfully.', { variant: 'success' });
            logAction(`User removed draft adjustment with requestId ${selectedAdjustment.RequestId} from tag ${id}`, 'Tag Report');
        });
    }, buttonDebounce, { leading: true, }), [headers, apiUrlPrefix, id, selectedAdjustment]);

    function handleConfirmRemoveDraftAdjustment() {
        setConfirmationDialogProps({
            open: true,
            message: `You are about to remove the selected draft adjustment for the selected tag (${id}). Continue?`,
            onConfirmation: handleRemoveDrafts,
            onCancel: () => setConfirmationDialogProps({ open: false }),
        });
    }

    function handleConfirmRemoveDraftTag() {
        setConfirmationDialogProps({
            open: true,
            message: `You are about to remove the selected draft tag (${id}). Continue?`,
            onConfirmation: handleRemoveDraftTag,
            onCancel: () => setConfirmationDialogProps({ open: false }),
        });
    }

    function handleConfirmCloneTag() {
        setConfirmationDialogProps({
            open: true,
            message: `You are about to clone the selected tag (${id}). Continue?`,
            onConfirmation: handleCloneTag,
            onCancel: () => setConfirmationDialogProps({ open: false }),
        });
    }

    const handleRemoveDraftTag = useCallback(debounce(async () => {
        setConfirmationDialogProps({ open: false });
        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=dealrizz.UI_TagReport_RemoveDraftTag`
            + `&parm=${headers.userGuid}`
            + `&parm=${id}`

        return get(url).then(response => {
            const nodesToRemove = [];
            forEachGridNode((node) => {
                if (node.data.tagIdx === id) {
                    nodesToRemove.push(node.data);
                }
            });
            applyGridTransaction({
                remove: nodesToRemove,
            });
            enqueueSnackbar('Draft tag removed successfully.', { variant: 'success' });
            logAction(`User removed draft tag with id ${id}`, 'Tag Report');
        });
    }, buttonDebounce, { leading: true, }), [headers, apiUrlPrefix, id]);

    const handleCloneTag = useCallback(debounce(async () => {
        setConfirmationDialogProps({ open: false });
        logAction(`User cloned tag with id ${id}`, 'Tag Reports', selectedTag);

        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Pushfetch?name=dealrizz.UI_ETag_CloneTag`
            + `&parm=${headers.userGuid}`
            + `&parm=${id}`
            + `&parm=${context.timezone}`

        return post(url, selectedTag).then(response => {
            enqueueSnackbar('Tag cloned successfully. Fetching data for new tag...', { variant: 'success' });
            fetchNewTagData(response.data);
            clearSelected();
        });
    }, buttonDebounce, { leading: true, }), [headers, apiUrlPrefix, id, selectedTag, context]);

    async function fetchNewTagData(tagIdx) {
        const { timezone, startDate, stopDate, onlyShowNonZeroCuts, source, excludeMarketPathPSE } = context;

        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=dealrizz.UI_TagReport_FetchSummary_v2`
            + `&parm=${headers.userGuid}`
            + `&parm=${timezone}`
            + `&parm=${dayjs(startDate).format('MM/DD/YYYY')}`
            + `&parm=${dayjs(stopDate ?? startDate).format('MM/DD/YYYY')}`
            + `&parm=${onlyShowNonZeroCuts ? 1 : 0}`
            + `&parm=${excludeMarketPathPSE ?? ''}`
            + `&parm=${tagIdx}`;

        return get(url).then(response => {
            applyGridTransaction({
                add: response.data,
                addIndex: 0,
            });
        }).catch((error) => {
            enqueueSnackbar(error.message, { variant: 'error' });
        });
    }

    return (
        <Stack direction='row' spacing={1} sx={{ p: 1, display: 'flex', flexWrap: 'wrap', rowGap: '16px', alignItems: 'start', flexDirection: 'row' }}>
            <TagTerminationDialog
                open={openTerminationDialog}
                onTerminate={handleTerminate}
                onCancel={() => setOpenTerminationDialog(false)}
                selectedTag={selectedTag}
            />

            <ConfirmationDialog {...confirmationDialogProps} />

            {/*<RibbonButton
                label="New"
                expanded={expanded}
                //onMouseDown={handleNewTag}
                icon={<AddCircleOutlineIcon
                    fontSize='small'
                />}
            />*/}

            <RibbonButton
                label="Clone"
                expanded={expanded}
                disabled={!enableCopy}
                onMouseDown={handleConfirmCloneTag}
                icon={<ContentCopyIcon
                    fontSize='small'
                />}
            />

            <RibbonButton
                label="Adjust"
                expanded={expanded}
                disabled={!enableAdjust}
                onMouseDown={handleSaveAdjustments}
                icon={<EditIcon
                    fontSize='small'
                />}
            />

            <RibbonButton
                label="Submit Draft"
                expanded={expanded}
                disabled={!allowSubmitDraftTag}
                onMouseDown={handleSubmitDraft}
                icon={<PublishIcon
                    fontSize='small'
                />}
            />

            <RibbonButton
                label="Remove Draft Adjustment"
                expanded={expanded}
                disabled={!allowRemoveDrafts}
                onMouseDown={handleConfirmRemoveDraftAdjustment}
                icon={<DeleteOutlineIcon
                    fontSize='small'
                />}
            />

            <RibbonButton
                label="Remove Draft Tag"
                expanded={expanded}
                disabled={!allowRemoveDraftTag}
                onMouseDown={handleConfirmRemoveDraftTag}
                icon={<DeleteOutlineIcon
                    fontSize='small'
                />}
            />

            <RibbonButton
                label="Withdraw"
                expanded={expanded}
                onClick={handleWithdraw}
                disabled={!enableWithdraw}
                icon={<UndoIcon
                    fontSize='small'
                />}
            />

            <RibbonButton
                label="Correct"
                expanded={expanded}
                disabled={!enableCorrect}
                icon={<CheckIcon
                    fontSize='small'
                />}
            />

            {/*<RibbonButton
                label="Cancel"
                expanded={expanded}
                onClick={handleCancel}
                disabled={!enableCancel}
                icon={<HighlightOffIcon
                    fontSize='small'
                />}
            />*/}

            <RibbonButton
                label="Terminate"
                expanded={expanded}
                onClick={() => setOpenTerminationDialog(true)}
                disabled={!enableTerminate}
                icon={<NotInterestedIcon
                    fontSize='small'
                />}
            />

        </Stack>
    )
};