import { Outlet, useNavigate } from 'react-router-dom';
import { useState, useEffect, } from 'react';
import Collapse from '@mui/material/Collapse';
import Menu from './Menu';
import { Box } from '@mui/material';
import CriteriaProvider from '../CriteriaContext/CriteriaProvider';
import DivGuard from '../Guards/DivGuard';
import { userGroups } from '../../authConfig';
import { TSRActivity } from '../../views';
import { PathBuilder } from './PathBuilder/PathBuilder';
import { LMPs } from './LMPs/LMPs';
import { PathMonitor } from '../PathMonitor/PathMonitor';
import { PositionReportDashboard } from './PositionReport/PositionReportDashboard';
import { TransmissionPositionDashboard } from './TransmissionPosition/TransmissionPositionDashboard';
import { DealSummaryDashboard } from './DealSummary/DealSummaryDashboard';
import { ScheduleSummaryDashboard } from './ScheduleSummary/ScheduleSummaryDashboard';
import { Discrepancies } from './Discrepancies/Discrepancies';
import { useParams } from 'react-router-dom';
import { TagReportDashboard } from './TagReport/TagReportDashboard';
import { TransDealSummaryDashboard } from './TransmissionDealSummary/TransDealSummaryDashboard';
import '../../styles/dealRizzStyles.css';
import { IndexPricingDashboard } from './IndexPricing/IndexPricingDashboard';
import { Forecast } from './Forecast/Forecast';
import { UnscheduledMWsDashboard } from './UnscheduledMWs/UnscheduledMWsDashboard';
import { Scheduling } from './Scheduling/Scheduling';
import { TaggingProvider } from './TagReport/TaggingContext';

const viewStorageKey = 'deal-rizz-saved-view';

export const DealRizz = () => {
  const savedView = localStorage.getItem(viewStorageKey);
  const params = useParams();
  const view = params.view;
  const navigate = useNavigate();

  useEffect(() => {
    if (!view) {
      const newView = savedView ?? 'lmps';
      navigate(`/dealrizz/${newView}`);
    }
  }, [view, savedView]);

  return (
    <DivGuard groups={[userGroups.dealrizz]}>
      <CriteriaProvider>
        <Box sx={{ display: 'flex', }}>
          <Menu view={view} />
          <Outlet />
        </Box>
      </CriteriaProvider>
    </DivGuard>
  )
}

export const DealRizzViews = () => {
  const [loadedViews, setLoadedViews] = useState({});
  const params = useParams();
  const view = params.view;

  useEffect(() => {
    if (view && !loadedViews[view]) {
      setLoadedViews({
        ...loadedViews,
        [view]: true
      })
    }
  }, [view]);

  useEffect(() => {
    if (view) {
      localStorage.setItem(viewStorageKey, view);
    }
  }, [view])

  return (
    <Box
      sx={{
        p: '0px',
        flexGrow: 1,
      }}
    >
      {(loadedViews['pathbuilder']) && <Collapse in={view === 'pathbuilder'}>
        <PathBuilder />
      </Collapse>}
      {loadedViews['tsractivity'] && <Collapse in={view === 'tsractivity'}>
        <TSRActivity visible={view === 'tsractivity'} application='dealrizz' />
      </Collapse>}
      {loadedViews['lmps'] && <Collapse in={view === 'lmps'}>
        <LMPs visible={view === 'lmps'} />
      </Collapse>}
      {loadedViews['transactionqueue'] && <Collapse in={view === 'transactionqueue'}>
        <PathMonitor application={'dealrizz'} />
      </Collapse>}
      {loadedViews['positionreport'] && <Collapse in={view === 'positionreport'}>
        <PositionReportDashboard />
      </Collapse>}
      {loadedViews['transmissionposition'] && <Collapse in={view === 'transmissionposition'}>
        <TransmissionPositionDashboard />
      </Collapse>}
      {loadedViews['unscheduledmws'] && <Collapse in={view === 'unscheduledmws'}>
        <UnscheduledMWsDashboard />
      </Collapse>}
      {loadedViews['dealsummary'] && <Collapse in={view === 'dealsummary'}>
        <DealSummaryDashboard />
      </Collapse>}
      {loadedViews['schedulesummary'] && <Collapse in={view === 'schedulesummary'}>
        <ScheduleSummaryDashboard />
      </Collapse>}
      {loadedViews['indexdailyprice'] && <Collapse in={view === 'indexdailyprice'}>
        <IndexPricingDashboard />
      </Collapse>}
      {loadedViews['transmissiondealsummary'] && <Collapse in={view === 'transmissiondealsummary'}>
        <TransDealSummaryDashboard />
      </Collapse>}
      {loadedViews['discrepancies'] && <Collapse in={view === 'discrepancies'}>
        <Discrepancies />
      </Collapse>}
      {loadedViews['tagreport'] && <Collapse in={view === 'tagreport'}>
        <TaggingProvider>
          <TagReportDashboard />
        </TaggingProvider>
      </Collapse>}
      {loadedViews['forecast'] && <Collapse in={view === 'forecast'}>
        <Forecast />
      </Collapse>}
      {loadedViews['scheduling'] && <Collapse in={view === 'scheduling'}>
        <Scheduling />
      </Collapse>}
    </Box>
  )
}

export const viewTitles = {
  pathbuilder: 'Path Builder',
  tsractivity: 'TSR Activity',
  lmps: 'LMPs',
  transactionqueue: 'Transaction Queue',
  dealentry: 'Deal Entry',
  positionreport: 'Deal Positions',
  transmissionposition: 'Trans Position',
  unscheduledmws: 'Unscheduled MWs',
  dealsummary: 'Deal Summary',
  schedulesummary: 'Schedule Summary',
  indexdailyprice: 'Index Daily Price',
  transmissiondealsummary: 'Transmission Deal Summary',
  discrepancies: 'Discrepancies',
  tagreport: 'Tagging',
  forecast: 'Forecast',
  scheduling: 'Scheduling',
};