export const columnPanel = {
  id: 'columns',
  labelDefault: 'Columns',
  labelKey: 'columns',
  iconKey: 'columns',
  toolPanel: 'agColumnsToolPanel',
  minWidth: 225,
  maxWidth: 225,
  width: 225
};

export const filterPanel = {
  id: 'filters',
  labelDefault: 'Filters',
  labelKey: 'filters',
  iconKey: 'filter',
  toolPanel: 'agFiltersToolPanel',
  minWidth: 180,
  maxWidth: 400,
  width: 250
};

export const palettePanel = (gridRef, paletteRef) => ({
  id: 'palette',
  labelDefault: 'Palette',
  labelKey: 'palette',
  iconKey: 'palette',
  toolPanel: 'paletteToolPanel',
  toolPanelParams: {
    gridRef: gridRef,
    paletteRef: paletteRef,
  },
  minWidth: 180,
  maxWidth: 300,
  width: 220
});