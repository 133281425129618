import { useRef, useMemo, useState, useCallback, forwardRef, useImperativeHandle } from "react"
import { Box } from "@mui/material"
import { AgGridContainer } from "../../AgGrid/AgGridContainer"
import { AgGridReact } from "ag-grid-react"
import { defaultColumnDef, defaultStatusBar } from "../../AgGrid/defaultGridProps"
import { useColumnTypes } from "../../AgGrid/useColumnTypes"
import { columnPanel, filterPanel, palettePanel, } from "../../ToolPanels/DefaultToolPanels"
import { LayoutToolPanel } from "../../ToolPanels/LayoutToolPanel"
import { apiUrlPrefix } from "../../../authConfig"
import { useSnackbar } from "notistack"
import useGridLayout from "../../useGridLayout"
import axios from "axios"
import useHeader from "../../useHeader"
import dayjs from "dayjs"
import { useActionAudit } from "../../useActionAudit"
import { Ribbon, GridActionsGroup, } from "../Ribbon"
import { FilterCriteriaFormGroup } from "./FilterCriteriaFormGroup"
import { handleUpdateTotalRows, jsonOrCommaSeparatedFormatter } from "../Utils"
import { heatmapStyle, tooltipRenderer, redPastMarkerFormatter } from "../Utils"
import { PaletteToolPanel } from "../../ToolPanels/PalettePanel"
import { useGridCrossHighlight } from "../useGridCrossHighlight"

export const PositionReportView = forwardRef(({ view, }, ref) => {
    const layoutStorageKey = `deal-rizz-position-report-grid-layout-${view.id}`;
    const gridRef = useRef();
    const { columnTypes } = useColumnTypes();
    const [rowData, setRowData] = useState();
    const headers = useHeader();
    const { enqueueSnackbar } = useSnackbar();
    const { logAction } = useActionAudit();
    const [date, setDate] = useState(dayjs().format('MM/DD/YYYY'));
    const paletteRef = useRef({ showHeatmap: false });
    const { highlightingCellClassRules, clearHighlighting, handleCellMouseOverHighlight, } = useGridCrossHighlight(gridRef);

    const fetchData = useCallback(async (data) => {
        logAction('User fetched Position Report Data', 'Position Report', data);
        const { timezone, date, } = data;
        setDate(date);
        gridRef.current?.api?.showLoadingOverlay();

        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=dealrizz.UI_fetchDealPositionReport`
            + `&parm=${headers.userGuid}`
            + `&parm=${dayjs(date).format('MM/DD/YYYY')}`
            + `&parm=${timezone}`

        return axios.get(url, { headers: headers }).then(response => {
            setRowData(response?.data ?? []);
        }).catch((error) => {
            enqueueSnackbar(error.message, { variant: 'error' });
            setRowData([]);
        }).finally(() => {
            gridRef.current?.api?.hideOverlay();
        });
    }, [headers]);

    const baseColDefs = useMemo(() => ([
        { field: 'dealID', headerName: 'Deal ID', cellDataType: 'text' },
        { field: 'Deal_Number', headerName: 'Old Deal ID', },
        { field: 'Deal_Currency', headerName: 'Deal Currency', },
        {
            field: 'Book',
            valueFormatter: (params) => {
                return jsonOrCommaSeparatedFormatter(params.value);
            },
        },
        { field: 'Contract', },
        { field: 'Status', },
        { field: 'Counterparty', },
        { field: 'Market', },
        { field: 'Transaction_Type', headerName: 'Transaction Type', },
        { field: 'index_name', headerName: 'Index', },
        {
            headerName: 'Deal Position',
            cellRenderer: 'agSparklineCellRenderer',
            sortable: false,
            flex: 3,
            valueGetter: (params) => {
                const { data } = params;
                if (!data) return [];
                return [...Array.from({ length: 26 }).keys()].reduce((acc, next) => {
                    const hour = next + 1;

                    acc.push(
                        { x: dayjs(date).startOf('day').add(hour - 1, 'hours').toDate(), y: data[hour] ?? 0 },
                        { x: dayjs(date).startOf('day').add(hour, 'hours').toDate(), y: data[hour] ?? 0 },
                    );
                    return acc;
                }, []);
            },
            cellRendererParams: {
                sparklineOptions: {
                    type: 'area',
                    axis: {
                        type: 'time',
                    },
                    tooltip: {
                        renderer: tooltipRenderer
                    },
                    marker: {
                        formatter: redPastMarkerFormatter,
                    },
                },
            },
        },
        ...Array.from({ length: 26 }, (_, i) => ({
            headerName: `${i + 1}`,
            field: `${i + 1}`,
            enableRowGroup: false,
            aggFunc: 'sum',
            minWidth: 55,
            chartDataType: 'series',
            type: 'numericColumn',
            cellDataType: 'number',
            cellStyle: params => heatmapStyle(params, paletteRef),
        })),
    ]), []);

    const defaultColDef = useMemo(() => ({
        ...defaultColumnDef,
        editable: false,
        enableRowGroup: true,
        minWidth: 100,
        flex: 1,
    }), []);

    const { colDefs, layoutPanel, loadLayout, applySavedFilters, applyFilters, applyLayout, captureLayout } = useGridLayout(layoutStorageKey, gridRef, baseColDefs, defaultColDef);

    const styledColDefs = useMemo(() => colDefs.map(colDef => ({
        ...colDef,
        cellClassRules: highlightingCellClassRules,
    })), [colDefs, highlightingCellClassRules]);

    const sideBar = useMemo(() => ({
        toolPanels: [
            columnPanel,
            filterPanel,
            layoutPanel,
            palettePanel(gridRef, paletteRef, 'position-report-palette'),
        ]
    }), []);

    useImperativeHandle(ref, () => {
        return {
            captureLayout,
        };
    });

    function onGridReady(params) {
        if (view?.layout && !localStorage.getItem(layoutStorageKey)) {
            applyLayout(view.layout);
        } else {
            loadLayout();
        }
    }

    const handleFirstDataRendered = useCallback(() => {
        if (view?.layout?.filters && !localStorage.getItem(layoutStorageKey)) {
            applyFilters(view.layout.filters);
        } else if (localStorage.getItem(layoutStorageKey)) {
            applySavedFilters();
        }
    }, []);

    const toolbarFormId = `deal-rizz-position-report-toolbar-${view.id}`;

    const updateTotalRows = useCallback(({ api, }) => {
        handleUpdateTotalRows(api, 'dealID');
    }, []);

    const gridOptions = useMemo(() => ({
        rowClassRules: {
            "ag-custom-total-row": params => !!params.node.rowPinned,
        },
    }), []);

    return (
        <Box sx={{ height: '90vh', display: 'flex', flexDirection: 'column' }}>
            <Ribbon>
                {[
                    { title: 'Grid', content: <GridActionsGroup formId={toolbarFormId} /> },
                    { title: 'Filter', content: <FilterCriteriaFormGroup view={view} onSubmit={fetchData} formId={toolbarFormId} /> },
                ]}
            </Ribbon>
            <AgGridContainer style={{ display: 'flex', flex: 1, width: '100%' }} onMouseLeave={clearHighlighting}>
                <AgGridReact
                    containerStyle={{ display: 'flex', flexDirection: 'column', flex: 1, width: '100%', }}
                    rowData={rowData}
                    ref={gridRef}
                    gridOptions={gridOptions}
                    enableCharts
                    columnDefs={styledColDefs}
                    onFilterChanged={updateTotalRows}
                    onRowDataUpdated={updateTotalRows}
                    enableRangeSelection
                    onFirstDataRendered={handleFirstDataRendered}
                    onCellMouseOver={handleCellMouseOverHighlight}
                    enableFillHandle
                    groupTotalRow={"bottom"}
                    suppressAggFuncInHeader={true}
                    stopEditingWhenCellsLoseFocus
                    singleClickEdit
                    onGridReady={onGridReady}
                    columnTypes={columnTypes}
                    statusBar={defaultStatusBar}
                    sideBar={sideBar}
                    components={{
                        layoutToolPanel: LayoutToolPanel,
                        paletteToolPanel: PaletteToolPanel,
                    }}
                />
            </AgGridContainer>
        </Box>
    )
});