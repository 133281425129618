import { Box, useTheme, Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { AgGridContainer } from "../../../AgGrid/AgGridContainer";
import { ProfileChart } from "./ProfileChart";
import { ProfileGrid } from "./ProfileGrid";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ProfileCreationForm } from "./ProfileCreationForm";
import { useRef, } from 'react';

export default (props) => {
    const { disabled = false, profile, setProfile, } = props;
    const chartRef = useRef();

    function refreshChart() {
        chartRef.current?.refresh();
    }

    return (
        <Box sx={{ p: 1, height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Accordion
                defaultExpanded
                disableGutters
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Typography>Profile Chart</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ p: 0 }}>
                    <Box sx={{ position: 'relative', height: 200, }}>
                        <ProfileChart ref={chartRef} profileData={profile} />
                    </Box>
                </AccordionDetails>
            </Accordion>
            <ProfileCreationForm
                disabled={disabled}
                setProfile={setProfile}
            />
            <AgGridContainer style={{ flexGrow: 1, }} >
                <ProfileGrid refreshChart={refreshChart} data={profile} />
            </AgGridContainer>
        </Box >
    )
}