import { Box, ListItem, ListItemText, TextField, Typography } from "@mui/material";
import FormAutocomplete from "../../FormControls/FormAutocomplete";
import { useState, useMemo, useRef } from "react";
import { useFormContext } from "react-hook-form";

export const PercentageDropdown = ({ lookupOptions, name, renderInput, }) => {
    const { getValues, setValue, control, } = useFormContext();
    const inputRefs = useRef({});

    const [openDropdown, setOpenDropdown] = useState(false);
    const defaultPercentages = useMemo(() => {
        const defaults = getValues(name) ?? [];
        return new Map(defaults.map(def => [def.label, def.percentage ?? 0]));
    }, []);
    const [percentages, setPercentages] = useState(defaultPercentages);

    const options = useMemo(() => {
        const baseOptionLabels = lookupOptions[name] ?? [];
        const defaultPercentage = calculateDefaultPercentage(percentages);
        return baseOptionLabels.map(label => ({
            label: label,
            percentage: percentages.get(label) ?? defaultPercentage,
        }));
    }, [percentages, lookupOptions]);

    function calculateDefaultPercentage(percentages) {
        if (percentages.size === 0) { return 0; }

        const totalPercentage = [...percentages.values()].reduce((acc, val) => acc + parseInt(val), 0);
        //if total percentage is greater than 100, return 0; or if it is NaN
        if (totalPercentage > 100 || isNaN(totalPercentage)) { return 0; }

        const remainingPercentage = 100 - totalPercentage;
        return remainingPercentage;
    }

    function updatePercentage(label, percentage) {
        const newPercentages = new Map(percentages);
        newPercentages.set(label, percentage);
        setPercentages(newPercentages);
    }

    function handleUpdateValues(newOptions) {
        //if we already have a value with the selected label, overwrite the percentage
        const uniqueValsMap = newOptions.reduce((acc, val) => {
            acc.set(val.label, val);
            return acc;
        }, new Map())
        const uniqueVals = [...uniqueValsMap.values()];
        setValue(name, uniqueVals, { shouldTouch: true, shouldDirty: true });
    }

    return (
        <FormAutocomplete
            name={name}
            multiple
            autoSelect={false}
            control={control}
            options={options}
            isOptionEqualToValue={(option, value) => option.label === value.label}
            getOptionLabel={(option) => `${option.label} - ${option.percentage ?? 0}%`}
            getOptionKey={(option) => option.label}
            onChange={(event, value) => {
                handleUpdateValues(value);
            }}
            open={openDropdown}
            onOpen={() => setOpenDropdown(true)}
            onClose={(event, reason) => {
                //don't close the dropdown if one of the percentage inputs is focused
                const wasPercentageInputFocused = event.relatedTarget?.id?.startsWith('percentage-');
                //blur is fired when focusing the percentage input, we want to keep the dropdown open in that case
                if (reason !== 'blur' || !wasPercentageInputFocused) {
                    setOpenDropdown(false);
                }
            }}
            renderInput={renderInput}
            renderOption={(props, option) => {
                return (
                    <Box
                        sx={{ display: 'flex', justifyContent: 'space-between' }}
                        key={option.label}
                    >
                        <ListItem sx={{ flexGrow: 1 }} {...props} >
                            <ListItemText>{option.label}</ListItemText>
                        </ListItem>
                        <TextField
                            size='small'
                            sx={{ width: 100, paddingRight: 1, }}
                            defaultValue={option.percentage}
                            id={`percentage-${option.label}-input`}
                            ref={el => inputRefs.current[option.label] = el}
                            onChange={(event) => {
                                updatePercentage(option.label, event.target.value);
                            }}
                            InputProps={{
                                onMouseDown: event => event.stopPropagation(),
                                onClick: event => event.stopPropagation(),
                                endAdornment: (
                                    <Typography color='textSecondary' variant='caption'>
                                        %
                                    </Typography>
                                ),
                            }}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    event.preventDefault();
                                    handleUpdateValues([...getValues(name), { label: option.label, percentage: percentages.get(option.label) }]);
                                    setOpenDropdown(false);
                                }
                            }}
                        />
                    </Box>
                );
            }}
        />
    );
}