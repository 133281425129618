import dayjs from '../../../dayjs-tz';

export const useProfileTemplates = (formatString = 'MM/DD/YYYY HH:mm') => {
    function generateTransmissionBlocksUsingTemplate(config) {
        const template = profileOptions.find(option => option.name === config.Term)?.templateBlocks;
        if (template) {
            return template(config);
        } else {
            return [];
        }
    }

    const profileOptions = [
        {
            name: 'ATC',
            tooltip: 'Adds blocks covering all hours in the selected date range.',
            templateBlocks: atcBlocks,
        },
        {
            name: 'Hourly',
            tooltip: 'Adds a block for each hour in the selected date range.',
            templateBlocks: hourlyBlocks,
        },
        {
            name: 'Term',
            tooltip: '',
        },
        /*{
            name: '1x8',
            tooltip: 'Adds blocks for off peak hours in the selected date range.',
        },*/
        {
            name: '6x8, 1x24',
            tooltip: 'LL hours including Sunday',
            templateBlocks: llhBlocks,
        },
        {
            name: '1x16',
            tooltip: 'Adds blocks for on peak hours in the selected date range.',
            templateBlocks: onPeakBlocks,
        },
        {
            name: '6x16',
            tooltip: 'HL hours excluding Sunday',
            templateBlocks: hlhBlocks,
        },
        {
            name: '7x16',
            tooltip: 'HL hours including Sunday',
            templateBlocks: onPeakBlocks,
        },
    ];

    const isOffPeak = date => date.hour() < 6 || date.hour() >= 22;
    const isOnPeak = date => date.hour() >= 6 && date.hour() < 22;
    const isSunday = date => date.day() === 0;

    const createHourlyBlock = (start, end, config) => {
        const { mwOn, mwOff, priceOn, priceOff, } = config;
        const offPeak = isOffPeak(start);
        const mw = offPeak ? mwOff : mwOn;
        const price = offPeak ? priceOff : priceOn;

        return {
            startDateTime: start.format(formatString),
            endDateTime: end.format(formatString),
            capacityRequested: mw,
            price: price,
        }
    }

    function generateBlocks(config, selector, getBlockSignature = defaultBlockSignature) {
        const { startDateTime, stopDateTime, } = config;
        const blocks = [];
        let next = dayjs(startDateTime).startOf('hour');
        while (dayjs(stopDateTime).isAfter(next, 'hour')) {
            if (selector(next)) {
                blocks.push(createHourlyBlock(next, next.add(1, 'hour'), config));
            }
            next = next.add(1, 'hour');
        }
        return rollUpBlocks(blocks, getBlockSignature);
    }

    function atcBlocks(config) {
        const selector = () => true;
        const getAtcBlockSignature = (block) => `${block.capacityRequested}-${isOffPeak(dayjs(block.startDateTime))}`;
        return generateBlocks(config, selector, getAtcBlockSignature);
    }

    function hourlyBlocks(config) {
        const blocks = [];
        let next = dayjs(config.startDateTime);
        const end = dayjs(config.stopDateTime);
        while (end.isAfter(next, 'hour')) {
            blocks.push(createHourlyBlock(next, next.add(1, 'hour'), config));
            next = dayjs(next).add(1, 'hour');
        }
        return blocks;
    }

    function llhBlocks(config) {
        const selector = (date) => {
            return isOffPeak(date);
        }
        return generateBlocks(config, selector);
    }

    function offPeakBlocks(config) {
        return generateBlocks(config, isOffPeak);
    }

    function hlhBlocks(config) {
        const selector = (date) => {
            return (!isSunday(date) && isOnPeak(date))
        }
        return generateBlocks(config, selector);
    }

    function onPeakBlocks(config) {
        return generateBlocks(config, isOnPeak);
    }

    function rollUpBlocks(blocks, getBlockSignature = defaultBlockSignature) {
        return blocks.reduce((rolledUp, next) => {
            const last = rolledUp[rolledUp.length - 1];
            if (last && (last.endDateTime === next.startDateTime) && (getBlockSignature(last) === getBlockSignature(next))) {
                last.endDateTime = next.endDateTime;
            } else {
                rolledUp.push(next);
            }
            return rolledUp;
        }, [])
    }

    const defaultBlockSignature = (block) => `${block.capacityRequested}-${block.price}`;

    return {
        generateTransmissionBlocksUsingTemplate,
        profileOptions,
    }
}