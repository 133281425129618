import { useMemo } from 'react';
import { Box } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';
import { AgGridContainer } from '../AgGrid/AgGridContainer';

export default (props) => {
  const { data, node, api, } = props;

  const rowData = useMemo(() => {
    const detailData = JSON.parse(data.detailJson);
    return detailData;
  }, [data])

  function tooltipRenderer(params) {
    const { xValue, yValue } = params;
    const tzDate = moment(xValue).format('MM/DD/YY HH:mm');
    return {
      title: tzDate,
      content: yValue + params.context.data.minCapacity
    }
  }

  const palette = {
    blue: 'rgb(20,94,140)',
    lightBlue: 'rgb(182,219,242)',
    green: 'rgb(63,141,119)',
    lightGreen: 'rgba(75,168,142, 0.2)',
    pastelRed: 'rgb(255,102,102)',
  };

  const columnFormatter = (params) => {
    const { yValue, highlighted } = params;
    if (highlighted) {
      return;
    }
    return { fill: yValue < 0 ? palette.lightBlue : palette.blue };
  };

  function priceFormatter(params) {
    return `${new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD"
    }).format(params.value)}`
  }

  const markerFormatter = (params) => {
    const { min, highlighted } = params;
    return {
      size: highlighted ? 3 : 1,
      enabled: true,
      fill: params.yValue <= 0 ? palette.pastelRed : palette.green,
      stroke: params.yValue <= 0 ? palette.pastelRed : palette.green,
    };
  };

  const colDefs = useMemo(() => [
    {
      headerName: 'Leg',
      field: 'leg',
      initialWidth: 80,
    }, {
      headerName: 'TP',
      initialHide: true,
      field: 'TP',
      initialWidth: 80,
    },
    {
      headerName: 'POR',
      field: 'PointOfReceipt',
      initialHide: true,
      initialWidth: 100,
    },
    {
      headerName: 'POD',
      field: 'PointOfDelivery',
      initialHide: true,
      initialWidth: 100,
    },
    {
      headerName: 'Path Name',
      field: 'PathName',
      initialWidth: 220,
    },
    {
      headerName: 'Posting Ref',
      initialHide: true,
      field: 'PostingRef',
      initialWidth: 100,
    },
    {
      headerName: 'Availability',
      field: 'Availability',
      initialWidth: 100,
    },
    {
      //field: "atc",
      headerName: "ATC Future",
      cellRenderer: 'agSparklineCellRenderer',
      //equals: _.isEqual,
      initialHide: false,
      minWidth: 400,
      //flex: 1,
      valueGetter: (params) => {
        if (params.data.atcFuture) {
          const atcData = params.data.atcFuture.map(xyObj => ({
            x: moment(moment.parseZone(xyObj.StopTime).format('MM/DD/YYYY HH:mm')).toDate(),
            y: parseInt(xyObj.yValue),
          }));
          return atcData;
        } else {
          return [];
        }
      },
      cellRendererParams: {
        sparklineOptions: {
          type: 'area',
          axis: {
            type: 'time',
          },
          tooltip: {
            renderer: tooltipRenderer
          },
          marker: {
            formatter: markerFormatter,
          }
        },
      },
    },
    {
      headerName: 'Ceiling Price',
      initialHide: true,
      field: 'CeilingPrice',
      initialWidth: 100,
    },
    {
      headerName: 'Offer Price',
      field: 'OfferPrice',
      initialWidth: 100,
    },
    {
      headerName: 'Seller Code',
      initialHide: true,
      field: 'SellerCode',
      initialWidth: 100,
    },
    {
      headerName: 'Start Time',
      field: 'StartTimeUtc',
      initialHide: false,
      initialWidth: 120,
      valueFormatter: params => params.value ? moment(params.value).format('MM/DD/YYYY  HH:mm') : ''
    },
    {
      headerName: 'Stop Time',
      field: 'StopTimeUtc',
      initialHide: false,
      initialWidth: 120,
      valueFormatter: params => params.value ? moment(params.value).format('MM/DD/YYYY  HH:mm') : ''
    },
    {
      headerName: 'Time of Last Update',
      field: 'TimeOfLastUpdateUtc',
      initialHide: true,
      initialWidth: 120,
      valueFormatter: params => params.value ? moment(params.value).format('MM/DD/YYYY  HH:mm') : ''
    },
    {
      headerName: 'Increment',
      field: 'ServiceIncrement',
      initialWidth: 100,
    },
    {
      headerName: 'TS Class',
      field: 'TSClass',
      initialWidth: 100,
    },
    {
      headerName: 'TS Period',
      field: 'TSPeriod',
      initialWidth: 100,
    },
    {
      headerName: 'TS Subclass',
      field: 'TSSubclass',
      initialWidth: 100,
    },
    {
      headerName: 'TS Type',
      field: 'TSType',
      initialWidth: 120,
    },
    {
      headerName: 'TS Window',
      field: 'TSWindow',
      initialWidth: 100,
    },
  ], [])

  const defaultColDef = useMemo(() => ({
    editable: false,
    resizable: true,
  }), [])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', p: 1, }}>
      <AgGridContainer
        style={{ width: "100%", fontSize: '11px' }}
      >
        <AgGridReact
          columnDefs={colDefs}
          rowData={rowData}
          defaultColDef={defaultColDef}
          domLayout={'autoHeight'}
        />
      </AgGridContainer>
    </Box>
  );
}