import CloseIcon from '@mui/icons-material/Close';
import moment from "moment";
import { Typography, IconButton, FormControl, FormControlLabel, Checkbox, Button, Tooltip, Dialog, DialogContent, DialogActions, Box, DialogTitle, Grid, Autocomplete, TextField, } from "@mui/material";
import useFilteredCriteria from '../CriteriaContext/useFilteredCriteria';
import { useSnackbar } from "notistack";
import { useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import { useTheme } from '@mui/styles';
import dayjs from '../dayjs-tz'

export default (props) => {
  const { handleFetch, open, handleClose, } = props;
  const { pors, pods, providers, filteredTSCombos, } = useFilteredCriteria({});
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  const defaultCriteria = {
    legs: 4,
    por: ['CSWS'],
    pod: ['AECI'],
    firmNonFirm: 'NFATC',
    minCapacity: 100,
    hoursOut: 6,
    endHour: 1,
    timezone: 'Pacific Standard Time',
    tsClass: ['NON-FIRM'],
    tsIncrement: ['HOURLY'],
    tsType: ['POINT_TO_POINT'],
    tsPeriod: ['FULL_PERIOD'],
    tsWindow: ['FIXED'],
  }

  const criteriaStorageKey = 'powerfolio-path-criteria';
  const storedCriteria = JSON.parse(localStorage.getItem(criteriaStorageKey) ?? '{}');
  const [criteria, setCriteria] = useState({
    ...defaultCriteria,
    ...storedCriteria,
    startDate: dayjs().add(1, 'hour'),
    stopDate: dayjs().add(1, 'hour'),
  });

  function handleChange(key, value) {
    const newCriteria = {
      ...criteria,
      [key]: value,
    }

    if (!['startDate', 'stopDate'].includes(key)) {
      localStorage.setItem(criteriaStorageKey, JSON.stringify(newCriteria));
    }

    setCriteria(newCriteria);
  }

  const renderTimezones = {
    'Pacific': 'Pacific Standard Time',
    'Pacific Standard Time': 'Pacific',
    'Eastern': 'Eastern Standard Time',
    'Eastern Standard Time': 'Eastern',
    'Central': 'Central Standard Time',
    'Central Standard Time': 'Central',
    'Mountain': 'Mountain Standard Time',
    'Mountain Standard Time': 'Mountain',
  }

  function handleFetchClick() {
    if (validateCriteria()) {
      handleFetch(criteria);
    }
  }

  function validateCriteria() {
    if (!moment(criteria.startDate).isValid() || dateIsBeforeToday(criteria.startDate)) {
      enqueueSnackbar('Please choose a valid date not in the past.');
      return false;
    }
    return true;
  }

  function dateIsBeforeToday(date) {
    return moment(date).isBefore(moment())
  }

  function handleWildcardUpdate(key, value) {
    const [lastAdded] = value?.slice(-1);
    if (lastAdded === '*') {
      handleChange(key, ['*'])
    } else {
      handleChange(key, value.filter(option => option !== '*'))
    }
  }

  const maxLegOptions = [...Array(10).keys()].map(i => i + 1)

  return (
    <Dialog maxWidth={'lg'} open={open}>
      <DialogTitle>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography>Path Search Criteria</Typography>
          <Box sx={{ flexGrow: 1, }} />
          <IconButton onClick={handleClose} size='large'>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', p: 1 }}>
          <Grid container direction={'column'}>
            <Grid container justifyContent="center" style={{ marginTop: '20px' }} spacing={2}>
              <Grid item xs={2}>
                <Autocomplete
                  fullWidth
                  autoComplete
                  autoSelect
                  options={['Pacific', 'Eastern', 'Central', 'Mountain']}
                  value={renderTimezones[criteria.timezone] ?? ''}
                  onChange={(_, newValue) => {
                    handleChange('timezone', renderTimezones[newValue]);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Timezone"
                      size='small'
                      color="success"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={2}>
                <Tooltip title="The maximum number of legs in a path." arrow placement="top">
                  <Autocomplete
                    fullWidth
                    autoComplete
                    autoSelect
                    options={maxLegOptions}
                    value={criteria.legs?.toString()}
                    onChange={(_, newValue) => {
                      handleChange('legs', newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        sx={{ '& p': { color: 'orangered', } }}
                        variant="outlined"
                        label="Max Legs"
                        color="success"
                        helperText={criteria.legs > 3 ? 'Load times may be slower when including large numbers of legs.' : ''}
                      />
                    )}
                  />
                </Tooltip>
              </Grid>
              <Grid item xs={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    value={criteria.startDate}
                    onChange={(newValue) => handleChange('startDate', newValue)}
                    ampm={false}
                    slotProps={{
                      textField: {
                        size: 'small',
                        label: 'Start Date',
                        //error: !!errors.startDate,
                        //helperText: errors.startDate?.message,
                        fullWidth: true,
                      },
                      layout: {
                        sx: {
                          ul: {
                            '::-webkit-scrollbar': {
                              width: '10px',
                            },
                            '::-webkit-scrollbar-thumb': {
                              backgroundColor: theme.palette.primary.dark,
                              borderRadius: '20px',
                              border: '3px solid transparent',
                              backgroundClip: 'padding-box',
                            },
                            '::-webkit-scrollbar-track': {
                              background: 'transparent',
                            },
                            '::-webkit-scrollbar-thumb:hover': {
                              border: 0,
                            },
                          },
                        },
                      }
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    value={criteria.stopDate}
                    onChange={(newValue) => handleChange('stopDate', newValue)}
                    ampm={false}
                    slotProps={{
                      textField: {
                        size: 'small',
                        label: 'Stop Date',
                        //error: !!errors.stopDate,
                        //helperText: errors.stopDate?.message,
                        fullWidth: true,
                      },
                      layout: {
                        sx: {
                          ul: {
                            '::-webkit-scrollbar': {
                              width: '10px',
                            },
                            '::-webkit-scrollbar-thumb': {
                              backgroundColor: theme.palette.primary.dark,
                              borderRadius: '20px',
                              border: '3px solid transparent',
                              backgroundClip: 'padding-box',
                            },
                            '::-webkit-scrollbar-track': {
                              background: 'transparent',
                            },
                            '::-webkit-scrollbar-thumb:hover': {
                              border: 0,
                            },
                          },
                        },
                      }
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={6}>
                <Tooltip title="Choose a Point of Receipt." arrow placement="top">
                  <Autocomplete
                    fullWidth
                    autoComplete
                    autoSelect
                    multiple
                    options={pors ?? []}
                    value={criteria.por ?? []}
                    onChange={(_, newValue) => {
                      handleChange('por', newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        label="POR"
                        color="success"
                      //onKeyDown={handleKeyPress}
                      />
                    )}
                  />
                </Tooltip>
              </Grid>
              <Grid item xs={6}>
                <Tooltip title="Choose a Point of Delivery." arrow placement="top">
                  <Autocomplete
                    fullWidth
                    autoComplete
                    autoSelect
                    multiple
                    options={pods ?? []}
                    value={criteria.pod ?? []}
                    onChange={(_, newValue) => {
                      handleChange('pod', newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        label="POD"
                        color="success"
                      />
                    )}
                  />
                </Tooltip>
              </Grid>
              <Grid item xs={6}>
                <Tooltip title="Select * to include any TS Class" arrow placement="top">
                  <Autocomplete
                    fullWidth
                    multiple
                    options={['*', ...filteredTSCombos.tsClasses]}
                    value={criteria.tsClass}
                    onChange={(_, newValue) => handleWildcardUpdate('tsClass', newValue)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        label="TS Class"
                        color="success"
                      />
                    )}
                  />
                </Tooltip>
              </Grid>
              <Grid item xs={6}>
                <Tooltip title="Select * to include any TS Increment" arrow placement="top">
                  <Autocomplete
                    fullWidth
                    multiple
                    options={['*', ...filteredTSCombos.serviceIncrements]}
                    value={criteria.tsIncrement}
                    onChange={(_, newValue) => handleWildcardUpdate('tsIncrement', newValue)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        label="TS Increment"
                        color="success"
                      />
                    )}
                  />
                </Tooltip>
              </Grid>
              <Grid item xs={6}>
                <Tooltip title="Select * to include any TS Type" arrow placement="top">
                  <Autocomplete
                    fullWidth
                    multiple
                    value={criteria.tsType}
                    options={['*', ...filteredTSCombos.tsTypes]}
                    onChange={(_, newValue) => handleWildcardUpdate('tsType', newValue)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        label="TS Type"
                        color="success"
                      />
                    )}
                  />
                </Tooltip>
              </Grid>
              <Grid item xs={6}>
                <Tooltip title="Select * to include any TS Period" arrow placement="top">
                  <Autocomplete
                    fullWidth
                    multiple
                    options={['*', ...filteredTSCombos.tsPeriods]}
                    value={criteria.tsPeriod}
                    onChange={(_, newValue) => handleWildcardUpdate('tsPeriod', newValue)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        label="TS Period"
                        color="success"
                      />
                    )}
                  />
                </Tooltip>
              </Grid>
              <Grid item xs={6}>
                <Tooltip title="Select * to include any TS Window" arrow placement="top">
                  <Autocomplete
                    fullWidth
                    multiple
                    options={['*', ...filteredTSCombos.tsWindows]}
                    value={criteria.tsWindow}
                    onChange={(_, newValue) => handleWildcardUpdate('tsWindow', newValue)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        label="TS Window"
                        color="success"
                      />
                    )}
                  />
                </Tooltip>
              </Grid>
              <Grid item xs={6}>
                <Tooltip title="Select * to include any TS Subclass" arrow placement="top">
                  <Autocomplete
                    fullWidth
                    multiple
                    options={['*', ...filteredTSCombos.tsSubclasses]}
                    value={criteria.tsSubclass}
                    onChange={(_, newValue) => handleWildcardUpdate('tsSubclass', newValue)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        label="TS Subclass"
                        color="success"
                      />
                    )}
                  />
                </Tooltip>
              </Grid>
              <Grid item xs={6}>
                <Tooltip title="Selected providers will not appear in results." arrow placement="top">
                  <Autocomplete
                    fullWidth
                    multiple
                    options={providers ?? []}
                    value={criteria.excludeTp ?? []}
                    onChange={(_, newValue) => {
                      handleChange('excludeTp', newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size='small'
                        label="Excluded Providers"
                        color="success"
                      />
                    )}
                  />
                </Tooltip>
              </Grid>
              <Grid item xs={6}>
                <Tooltip title="Selected points will not appear in results." arrow placement="top">
                  <Autocomplete
                    fullWidth
                    multiple
                    options={pors ?? []}
                    value={criteria.excludePoints ?? []}
                    onChange={(_, newValue) => {
                      handleChange('excludePoints', newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size='small'
                        label="Excluded Points"
                        color="success"
                      />
                    )}
                  />
                </Tooltip>
              </Grid>
              <Grid item xs={6}>
                <Tooltip title="The minimum available MW across all legs." arrow placement="top">
                  <TextField
                    sx={{ '& p': { color: 'orangered', } }}
                    variant="outlined"
                    size='small'
                    fullWidth
                    label="Min Capacity"
                    color="success"
                    value={criteria.minCapacity}
                    onChange={e => handleChange('minCapacity', e.target.value)}
                    helperText={criteria.minCapacity < 50 ? 'Load times may be slower with a low min capacity.' : ''}
                  />
                </Tooltip>
              </Grid>
              <Grid item xs={6}>
                <Tooltip title='If selected, results will only contain trans with the minimum capacity available across the entire profile.' arrow placement='top'>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={criteria.hardLimit}
                        onClick={() => {
                          handleChange('hardLimit', !criteria.hardLimit);
                        }}
                      />
                    }
                    label="Hard Limit"
                  />
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={handleClose}>Cancel</Button>
        <Box sx={{ flexGrow: 1, }} />
        <Button variant="contained" color="primary" onClick={handleFetchClick}>Fetch Routes</Button>
      </DialogActions>
    </Dialog>
  )
}