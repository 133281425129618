import { Dialog, DialogTitle, Alert, DialogContent, DialogContentText, DialogActions, Button, Box, AlertTitle, } from '@mui/material'

export default (props) => {
  const { open, message, onConfirmation, onCancel, cancelText = 'NO', confirmText = 'YES', singleAction = false, title = 'Are you sure?' } = props;

  return (
    <Dialog aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" open={open} maxWidth={'sm'} fullWidth>
      <DialogTitle id="alert-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent id="alert-dialog-description">
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
        {/* <Alert open={open} sx={{ maxWidth: 600 }} severity="warning" variant="filled" action={ */}
        {/* <Box display='flex' height='100%' alignItems={'center'}> */}
        <DialogActions>
          <Button color="inherit" size="small" onClick={onCancel}>
            {cancelText}
          </Button>&nbsp;&nbsp;
          {(!singleAction) && <Button color="inherit" size="small" onClick={onConfirmation}>
            {confirmText}
          </Button>}
        </DialogActions>
        {/* </Box>     */}
        {/* <AlertTitle>{message}</AlertTitle> */}
        {/* </Alert> */}
      </DialogContent>
    </Dialog>
  )
}