import { useEffect, useState } from "react";
import useHeader from "./useHeader";
import { apiUrlPrefix } from "../authConfig";
import axios from "axios";
import { useSnackbar } from "notistack";
import styled from "@mui/system/styled";
import { Autocomplete, Box, Divider, List, ListItem, ListItemText, Tooltip, Typography, IconButton, TextField, } from "@mui/material";
import { DeleteForever, Add, } from "@mui/icons-material";
import { AddNewTemplateDialog as AddNewDialog, } from "./DealRizz/PathwayFinder/AddNewTemplateDialog";

export const useTransmissionTemplates = ({ getValues, reset, templateTypeId, templateFieldsPrefix, }) => {
    const headers = useHeader();
    const { enqueueSnackbar } = useSnackbar();
    const [template, setTemplate] = useState({});
    const [templates, setTemplates] = useState();
    const [confirmationDialogProps, setConfirmationDialogProps] = useState({});
    const noOptionsText = templates ? 'No templates found. Choose values for your template below, then click the button on the right to save it.' : 'Loading, please wait...';
    const [openAddNewTemplate, setOpenAddNewTemplate] = useState(false);

    useEffect(() => {
        fetchTemplates();
    }, []);

    function handleTemplateChange(newTemplate) {
        setTemplate(newTemplate);
        if (newTemplate?.json) {
            const parsedTemplate = JSON.parse(newTemplate.json);
            const allValues = getValues();
            reset({
                ...allValues,
                template: {
                    ...parsedTemplate,
                },
            });
        }
    }

    function handleDeleteTemplate(template) {
        setTemplate({});
        setConfirmationDialogProps({
            message: `Are you sure you want to delete template ${template.name}?`,
            onConfirmation: () => handleConfirmDeleteTemplate(template),
            open: true,
            onCancel: () => setConfirmationDialogProps({
                ...confirmationDialogProps,
                open: false,
            }),
        })
    }

    function handleConfirmDeleteTemplate(template) {
        setConfirmationDialogProps({
            ...confirmationDialogProps,
            open: false,
        });

        const options = {
            headers: headers,
            url: `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_TransmissionTemplateDelete&parm=${headers.userGuid}&parm=${templateTypeId}&parm=${template.name}`,
        }

        axios(options).then(response => {
            enqueueSnackbar(`Template ${template.name} deleted. Reloading templates.`, { variant: 'success' });
            fetchTemplates();
        }).catch(error => { enqueueSnackbar(`Error deleting template. ${error}`, { variant: 'error', }) });
    }

    function handleSaveNewTemplate({ name }) {
        setOpenAddNewTemplate(false);
        const templateValues = getValues(templateFieldsPrefix);

        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Push?name=PowerStationMetaData.UI_TransmissionTemplatePost&parm=${headers.userGuid}&parm=${templateTypeId}&parm=${name}`;

        axios.post(url, templateValues, { headers: headers }).then(response => {
            enqueueSnackbar(`Template ${name} saved. Reloading templates.`, { variant: 'success' });
            fetchTemplates().then((newTemplates) => {
                const newTemplate = newTemplates.find(t => t.name === name);
                setTemplate(newTemplate);
            });
        }).catch(error => { enqueueSnackbar(`Error saving template. ${error}`, { variant: 'error', }) });
    }

    async function fetchTemplates() {
        setTemplates();

        const options = {
            headers: headers,
            url: `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=PowerStationMetaData.UI_TransmissionTemplateFetch&parm=${headers.userGuid}&parm=${templateTypeId}`,
        };

        return axios(options).then(response => {
            const newTemplates = response.data ?? [];
            setTemplates(newTemplates);
            return newTemplates;
        }).catch(error => {
            enqueueSnackbar(`Error fetching templates. ${error}`, { variant: 'error', })
            setTemplates([]);
        });
    }

    const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
        '& .MuiAutocomplete-inputRoot': {
            minWidth: 150,
            marginTop: 0,
        },
    }));

    const AddNewTemplateDialog = () => {
        return (
            <AddNewDialog
                open={openAddNewTemplate}
                close={() => setOpenAddNewTemplate(false)}
                saveTemplate={handleSaveNewTemplate}
            />
        );
    }

    const TemplateAutocomplete = (props) => {
        return (
            <StyledAutocomplete
                value={template}
                onChange={(_, newVal) => {
                    handleTemplateChange(newVal);
                }}
                autoComplete
                autoHighlight
                autoSelect
                getOptionLabel={(option) => option.name ?? ''}
                noOptionsText={noOptionsText}
                fullWidth
                options={templates ?? []}
                slotProps={{
                    paper: {
                        sx: {
                            width: 'fit-content',
                        }
                    }
                }}
                renderOption={(props, option) => {
                    const parsedTemplate = JSON.parse(option.json);
                    return (
                        <Tooltip placement='right' title={
                            <Box sx={{ pt: 2, }}>
                                <Divider>Template Values</Divider>
                                <List dense sx={{ fontSize: '1rem', }}>
                                    {parsedTemplate.tsClass && <ListItem key={'tsClass'}><ListItemText primary={`TS Class: ${parsedTemplate.tsClass.join(', ')}`} /></ListItem>}
                                    {parsedTemplate.tsIncrement && <ListItem key={'tsIncrement'}><ListItemText primary={`TS Increment: ${parsedTemplate.tsIncrement.join(', ')}`} /></ListItem>}
                                    {parsedTemplate.tsType && <ListItem key={'tsType'}><ListItemText primary={`TS Type: ${parsedTemplate.tsType.join(', ')}`} /></ListItem>}
                                    {parsedTemplate.tsPeriod && <ListItem key={'tsPeriod'}><ListItemText primary={`TS Period: ${parsedTemplate.tsPeriod.join(', ')}`} /></ListItem>}
                                    {parsedTemplate.tsWindow && <ListItem key={'tsWindow'}><ListItemText primary={`TS Window: ${parsedTemplate.tsWindow.join(', ')}`} /></ListItem>}
                                    {parsedTemplate.tsSubclass && <ListItem key={'tsSubclass'}><ListItemText primary={`TS Subclass: ${parsedTemplate.tsSubclass.join(', ')}`} /></ListItem>}
                                    {parsedTemplate.excludeTp && <ListItem key={'excludeTp'}><ListItemText primary={`Excluded Providers: ${parsedTemplate.excludeTp.join(', ')}`} /></ListItem>}
                                    {parsedTemplate.excludePoints && <ListItem key={'excludePoints'}><ListItemText primary={`Excluded Points: ${parsedTemplate.excludePoints.join(', ')}`} /></ListItem>}
                                </List>
                                <Divider />
                            </Box>
                        }>
                            <Box component='li' sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography {...props} width='100%'>{option.name}</Typography>
                                <IconButton size='small' onClick={() => handleDeleteTemplate(option)}>
                                    <DeleteForever />
                                </IconButton>
                            </Box>
                        </Tooltip>
                    );
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        size="small"
                        variant="standard"
                        minWidth={200}
                    />
                )}
                {...props}
            />
        );
    }

    const AddNewTemplateButton = () => {
        return (
            <Tooltip title="Create a new template with the values below." arrow placement="top">
                <IconButton size="small" onClick={() => setOpenAddNewTemplate(true)}>
                    <Add />
                </IconButton>
            </Tooltip>
        );
    }

    return {
        TemplateAutocomplete,
        AddNewTemplateButton,
        AddNewTemplateDialog,
        fetchTemplates,
        handleTemplateChange,
        confirmationDialogProps,
    };
}