import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import Cloud from '@mui/icons-material/Cloud';
import { useNavigate } from "react-router-dom";
import AltRouteIcon from '@mui/icons-material/AltRoute';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import PreviewIcon from '@mui/icons-material/Preview';
import AccessibilityNewOutlinedIcon from '@mui/icons-material/AccessibilityNewOutlined';
import { Collapse } from '@mui/material';
import TransformOutlinedIcon from '@mui/icons-material/TransformOutlined';
import { routePaths } from '../routeConfig.js';
import { userGroups } from '../authConfig.js';
import GroupGuard from './Guards/GroupGuard.js';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import { useMsal } from '@azure/msal-react';
import CornerRibbon from 'react-corner-ribbon';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import PsychologyIcon from '@mui/icons-material/Psychology';

function AppMenu() {
  const navigate = useNavigate();

  //check if user is tea...
  const { accounts } = useMsal();
  const emailAddress = accounts[0]?.username;
  const isTea = emailAddress?.includes('@teainc.org');

  const handleCrystalBallClick = () => {
    navigate(routePaths.crystalball);
  };

  const handleSupportClick = () => {
    navigate(routePaths.support);
  };

  const handleUserGroupsClick = () => {
    navigate(routePaths.usergroupsview);
  };

  const handleAboutPowerStationClick = () => {
    navigate(routePaths.aboutpowerstation);
  };

  const handlePowerPeopleClick = () => {
    navigate(routePaths.powerpeople);
  };

  const handleSpyMonkeyClick = () => {
    navigate(routePaths.spymonkey);
  };

  // const handleApp3Click = () => {
  //   navigate("/app3");
  // };

  const handleFastPathClick = () => {
    navigate(routePaths.fastpath);
  };

  const handleDashboardClick = () => {
    navigate(routePaths.dashboard);
  };

  const handleBidmanClick = () => {
    navigate(routePaths.bidman);
  };
  const handleBidmanMockupClick = () => {
    navigate(routePaths.bidmanmockup);
  };

  const handleTransnowClick = () => {
    navigate(routePaths.transnow);
  };

  const handleTSRActivityClick = () => {
    navigate(routePaths.tsractivity);
  };

  const handleBulkReassignmentClick = () => {
    navigate(routePaths.bulkoriginalrequestsview);
  };

  const handleProductUpdatesClick = () => {
    navigate(routePaths.productupdates);
  };

  return (
    <Paper sx={{ width: 320, maxWidth: '100%' }}>
      <MenuList>
        <MenuItem onClick={handleFastPathClick}>
          <ListItemIcon>
            <AltRouteIcon color="primary" fontSize="small" />
          </ListItemIcon>
          <ListItemText>Fast Path</ListItemText>
          <Typography variant="body2" color="text.secondary">
            ⌘F
          </Typography>
        </MenuItem>
        <GroupGuard groups={[userGroups.spymonkey]}>
          <Collapse in={isTea}>
            <MenuItem onClick={handleSpyMonkeyClick}>
              <ListItemIcon>
                <PreviewIcon color="primary" fontSize="small" />
              </ListItemIcon>
              <ListItemText>Spy Monkey</ListItemText>
              <Typography variant="body2" color="text.secondary">
                ⌘S
              </Typography>
            </MenuItem>
          </Collapse>
        </GroupGuard>
        {/*<GroupGuard groups={[userGroups.bulkoriginalrequestsview]}>
          <MenuItem onClick={handleBulkReassignmentClick}>
            <ListItemIcon>
              <AssignmentIndOutlinedIcon color="primary" fontSize="small" />
            </ListItemIcon>
            <ListItemText>Bulk Original Requests</ListItemText>
            <Typography variant="body2" color="text.secondary">
              ⌘R
            </Typography>
          </MenuItem>
      </GroupGuard>*/}
        {/*<GroupGuard groups={[userGroups.tsractivity, ]}>
          <MenuItem onClick={handleTSRActivityClick}>
            <ListItemIcon>
              <LocalActivityOutlinedIcon color="primary" fontSize="small" />
            </ListItemIcon>
            <ListItemText>TSR Activity</ListItemText>
            <Typography variant="body2" color="text.secondary">
              ⌘A
            </Typography>
          </MenuItem>
    </GroupGuard>*/}
        <GroupGuard groups={[userGroups.crystalball]}>
          <MenuItem onClick={handleCrystalBallClick}>
            <ListItemIcon>
              <SportsSoccerIcon color="primary" fontSize="small" />
            </ListItemIcon>
            <ListItemText>Crystal Ball</ListItemText>
            <Typography variant="body2" color="text.secondary">
              ⌘C
            </Typography>
          </MenuItem>
        </GroupGuard>
        {/*<GroupGuard groups={[userGroups.dashboard]}>
          <MenuItem onClick={handleDashboardClick}>
            <ListItemIcon>
              <SubscriptionsOutlinedIcon color="primary" fontSize="small" />
            </ListItemIcon>
            <ListItemText>Automation Dashboard</ListItemText>
            <Typography variant="body2" color="text.secondary">
              ⌘B
            </Typography>
          </MenuItem>
  </GroupGuard>*/}
        {/*<GroupGuard groups={[userGroups.bidmanmockup]}>
          <MenuItem onClick={handleBidmanMockupClick}>
            <ListItemIcon>
              <AccessibilityNewOutlinedIcon color="primary" fontSize="small" />
            </ListItemIcon>
            <ListItemText>Bidman Mockup</ListItemText>
            <Typography variant="body2" color="text.secondary">
              ⌘M
            </Typography>
          </MenuItem>
</GroupGuard>*/}
        <GroupGuard groups={[userGroups.bidman, userGroups.engie]}>
          <MenuItem onClick={handleBidmanClick}>
            <ListItemIcon>
              <AccessibilityNewOutlinedIcon color="primary" fontSize="small" />
            </ListItemIcon>
            <ListItemText>Bidman</ListItemText>
            <Typography variant="body2" color="text.secondary">
              ⌘M
            </Typography>
          </MenuItem>
        </GroupGuard>
        {/* <GroupGuard groups={[userGroups.default]}>  */}
        <MenuItem onClick={handleTransnowClick}>
          <ListItemIcon>
            <TransformOutlinedIcon color="primary" fontSize="small" />
          </ListItemIcon>
          <ListItemText>TransNow</ListItemText>
          <Typography variant="body2" color="text.secondary">
            ⌘T
          </Typography>
        </MenuItem>
        {/* </GroupGuard> */}
        {/* <GroupGuard groups={[userGroups.transhunter,]}> */}
        <MenuItem onClick={() => navigate(routePaths.transhunter)}>
          <ListItemIcon>
            <TrackChangesIcon color="primary" fontSize="small" />
          </ListItemIcon>
          <ListItemText>TransHunter</ListItemText>
          <Typography variant="body2" color="text.secondary">
            ⌘H
          </Typography>
        </MenuItem>
        {/* </GroupGuard> */}
        <div style={{ position: 'relative' }}>
          <CornerRibbon style={{ fontSize: 7 }}>BETA</CornerRibbon>
          <MenuItem onClick={() => navigate(routePaths.dealrizz)}>
            <ListItemIcon>
              <DashboardCustomizeIcon color='primary' fontSize='small' />
            </ListItemIcon>
            <ListItemText primary="Deal Rizz" />
          </MenuItem>
        </div>
        <div style={{ position: 'relative' }}>
          <CornerRibbon style={{ fontSize: 7 }}>BETA</CornerRibbon>
          <MenuItem onClick={() => navigate(routePaths.brain)}>
            <ListItemIcon>
              <PsychologyIcon color='primary' fontSize='small' />
            </ListItemIcon>
            <ListItemText primary="The Brain" />
          </MenuItem>
        </div>
        <Divider />
        <MenuItem onClick={handleAboutPowerStationClick}>
          <ListItemIcon>
            <Cloud color="primary" fontSize="small" />
          </ListItemIcon>
          <ListItemText>About Power Station</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleProductUpdatesClick}>
          <ListItemIcon>
            <NewReleasesIcon color="primary" fontSize="small" />
          </ListItemIcon>
          <ListItemText>Product Updates</ListItemText>
        </MenuItem>
        <GroupGuard groups={[userGroups.support, userGroups.requirements, userGroups.emailcomposer, userGroups.helpcomposer]}>
          <MenuItem onClick={handlePowerPeopleClick}>
            <ListItemIcon>
              <ElectricBoltIcon color="primary" fontSize="small" />
            </ListItemIcon>
            <ListItemText>Power People</ListItemText>
          </MenuItem>
        </GroupGuard>
        {/*<GroupGuard groups={[userGroups.support,]}>
          <MenuItem onClick={handleSupportClick}>
            <ListItemIcon>
              <LiveHelpIcon color="primary" fontSize="small" />
            </ListItemIcon>
            <ListItemText>Support</ListItemText>
          </MenuItem>
        </GroupGuard>*/}
        {/*<GroupGuard groups={[userGroups.usergroups, userGroups.admins, userGroups.usergroupsview,]}>
          <MenuItem onClick={handleUserGroupsClick}>
            <ListItemIcon>
              <GroupsIcon color="primary" fontSize="small" />
            </ListItemIcon>
            <ListItemText>User Groups</ListItemText>
          </MenuItem>
        </GroupGuard>*/}
      </MenuList>
    </Paper>
  );
}

export default AppMenu