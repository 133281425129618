import { useRef, useMemo, useState, useCallback, forwardRef, useImperativeHandle } from "react"
import { Box } from "@mui/material"
import { AgGridContainer } from "../../AgGrid/AgGridContainer"
import { AgGridReact } from "ag-grid-react"
import { defaultColumnDef, defaultStatusBar } from "../../AgGrid/defaultGridProps"
import { useColumnTypes } from "../../AgGrid/useColumnTypes"
import { columnPanel, filterPanel, palettePanel, } from "../../ToolPanels/DefaultToolPanels"
import { LayoutToolPanel } from "../../ToolPanels/LayoutToolPanel"
import { apiUrlPrefix } from "../../../authConfig"
import { useSnackbar } from "notistack"
import useGridLayout from "../../useGridLayout"
import axios from "axios"
import useHeader from "../../useHeader"
import dayjs from "dayjs"
import { useActionAudit } from "../../useActionAudit"
import { Ribbon, GridActionsGroup, } from "../Ribbon"
import { FilterCriteriaFormGroup } from "./FilterCriteriaFormGroup"
import { useGridCrossHighlight } from "../useGridCrossHighlight"
import { jsonOrCommaSeparatedFormatter } from "../Utils"

export const TransDealSummaryView = forwardRef(({ view, }, ref) => {
    const layoutStorageKey = `deal-rizz-trans-deal-summary-grid-layout-${view.id}`;
    const gridRef = useRef();
    const { columnTypes } = useColumnTypes();
    const [rowData, setRowData] = useState();
    const headers = useHeader();
    const { enqueueSnackbar } = useSnackbar();
    const { logAction } = useActionAudit();
    const { highlightingCellClassRules, clearHighlighting, handleCellMouseOverHighlight, } = useGridCrossHighlight(gridRef);

    const fetchData = useCallback(async (data) => {
        logAction('User fetched Trans Deal Summary Data', 'Trans Deal Summary', data);
        const { timezone, startDate, endDate } = data;
        gridRef.current?.api?.showLoadingOverlay();

        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=dealrizz.UI_fetchTransmissionDealSummary`
            + `&parm=${headers.userGuid}`
            + `&parm=${dayjs(startDate).format('MM/DD/YYYY')}`
            + `&parm=${dayjs(endDate).format('MM/DD/YYYY')}`
            + `&parm=${timezone}`

        return axios.get(url, { headers: headers }).then(response => {
            setRowData(response?.data ?? []);
        }).catch((error) => {
            enqueueSnackbar(error.message, { variant: 'error' });
            setRowData([]);
        }).finally(() => {
            gridRef.current?.api?.hideOverlay();
        });
    }, [headers]);

    const baseColDefs = useMemo(() => ([
        { field: "dealID", headerName: "Deal ID" },
        { field: "Old_Deal_ID", headerName: "Old Deal ID" },
        { field: "Trade_Date", headerName: "Trade Date" },
        { field: "AssignmentRef", headerName: "Assignment Ref" },
        { field: "Provider", headerName: "Provider" },
        {
            field: "Book",
            headerName: "Book",
            valueFormatter: (params) => {
                return jsonOrCommaSeparatedFormatter(params.value);
            },
        },
        {
            field: "Strategy",
            headerName: "Strategy",
            valueFormatter: (params) => {
                return jsonOrCommaSeparatedFormatter(params.value);
            },
        },
        { field: "MW", headerName: "MW" },
        { field: "Total_MWh", headerName: "Total MWh" },
        { field: "PointOfReceipt", headerName: "Point Of Receipt" },
        { field: "PointOfDelivery", headerName: "Point Of Delivery" },
        { field: "RequestType", headerName: "Request Type" },
        { field: "Service", headerName: "Service" },
        { field: "NERCcurtailmentPriority", headerName: "NERC Curtailment Priority" },
        { field: "Trader", headerName: "Trader" },
        { field: "Modification_User", headerName: "Modification User" },
        { field: "Column1", headerName: "Column 1" },
        { field: "RelatedRef", headerName: "Related Ref" },
        { field: "Counterparty", headerName: "Counterparty" },
        { field: "Owner", headerName: "Owner" },
        { field: "Creator", headerName: "Creator" },
        { field: "Buying_Trader", headerName: "Buying Trader" },
        { field: "Executing_Trader", headerName: "Executing Trader" },
        { field: "Selling_Trader", headerName: "Selling Trader" },
        { field: "Transaction_Type", headerName: "Transaction Type" },
        { field: "Trans_Price", headerName: "Transaction Price" },
        { field: "Deal_Status", headerName: "Deal Status" }
    ]), []);

    const defaultColDef = useMemo(() => ({
        ...defaultColumnDef,
        editable: false,
        enableRowGroup: true,
        minWidth: 100,
        flex: 1,
    }), []);

    const { colDefs, layoutPanel, loadLayout, applySavedFilters, applyFilters, applyLayout, captureLayout } = useGridLayout(layoutStorageKey, gridRef, baseColDefs, defaultColDef);

    const styledColDefs = useMemo(() => colDefs.map(colDef => ({
        ...colDef,
        cellClassRules: highlightingCellClassRules,
    })), [colDefs, highlightingCellClassRules]);

    const sideBar = useMemo(() => ({
        toolPanels: [
            columnPanel,
            filterPanel,
            layoutPanel,
        ]
    }), []);

    useImperativeHandle(ref, () => {
        return {
            captureLayout,
        };
    });

    function onGridReady(params) {
        if (view?.layout && !localStorage.getItem(layoutStorageKey)) {
            applyLayout(view.layout);
        } else {
            loadLayout();
        }
    }

    const handleFirstDataRendered = useCallback(() => {
        if (view?.layout?.filters && !localStorage.getItem(layoutStorageKey)) {
            applyFilters(view.layout.filters);
        } else if (localStorage.getItem(layoutStorageKey)) {
            applySavedFilters();
        }
    }, []);

    const toolbarFormId = `deal-rizz-trans-deal-summary-toolbar-${view.id}`;

    return (
        <Box sx={{ height: '90vh', display: 'flex', flexDirection: 'column' }}>
            <Ribbon>
                {[
                    { title: 'Grid', content: <GridActionsGroup formId={toolbarFormId} /> },
                    { title: 'Filter', content: <FilterCriteriaFormGroup view={view} onSubmit={fetchData} formId={toolbarFormId} /> },
                ]}
            </Ribbon>
            <AgGridContainer style={{ display: 'flex', flex: 1, width: '100%' }} onMouseLeave={clearHighlighting}>
                <AgGridReact
                    containerStyle={{ display: 'flex', flexDirection: 'column', flex: 1, width: '100%', }}
                    rowData={rowData}
                    ref={gridRef}
                    enableCharts
                    columnDefs={styledColDefs}
                    enableRangeSelection
                    onFirstDataRendered={handleFirstDataRendered}
                    onCellMouseOver={handleCellMouseOverHighlight}
                    enableFillHandle
                    groupTotalRow={"bottom"}
                    suppressAggFuncInHeader={true}
                    stopEditingWhenCellsLoseFocus
                    singleClickEdit
                    onGridReady={onGridReady}
                    columnTypes={columnTypes}
                    statusBar={defaultStatusBar}
                    sideBar={sideBar}
                    components={{
                        layoutToolPanel: LayoutToolPanel,
                    }}
                />
            </AgGridContainer>
        </Box>
    )
});