import { useRef, useMemo, useState, useCallback, forwardRef, useImperativeHandle } from "react"
import { Box } from "@mui/material"
import { AgGridContainer } from "../../AgGrid/AgGridContainer"
import { AgGridReact } from "ag-grid-react"
import { defaultColumnDef, defaultStatusBar } from "../../AgGrid/defaultGridProps"
import { useColumnTypes } from "../../AgGrid/useColumnTypes"
import { columnPanel, filterPanel, palettePanel, } from "../../ToolPanels/DefaultToolPanels"
import { LayoutToolPanel } from "../../ToolPanels/LayoutToolPanel"
import { apiUrlPrefix } from "../../../authConfig"
import { useSnackbar } from "notistack"
import useGridLayout from "../../useGridLayout"
import axios from "axios"
import useHeader from "../../useHeader"
import dayjs from "dayjs"
import { useActionAudit } from "../../useActionAudit"
import { Ribbon, GridActionsGroup, } from "../Ribbon"
import { FilterCriteriaFormGroup } from "./FilterCriteriaFormGroup"
import { handleUpdateTotalRows } from "../Utils"
import { heatmapStyle, tooltipRenderer } from "../Utils"
import { PaletteToolPanel } from "../../ToolPanels/PalettePanel"

export const Forecast = () => {
    const layoutStorageKey = `deal-rizz-forecast-grid-layout`;
    const gridRef = useRef();
    const { columnTypes } = useColumnTypes();
    const [rowData, setRowData] = useState();
    const headers = useHeader();
    const { enqueueSnackbar } = useSnackbar();
    const { logAction } = useActionAudit();
    const paletteRef = useRef({ showHeatmap: false });

    const fetchData = useCallback(async (data) => {
        logAction('User fetched Forecast Data', 'Forecast', data);
        const { timezone, date, } = data;
        gridRef.current?.api?.showLoadingOverlay();

        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf?name=dealrizz.UI_fetchTenantForecast`
            + `&parm=${headers.userGuid}`
            + `&parm=${dayjs(date).format('MM/DD/YYYY')}`
            + `&parm=${timezone}`

        return axios.get(url, { headers: headers }).then(response => {
            setRowData(response?.data ?? []);
        }).catch((error) => {
            enqueueSnackbar(error.message, { variant: 'error' });
            setRowData([]);
        }).finally(() => {
            gridRef.current?.api?.hideOverlay();
        });
    }, [headers]);

    const baseColDefs = useMemo(() => ([
        { field: 'name', headerName: 'Name', cellDataType: 'text' },
        { field: 'description', headerName: 'Description', cellDataType: 'text' },
        { field: 'source', headerName: 'Source', cellDataType: 'text' },
        { field: 'type', headerName: 'Type', cellDataType: 'text' },
        ...Array.from({ length: 26 }, (_, i) => ({
            headerName: `${i + 1}`,
            field: `${i + 1}`,
            enableRowGroup: false,
            aggFunc: 'sum',
            minWidth: 75,
            chartDataType: 'series',
            type: 'numericColumn',
            cellDataType: 'number',
            cellStyle: params => heatmapStyle(params, paletteRef),
        })),
    ]), []);

    const defaultColDef = useMemo(() => ({
        ...defaultColumnDef,
        editable: false,
        enableRowGroup: true,
        minWidth: 100,
        flex: 1,
    }), []);

    const { colDefs, layoutPanel, loadLayout, applySavedFilters, } = useGridLayout(layoutStorageKey, gridRef, baseColDefs, defaultColDef);

    const sideBar = useMemo(() => ({
        toolPanels: [
            columnPanel,
            filterPanel,
            layoutPanel,
            palettePanel(gridRef, paletteRef, 'deal-rizz-forecast-palette'),
        ]
    }), []);

    function onGridReady(params) {
        loadLayout();
    }

    const handleFirstDataRendered = useCallback(() => {
        applySavedFilters();
    }, []);

    const toolbarFormId = `deal-rizz-forecast-toolbar`;

    const updateTotalRows = useCallback(({ api, }) => {
        handleUpdateTotalRows(api, baseColDefs[0].field);
    }, []);

    const gridOptions = useMemo(() => ({
        rowClassRules: {
            "ag-custom-total-row": params => !!params.node.rowPinned,
        },
    }), []);

    return (
        <Box sx={{ height: '90vh', display: 'flex', flexDirection: 'column' }}>
            <Ribbon>
                {[
                    { title: 'Grid', content: <GridActionsGroup formId={toolbarFormId} /> },
                    { title: 'Filter', content: <FilterCriteriaFormGroup onSubmit={fetchData} formId={toolbarFormId} /> },
                ]}
            </Ribbon>
            <AgGridContainer style={{ display: 'flex', flex: 1, width: '100%' }}>
                <AgGridReact
                    containerStyle={{ display: 'flex', flexDirection: 'column', flex: 1, width: '100%', }}
                    rowData={rowData}
                    ref={gridRef}
                    gridOptions={gridOptions}
                    enableCharts
                    columnDefs={colDefs}
                    onFilterChanged={updateTotalRows}
                    onRowDataUpdated={updateTotalRows}
                    enableRangeSelection
                    onFirstDataRendered={handleFirstDataRendered}
                    enableFillHandle
                    groupTotalRow={"bottom"}
                    suppressAggFuncInHeader={true}
                    stopEditingWhenCellsLoseFocus
                    singleClickEdit
                    onGridReady={onGridReady}
                    columnTypes={columnTypes}
                    statusBar={defaultStatusBar}
                    sideBar={sideBar}
                    components={{
                        layoutToolPanel: LayoutToolPanel,
                        paletteToolPanel: PaletteToolPanel,
                    }}
                />
            </AgGridContainer>
        </Box>
    )
};