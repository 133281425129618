import { Box } from "@mui/material"

export default () => {
    return (
        <Box sx={{ p: 1, height: '85vh'}}>
            <iframe 
               width={'100%'} 
               height={'100%'} 
               src={'https://portal.azure.com/#@powersysops.com/resource/subscriptions/a5ab70f9-75d2-40c5-a2f3-f46caa4a6a25/resourceGroups/PSO-Azure-DevOps/providers/Microsoft.Web/sites/NewUserWorkflow/workflowsList'}
            />
        </Box>
    )
}