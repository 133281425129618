import { makeStyles } from "@mui/styles";

const useGridViewStyles = makeStyles({
    gridViewWrapper: {
        width: "100%",
        paddingLeft: "30px",
        paddingRight: "10px",
    },
    searchDetails: {
        display: "flex",
        //alignItems="center",
        alignItems: 'center',
        flexWrap: 'wrap',
        verticalAlign: 'middle',
        display: 'inline-flex',
        "& p": {
            marginRight: "30px",
            //fontWeight: "bold",
        }
    },
    dataGrid: {
        border: "1px solid #87b7eb",
        width: "100%", //changes the width of the overall grid not the inside
        //border is hidden without 
        borderCollapse: "collapse",
        "& tr": {
            border: "1px solid #87b7eb",
        },
    },
    subRowGrid: {
        display: "flex",
    },
    subRowFixedTable: {
        borderCollapse: "collapse",
        "& thead": {
            backgroundColor: "#efefef"
        },
        "& tr": {
            "&:last-child": {
                "& td": {
                    border: "none"
                }
            },
            "&:not(:last-child)": {
                backgroundColor: "#efefef",
            },
            "& th": {
                border: "1px solid #87b7eb",
                width: "180px",
                height: "25px",

                "&:first-child": {
                    maxWidth: "40px",
                    borderRight: "none",

                },

                "&:not(:first-child)": {
                    borderLeft: "none"
                },

                "&:nth-child(2)": {
                    minWidth: "40px",
                }

            },

            "& td": {
                fontSize: "10px",
                fontWeight: "bold",
                textAlign: "center",
                border: "1px solid #87b7eb",
                width: "120px",
                height: "25px",

                "&:first-child": {
                    maxWidth: "40px",
                    borderRight: "none"
                },

                "&:not(:first-child)": {
                    borderLeft: "none"
                },

                "&:nth-child(2)": {
                    //backgroundColor: "red",
                }
            }
        }
    },
    /******************************************************/
    /* Scroll Table */
    scrollTableWrapper: {
        overflow: "auto",
        width: "100%",

        "&::-webkit-scrollbar": {
            backgroundColor: "#dddddd",
            height: "25px",
        },

        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#aeaeae",
        },
    },

    subRowScrollTable: {
        borderCollapse: "collapse",
        "& tr": {

            "& th": {
                fontSize: "10px",
                border: "1px solid #87b7eb",
                minWidth: "50px",
                height: "25px",
            },

            "& td": {
                fontSize: "10px",
                border: "1px solid #87b7eb",
                width: "50px",
                height: "25px",
                textAlign: "center"
            }
        }
    }
},{ index: 1 });


export { useGridViewStyles };