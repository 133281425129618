import { useRef, useMemo } from "react"
import { Box } from "@mui/material"
import { Toolbar } from "./Toolbar"
import { AgGridContainer } from "../../AgGrid/AgGridContainer"
import { AgGridReact } from "ag-grid-react"

export const Discrepancies = () => {
    const gridRef = useRef();

    const colDefs = useMemo(() => ([
        {
            checkboxSelection: true,
            headerCheckboxSelection: true,
            headerName: 'Schedule #',
            field: 'scheduleNumber',
        },
        {
            headerName: 'Schedule Status',
            field: 'scheduleStatus',
        },
        {
            headerName: 'Schedule Start Date',
            field: 'scheduleStartDate',
        },
        {
            headerName: 'Schedule End Date',
            field: 'scheduleEndDate',
        },
        {
            headerName: 'Tag Code',
            field: 'tagCode',
        },
        {
            headerName: 'Tag Last Action',
            field: 'tagLastAction',
        },
        {
            headerName: 'Tag Status',
            field: 'tagStatus',
        },
        {
            headerName: 'Start Time',
            field: 'startTime',
        },
        {
            headerName: 'Stop Time',
            field: 'stopTime',
        },
    ]), []);

    const defaultColDef = useMemo(() => ({
        editable: false,
        resizable: true,
        flex: 1,
    }), []);

    return (
        <Box sx={{ height: '86vh' }}>
            <Toolbar />
            <AgGridContainer style={{ height: '100%' }}>
                <AgGridReact
                    rowData={[]}
                    ref={gridRef}
                    columnDefs={colDefs}
                    enableRangeSelection
                    enableFillHandle
                    stopEditingWhenCellsLoseFocus
                    singleClickEdit
                    defaultColDef={defaultColDef}
                />
            </AgGridContainer>
        </Box>
    )
}