import { Stack } from "@mui/material"
import { RibbonButton } from "../Ribbon"
import MergeTypeIcon from '@mui/icons-material/MergeType';
import { AddTsrDialog } from './AddTsrDialog/AddTsrDialog';
import { useState } from "react";
import { apiUrlPrefix } from "../../../authConfig";
import { useApi } from "../../useApi";
import dayjs from "dayjs";

export const TSRActionsRibbonGroup = ({ expanded, selectedTags, context, }) => {
    const selectedTag = selectedTags?.[0];
    const [openAddTsrDialog, setOpenAddTsrDialog] = useState(false);
    const { get, post, headers, enqueueSnackbar, logAction } = useApi();

    async function handleSaveTsrs(productRef, data) {
        const url = `${apiUrlPrefix}/CrystalBall/Store/Shelf/JSON/Pushfetch?name=dealrizz.UI_TagReport_AddTransmissionToDraftTag`
            + `&parm=${headers.userGuid}`
            + `&parm=${selectedTag.tagIdx}`
            + `&parm=${productRef.id}`
            + `&parm=${context.timezone}`
            + `&parm=${selectedTag.tagDate ?? ''}`
            + `&parm=${dayjs(context.startDate).format('MM/DD/YYYY')}`
            + `&parm=${dayjs(context.stopDate).format('MM/DD/YYYY')}`

        return post(url, data).then(response => {
            enqueueSnackbar('Transmission added successfully.', { variant: 'success' });
            logAction(`User added transmission to tag ${selectedTag.tagIdx}`, 'Tag Report', data);
            return response;
        });
    }

    function addTsrs(productRef, data) {
        setOpenAddTsrDialog(false);
        handleSaveTsrs(productRef, data).then(response => {
            const { adjustmentInfo, txInfo } = response;
        });
    }

    return (
        <Stack direction='row' spacing={1} sx={{ p: 1, display: 'flex', flexWrap: 'noWrap', alignItems: 'center', flexDirection: 'row' }}>

            <AddTsrDialog
                open={openAddTsrDialog}
                handleSaveAndClose={addTsrs}
                handleCancel={() => setOpenAddTsrDialog(false)}
                context={context}
                selectedTag={selectedTag}
            />

            <RibbonButton
                label="Add TSR"
                expanded={expanded}
                onMouseDown={() => setOpenAddTsrDialog(true)}
                icon={<MergeTypeIcon
                    fontSize='small'
                />}
            />

        </Stack>
    )
}